import { atom } from 'jotai';
import env from '@/env';
import { getFetchClient } from '@/query/client';

// Storing as an object, as advised by the devs, otherwise jotai assumes it needs to store the *result* of the function
export const fetchClientAtom = atom<{ fetch: (input: RequestInfo, init?: RequestInit) => Promise<any> }>({
  fetch: (...args) => {
    // eslint-disable-next-line no-console
    if (env.VITE_NODE_ENV !== 'test') console.warn('Fetch initiated before client was ready');
    const fetch = getFetchClient(Promise.resolve('unset'));
    return fetch(...args);
  },
});
