import { ConfirmationDialog, Typography } from '@statsbomb/kitbag-components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const GenericDeleteModal = ({
  id,
  itemToDelete,
  itemType,
  onConfirm,
  onCancel,
}: {
  id: string;
  itemToDelete: string;
  itemType: string;
  onConfirm: () => Promise<void>;
  onCancel: () => void;
}) => {
  const { t } = useTranslation(['general', 'modal']);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <ConfirmationDialog
      id={id}
      title={t('deleteModal.title', { ns: 'modal', itemToDelete })}
      isOpen
      onConfirm={async () => {
        setIsLoading(true);
        await onConfirm();
        setIsLoading(false);
      }}
      onCancel={onCancel}
      cancelLabel={t('cancel')}
      confirmLabel={t('delete')}
      confirmButtonVariant="error"
      isPendingConfirm={isLoading}
    >
      <Typography variant="bodyRegular">{t('deleteModal.content', { ns: 'modal', itemType })}</Typography>
    </ConfirmationDialog>
  );
};
