/* TODO (PPC-1399: Remove SaveToDashboardMenuItemOLD) */
import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, MenuItem, MenuItemText, Icon } from '@statsbomb/kitbag-components';
import { useSaveItemToDashboard } from '@/hooks/useSaveItemToDashboardOLD';
import { SaveToDashboardMenuItemProps } from '@/types/widget';
import { noop } from '@/utils/noop';
import { useSetAtom } from 'jotai';
import { closeToastAtom, toastDataAtom } from '@/atoms/toast';
import { useNavigate } from 'react-router-dom';
import { isVisOptionsMenuOpenAtom } from '@/atoms/visualisation';

interface SaveToDashboardMenuItemBaseProps {
  onClick?: () => void;
  disabled?: boolean;
}

const SaveToDashboardMenuItemBase = ({ onClick = noop, disabled = false }: SaveToDashboardMenuItemBaseProps) => {
  const { t } = useTranslation('widget');

  return (
    <MenuItem disabled={disabled} onClick={onClick}>
      <div
        data-testid={`save-to-dashboard-menu-item${disabled ? `-disabled` : ''}`}
        className="flex gap-x-2 items-center"
      >
        <Icon variant="PlusAdd" size="small" />
        <MenuItemText>{t('saveToDashboard', { ns: 'widget' })}</MenuItemText>
      </div>
    </MenuItem>
  );
};

const SaveToDashboardMenuItemWithData = ({ visType, dataType }: SaveToDashboardMenuItemProps) => {
  const saveItemToDashboard = useSaveItemToDashboard();
  const {
    i18n: { language },
  } = useTranslation();
  const { t } = useTranslation(['visualisation', 'widget']);
  const translatedVisType = t(visType, { ns: 'visualisation' });
  const setToastData = useSetAtom(toastDataAtom);
  const closeToast = useSetAtom(closeToastAtom);
  const navigate = useNavigate();
  const setIsVisOptionsMenuOpen = useSetAtom(isVisOptionsMenuOpenAtom);

  const onClick = async () => {
    const isSuccess = await saveItemToDashboard(visType, dataType);

    const buttonText = t('viewDashboard', { ns: 'widget' });
    const toastContent = isSuccess ? (
      <Button
        size="small"
        variant="secondary"
        onClick={() => {
          closeToast();
          navigate(`/${language}/dashboard`);
        }}
      >
        {buttonText}
      </Button>
    ) : null;

    setToastData('addToDashboard', isSuccess, translatedVisType, toastContent);
  };

  return (
    <SaveToDashboardMenuItemBase
      onClick={async () => {
        setIsVisOptionsMenuOpen(false);
        await onClick();
      }}
    />
  );
};

export const SaveToDashboardMenuItem = ({ visType, dataType }: SaveToDashboardMenuItemProps) => (
  <Suspense fallback={<SaveToDashboardMenuItemBase disabled />}>
    <SaveToDashboardMenuItemWithData visType={visType} dataType={dataType} />
  </Suspense>
);
