import { competitionOptionsAtom } from '@/atoms/filters/highLevel/competitions';
import { unwrappedPlayerSelectedGamesForQueryAtom } from '@/atoms/filters/player/playerGames';
import { pageAndOrderParamsAtom } from '@/atoms/general';
import { playerIdAtom } from '@/atoms/player/player';
import { fetchClientAtom } from '@/atoms/queries/client';
import { playerGamesAtom } from '@/atoms/queries/players';
import { convertFilterParamsToString } from '@/utils/api';
import { convertOptionsToValues } from '@/utils/array';
import { flattenAndAddPrefix } from '@/utils/object';
import { objSnakeToCamel } from '@/utils/queries';
import { PlayerGameAggregate, Position } from '@statsbomb/parachute-types';
import { atom } from 'jotai';
import { atomWithSuspenseQuery } from 'jotai-tanstack-query';
import { getSortedGamesToDisplay } from '@/utils/games';

const rawPlayerGameAggsDataAtom = atomWithSuspenseQuery(get => {
  const queryKey = ['playerGameAggs', get(playerIdAtom)] as const;
  const queryFn = async ({ queryKey: [, playerId] }: { queryKey: typeof queryKey }) => {
    const { fetch } = get(fetchClientAtom);

    const data: PlayerGameAggregate[] = await fetch(
      `/player/${playerId}/games/total-aggregates${convertFilterParamsToString({
        limit: 'ALL',
      })}`,
    );

    return data.map(objSnakeToCamel);
  };
  return { queryKey, queryFn };
});

/* need to do joining here between playerGames and rawPlayerGameAggsDataAtom as we don't have
the aggregate data for all our games, so when using the lastXGames filter it didn't work as expected.
This joins them together so the filter works and we display a message for when the aggregates haven't
been processed yet. */
export const playerGameAggsAtom = atom(async get => {
  const pageAndOrderParams = get(pageAndOrderParamsAtom);
  const playerGameAggs = (await get(rawPlayerGameAggsDataAtom)).data;
  const competitionOptions = await get(competitionOptionsAtom);
  const unwrappedPlayerGamesForQuery = convertOptionsToValues(get(unwrappedPlayerSelectedGamesForQueryAtom));
  const playerGames = await get(playerGamesAtom);

  const gamesToDisplayWithAggs = playerGames
    .filter(({ game }) => unwrappedPlayerGamesForQuery.includes(game.gameId))
    .map(game => {
      const gameAggs = playerGameAggs.find(gameAgg => gameAgg.game.gameId === game.game.gameId);
      const competition = competitionOptions.find(comp => game.cycle.competitionId === comp.value);
      const gameWithAggs = {
        ...game,
        aggregates: gameAggs?.aggregates,
        hasAggregates: !!gameAggs,
        competition: { name: competition?.label },
      };

      return flattenAndAddPrefix(objSnakeToCamel(gameWithAggs), '.', false);
    });

  const sortedGamesToDisplay = getSortedGamesToDisplay(gamesToDisplayWithAggs, pageAndOrderParams);
  return sortedGamesToDisplay.slice(pageAndOrderParams.offset, pageAndOrderParams.offset + pageAndOrderParams.limit);
});

export const playerGameAggsCountAtom = atom(async get => get(unwrappedPlayerSelectedGamesForQueryAtom).length);

/* this atom is required as we don't want to use the standard player positions atom 
on the player games page as changing the filters results in that endpoint being requeried
as on this page we only need to know whether they are a keeper or not */
export const playerGameAggsPositions = atomWithSuspenseQuery(get => {
  const queryKey = ['playerAggsPositions', get(playerIdAtom)] as const;

  const queryFn = async ({ queryKey: [_, playerId] }: { queryKey: typeof queryKey }) => {
    if (!playerId) return [];
    const { fetch } = get(fetchClientAtom);
    return (await fetch(`/player/${playerId}/positions`)) as Promise<Position[]>;
  };
  return { queryKey, queryFn };
});
