import { Nullable } from '@/types/generic';
import { SORT_DIRECTION } from '@/types/ordering';
import { useTranslation } from 'react-i18next';

export const useSortObjectArray = (
  orderByField: Nullable<string>,
  array: Record<string, any>[],
  sortDirection = SORT_DIRECTION.ASC,
) => {
  const { i18n } = useTranslation();

  const sortedOptions = orderByField
    ? [...array].sort((a, b) => {
        const aField = a[orderByField];
        const bField = b[orderByField];
        return sortDirection === SORT_DIRECTION.ASC
          ? aField.localeCompare(bField, i18n.language, { sensitivity: 'base' })
          : bField.localeCompare(aField, i18n.language, { sensitivity: 'base' });
      })
    : array;

  return sortedOptions;
};
