import { playerMetricDataAtom } from '@/atoms/player/metric';
import { playerEventsForMetricCountAtom } from '@/atoms/queries/player/playerEventData';
import { PlayerLayoutWithFilters } from '@/components/layout/player/PlayerLayoutWithFilters';
import { MetricKeyDropdown } from '@/components/metric/MetricKeyDropdown';
import { SectionTopBar } from '@/components/sectionTopBar/SectionTopBar';
import { PlayerVisEventsTable } from '@/components/player/vis/PlayerVisEventsTable';
import { VisDisplaySection } from '@/components/section/VisDisplaySection';
import { EventCountInfoBar } from '@/components/visualisations/EventCountInfoBar';
import { PitchVisualisation } from '@/components/visualisations/PitchVisualisation/PitchVisualisation';
import { playerPitchVisComponents } from '@/consts/visualisationComponents';
import { EntityVisModeTopBar } from '@/components/sectionTopBar/entity/EntityVisModeTopBar';
import { PageTitle } from '@/components/head/PageTitle';
import { unwrappedSelectedPlayerNameAtom } from '@/atoms/player/info';
import { useTranslation } from 'react-i18next';
import { useAtomValue } from 'jotai';
import { useEntityPageTitle } from '@/hooks/useEntityPageTitle';

export const PlayerVisPage = () => {
  const { t } = useTranslation('navigation');
  const pageTitle = useEntityPageTitle(t('secondary.pitchVis'), useAtomValue(unwrappedSelectedPlayerNameAtom));

  return (
    <>
      <PageTitle>{pageTitle}</PageTitle>
      <PlayerLayoutWithFilters>
        <>
          <SectionTopBar>
            <EntityVisModeTopBar />
          </SectionTopBar>
          <VisDisplaySection
            EventsTableComponent={PlayerVisEventsTable}
            visualisationComponent={
              <PitchVisualisation
                visualisations={playerPitchVisComponents}
                headerComponent={<MetricKeyDropdown metricDataAtom={playerMetricDataAtom} />}
                footerComponent={<EventCountInfoBar eventCountAtom={playerEventsForMetricCountAtom} />}
              />
            }
          />
        </>
      </PlayerLayoutWithFilters>
    </>
  );
};
