import { Table } from '@statsbomb/kitbag-components';
import { useTranslation } from 'react-i18next';
import { useAtomValue } from 'jotai';
import { eventsDataVisibleColumnsAtom } from '@/atoms/dataLocker/events/table';
import { LINK_SORTING_MAP } from '@/consts/ordering';
import { DataTableColumn } from '@/types/table';
import { NestedObject, NestedObjectPromiseAtom } from '@/types/object';
import { alignTableCell, getHighlightedColumnIndex, translateValue } from '@/utils/table';
import { useCurrentlySelectedVideoId } from '@/hooks/useCurrentlySelectedVideoId';
import { PromiseAtom } from '@/types/atom';
import { TablePagination } from '@/components/tables/TablePagination';
import { tableColumnsAtom } from '@/atoms/queries/tableColumns';
import { Suspense } from 'react';
import { GeneralErrorBoundaryFallback } from '@/components/errorBoundary/ErrorBoundaryFallback';
import { ErrorBoundary } from '@sentry/react';
import { useSortTable } from '@/hooks/useSortTable';
import { hasVideoLicenceAtom } from '@/atoms/user';
import { PlayVideoButton } from '../video/PlayVideoButton';
import { CellValueToDisplay } from './CellValueToDisplay';
import { EmptyState } from '../contentState/EmptyState';
import { LoadingState } from '../contentState/LoadingState';
import { HasVideoAccess } from '../video/HasVideoAccess';
import { TableWrapper } from './TableWrapper';

export const EventsTableBase = ({
  events = [],
  eventDataTableColumns,
  isLoading = false,
  isVideoColumnVisible,
}: {
  events?: NestedObject[];
  eventDataTableColumns?: DataTableColumn[];
  isLoading?: boolean;
  isVideoColumnVisible?: boolean;
}) => {
  const { t } = useTranslation('events');
  const { orderBy, handleSort, getSortedState } = useSortTable();
  const eventColumns = useAtomValue(eventsDataVisibleColumnsAtom);
  const eventColumnSortKeys = eventColumns.map(key => LINK_SORTING_MAP[key] || key);

  const sortedColumnIndex = orderBy ? eventColumnSortKeys.findIndex(key => key === orderBy) : -1;
  const highlightedColumnIndex = getHighlightedColumnIndex(sortedColumnIndex, !!isVideoColumnVisible);

  const currentlySelectedVideoId = useCurrentlySelectedVideoId();

  const selectedRow = () => {
    const rowIndex = events.findIndex(
      event =>
        String(event['event.attributes.originatingArqamEventId'] || event['event.attributes.arqamEventId']) ===
        currentlySelectedVideoId,
    );
    return rowIndex + 1;
  };

  const hasData = events.length > 0;

  return (
    <TableWrapper>
      <div className={hasData ? 'overflow-x-auto' : 'overflow-x-hidden'}>
        <Table
          isHeadSticky
          withBorder={false}
          highlightedColumn={highlightedColumnIndex}
          selectedRows={[selectedRow()]}
        >
          {eventColumns.length > 0 && (
            <Table.Head>
              <Table.Row>
                <HasVideoAccess>
                  <Table.HeaderCell size="regular" />
                </HasVideoAccess>
                {eventColumnSortKeys.map((key, index) => {
                  const formatRule = eventDataTableColumns?.find(column => column.key === key)?.formatRule;
                  const isLowerBetter =
                    formatRule === 'string' || formatRule === 'string-translate' || formatRule === 'link';

                  return (
                    <Table.HeaderCell
                      key={key}
                      textAlign={alignTableCell(formatRule)}
                      sortCb={() => handleSort(key, isLowerBetter)}
                      sortState={getSortedState(key)}
                      size="regular"
                    >
                      <span data-testid={`column-${index}`}>{t(`keys.${key}`)}</span>
                    </Table.HeaderCell>
                  );
                })}
              </Table.Row>
            </Table.Head>
          )}
          {hasData && (
            <Table.Body>
              {events.map(event => (
                // TODO (PPC-324: once we've completely moved over to new style events, remove the fallback here)
                <Table.Row key={String(event['event.eventId'])}>
                  <HasVideoAccess>
                    <Table.DataCell>
                      <PlayVideoButton
                        eventId={String(event['event.eventId'])}
                        uuid={String(
                          event['event.attributes.originatingArqamEventId'] || event['event.attributes.arqamEventId'],
                        )}
                      />
                    </Table.DataCell>
                  </HasVideoAccess>
                  {eventColumns.map(key => {
                    const columnRule = eventDataTableColumns?.find(column => column.key === key)?.formatRule;
                    const value = translateValue(key, event[key], columnRule, t);
                    return <CellValueToDisplay key={key} eventKey={key} value={value} columnRule={columnRule} />;
                  })}
                </Table.Row>
              ))}
            </Table.Body>
          )}
        </Table>
      </div>
      {!isLoading && !hasData && <EmptyState />}
      {isLoading && <LoadingState />}
    </TableWrapper>
  );
};

const EventsTableWithData = ({ eventsAtom }: { eventsAtom: PromiseAtom<NestedObject[]> }) => {
  const events = useAtomValue(eventsAtom);
  const eventDataTableColumns = useAtomValue(tableColumnsAtom);
  const isVideoColumnVisible = useAtomValue(hasVideoLicenceAtom);

  return <EventsTableBase {...{ events, eventDataTableColumns, isVideoColumnVisible }} />;
};

export const EventsTableWithPagination = ({
  eventsAtom,
  dataLengthAtom,
}: {
  eventsAtom: NestedObjectPromiseAtom;
  dataLengthAtom: PromiseAtom<number>;
}) => (
  <ErrorBoundary fallback={<GeneralErrorBoundaryFallback />}>
    <div>
      <Suspense fallback={<EventsTableBase isLoading />}>
        <EventsTableWithData {...{ eventsAtom }} />
      </Suspense>
      <TablePagination {...{ dataLengthAtom }} />
    </div>
  </ErrorBoundary>
);
