import { unwrappedTeamSelectedGamesForQueryAtom } from '@/atoms/filters/team/teamGames';
import { teamEventMetricFiltersDebounceObject } from '@/atoms/queries/team/teamEventData';
import { teamRadarFilterDebounceObject } from '@/atoms/queries/team/teamRadar';
import { playerAggsFilterParamsDebounceObject as teamSquadAggsFilterParamsDebounceObject } from '@/atoms/queries/player/playerAggData';
import { teamIdAtom } from '@/atoms/team/team';
import { DEFAULT_DEBOUNCE_MS } from '@/consts/api';
import { optionsToValueStrings, toObject } from '@/utils/object';
import { useAtomValue, useSetAtom } from 'jotai';
import { useEffect } from 'react';

export const useSetTeamDataDebouncedFilterParams = () => {
  const teamId = useAtomValue(teamIdAtom);
  const teamGameIds = optionsToValueStrings(useAtomValue(unwrappedTeamSelectedGamesForQueryAtom));
  const { debouncedValueAtom: teamEventMetricFiltersAtom, timeoutDelayAtom: teamEventMetricFiltersTimeoutDelayAtom } =
    teamEventMetricFiltersDebounceObject;
  const setTeamEventMetricFilters = useSetAtom(teamEventMetricFiltersAtom);
  const setTeamEventMetricFiltersTimeoutDelay = useSetAtom(teamEventMetricFiltersTimeoutDelayAtom);

  const { debouncedValueAtom: teamRadarFilterAtom, timeoutDelayAtom: teamRadarFilterTimeDelayAtom } =
    teamRadarFilterDebounceObject;
  const setTeamRadarFilter = useSetAtom(teamRadarFilterAtom);
  const setTeamRadarFilterTimeDelay = useSetAtom(teamRadarFilterTimeDelayAtom);

  const {
    debouncedValueAtom: teamSquadAggsFilterParamsDebounceAtom,
    timeoutDelayAtom: teamSquadAggsFilterParamsTimeoutDelayAtom,
  } = teamSquadAggsFilterParamsDebounceObject;
  const setTeamSquadAggsFilterParamsDebounce = useSetAtom(teamSquadAggsFilterParamsDebounceAtom);
  const setTeamSquadAggsFilterParamsTimeoutDelay = useSetAtom(teamSquadAggsFilterParamsTimeoutDelayAtom);

  const updateTimeouts = (timeout: number) => {
    setTeamEventMetricFiltersTimeoutDelay(timeout);
    setTeamRadarFilterTimeDelay(timeout);
    setTeamSquadAggsFilterParamsTimeoutDelay(timeout);
  };

  useEffect(() => {
    // When team id updates, set the timeout delay to 0 so that our new query runs immediately.
    updateTimeouts(0);
  }, [teamId]);

  useEffect(() => {
    // If there's no games, set the timeout to 0 as this won't trigger an API call.
    if (!teamGameIds.length) updateTimeouts(0);
    setTeamEventMetricFilters({
      eq: { 'event.game_id': teamGameIds },
    });
    setTeamRadarFilter({
      eq: { game_id: teamGameIds },
    });
    setTeamSquadAggsFilterParamsDebounce({
      eq: {
        ...toObject('input.team_id', teamId),
        'input.game_id': teamGameIds,
      },
    });
    // If there were games this time, set the timeout so that we debounce subsequent calls
    if (teamGameIds.length) updateTimeouts(DEFAULT_DEBOUNCE_MS);
  }, [JSON.stringify(teamGameIds)]);
};
