import { Typography } from '@statsbomb/kitbag-components';
import { useTranslation } from 'react-i18next';
import { ContentStateWrapper } from './ContentStateWrapper';

export const LoadingState = () => {
  const { t } = useTranslation('general');
  return (
    <ContentStateWrapper id="loading-state">
      <Typography variant="bodyRegular">{t('loading')}</Typography>
    </ContentStateWrapper>
  );
};
