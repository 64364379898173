import { Search } from '../search/Search';
import { SupportLinks } from './SupportLinks';

export const TopBar = ({ children }: { children?: React.ReactNode }) => (
  <div data-testid="top-bar" className="pr-4 md:px-4 bg-canvas-tertiary-main w-full flex h-8 relative">
    <div className="flex items-center w-full gap-2">
      <div className="flex gap-2 items-center flex-1 truncate">{children}</div>
      <div className="flex gap-2 items-center">
        <div className="hidden md:block relative">
          <SupportLinks />
        </div>
        <Search />
      </div>
    </div>
  </div>
);
