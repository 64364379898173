import { eventsDataVisibleColumnsAtom } from '@/atoms/dataLocker/events/table';
import { selectedGameTypeAtom } from '@/atoms/filters/gameMetrics';
import {
  deselectedGamesAtom,
  selectedEndDateAtom,
  selectedGameRangeAtom,
  selectedStartDateAtom,
} from '@/atoms/filters/highLevel';
import { selectedCompetitionIdsAtom } from '@/atoms/filters/highLevel/competitions';
import { selectedPositionsAtom } from '@/atoms/filters/highLevel/positions';
import { selectedSeasonIdsAtom } from '@/atoms/filters/highLevel/seasons';
import { selectedTeamIdsAtom } from '@/atoms/filters/highLevel/teams';
import { selectedPlayerNameAtom } from '@/atoms/player/info';
import { playerIdAtom } from '@/atoms/player/player';
import { playerEventMetricFiltersAtom } from '@/atoms/queries/player/playerEventData';
import { renderedRadarDataAtom, selectedRadarTemplateIdAtom } from '@/atoms/radar';
import { isMobileFiltersPanelOpenAtom, isSettingsPanelOpenAtom } from '@/atoms/sidebar';
import { selectedVideoIdsBaseAtom } from '@/atoms/video';
import { selectedVisEventsAtom } from '@/atoms/vis/selection';
import {
  isStartPlacementAtom,
  overwrittenVisualisationAtom,
  pitchCropAtom,
  pitchOrientationDashboardWidgetAtom,
  selectedVisualisationAtom,
  showArrowsAtom,
} from '@/atoms/visualisation';
import { LoadingState } from '@/components/contentState/LoadingState';
import { PlayerNav } from '@/components/navigation/PlayerNav';
import { TableScopeProvider } from '@/components/tables/TableScopeProvider';
import { EntityTopBar } from '@/components/topBar/EntityTopBar';
import { usePlayerReferenceData } from '@/hooks/dataFetching/usePlayerReferenceData';
import { useAtomsToHydrate } from '@/hooks/useAtomsToHydrate';
import { useAutoResetPagination } from '@/hooks/useAutoResetPagination';
import { useAutoResetSelectionLayer } from '@/hooks/useAutoResetSelectionLayer';
import { useSetMetricKey } from '@/hooks/useMetricKey';
import { useSetPlayerId } from '@/hooks/usePlayerId';
import { useAtomValue } from 'jotai';
import { ScopeProvider } from 'jotai-scope';
import { Outlet } from 'react-router-dom';
import { selectedFilterSetIdAtom } from '@/atoms/filters/userFilters';
import { AppShell } from '../AppShell';
import { SectionWrapper } from '../SectionWrapper';

const PlayerLayoutInner = () => {
  usePlayerReferenceData();
  const playerEventMetricFilters = useAtomValue(playerEventMetricFiltersAtom);
  useAutoResetSelectionLayer(playerEventMetricFilters);
  useAutoResetPagination(playerEventMetricFilters);
  useAtomsToHydrate();

  return (
    <SectionWrapper section="player">
      <Outlet />
    </SectionWrapper>
  );
};

export const PlayerLayout = () => {
  useSetPlayerId();
  useSetMetricKey();

  const playerId = useAtomValue(playerIdAtom);

  return (
    <ScopeProvider
      atoms={[
        selectedCompetitionIdsAtom,
        selectedSeasonIdsAtom,
        selectedTeamIdsAtom,
        selectedGameRangeAtom,
        selectedStartDateAtom,
        selectedEndDateAtom,
        selectedGameTypeAtom,
        deselectedGamesAtom,
        selectedVisEventsAtom,
        selectedVideoIdsBaseAtom,
        renderedRadarDataAtom,
        showArrowsAtom,
        selectedVisualisationAtom,
        overwrittenVisualisationAtom,
        eventsDataVisibleColumnsAtom,
        selectedPositionsAtom,
        isSettingsPanelOpenAtom,
        isMobileFiltersPanelOpenAtom,
        pitchCropAtom,
        pitchOrientationDashboardWidgetAtom,
        selectedRadarTemplateIdAtom,
        selectedFilterSetIdAtom,
        isStartPlacementAtom,
      ]}
    >
      <AppShell topBar={<EntityTopBar selectedEntityNameAtom={selectedPlayerNameAtom} />} subnav={<PlayerNav />}>
        <TableScopeProvider>{playerId ? <PlayerLayoutInner /> : <LoadingState />}</TableScopeProvider>
      </AppShell>
    </ScopeProvider>
  );
};
