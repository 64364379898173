import { playerDataVisibleColumnsAtom } from '@/atoms/dataLocker/player/table';
import { playerAggsCountAtom, playerAggsDataAtom } from '@/atoms/queries/player/playerAggData';
import { EmptyState } from '@/components/contentState/EmptyState';
import { LoadingState } from '@/components/contentState/LoadingState';
import { GeneralErrorBoundaryFallback } from '@/components/errorBoundary/ErrorBoundaryFallback';
import { CellValueToDisplay } from '@/components/tables/CellValueToDisplay';
import { useSortTable } from '@/hooks/useSortTable';
import { NestedObject } from '@/types/object';
import { getMetricKeyFromColumnKey } from '@/utils/metrics';
import { ErrorBoundary } from '@sentry/react';
import { Table } from '@statsbomb/kitbag-components';
import { useAtom, useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { getTranslationColumns } from '@/utils/translations';
import { snakeToCamel } from '@/utils/queries';
import { TablePagination } from './TablePagination';
import { TableWrapper } from './TableWrapper';
import { PLAYER_DATA_LOCKER_COLUMNS } from './PlayerAggsTable.constants';

const AggTable = ({ data }: { data: NestedObject[] }) => {
  const { t } = useTranslation('events');
  const { orderBy, handleSort, getSortedState } = useSortTable();
  const [searchParams] = useSearchParams();
  const playerId = searchParams.get('playerId');
  const columns = useAtomValue(playerDataVisibleColumnsAtom);

  const hasData = data.length > 0;
  if (!hasData) return <EmptyState />;

  const allColumns = ['player.name', ...columns];
  // +1 because highlightedColumn is 1 based index
  const highlightedColumn = orderBy ? allColumns.findIndex(key => key === orderBy) + 1 : undefined;

  const selectedRow = () => {
    const rowIndex = data.findIndex(playerObj => String(playerObj['player.playerId']) === playerId);
    return [rowIndex + 1];
  };

  const getColumnRule = (metricKey: string) =>
    PLAYER_DATA_LOCKER_COLUMNS.find(column => column.key.endsWith(metricKey));

  return (
    <TableWrapper>
      <div className="overflow-x-auto">
        <Table isHeadSticky withBorder={false} highlightedColumn={highlightedColumn} selectedRows={selectedRow()}>
          <Table.Head>
            <Table.Row>
              <Table.HeaderCell
                textAlign="left"
                sortCb={() => handleSort('player.name', true)}
                sortState={getSortedState('player.name')}
                size="regular"
              >
                <span>{t('keys.player.name')}</span>
              </Table.HeaderCell>
              {columns.map((column, index) => {
                const { translationKey, translationNs } = getTranslationColumns(column);

                return (
                  <Table.HeaderCell
                    key={column}
                    textAlign="right"
                    sortCb={() => handleSort(column)}
                    sortState={getSortedState(column)}
                    size="regular"
                  >
                    <span data-testid={`column-${index}`}>{t(translationKey, { ns: translationNs })}</span>
                  </Table.HeaderCell>
                );
              })}
            </Table.Row>
          </Table.Head>

          <Table.Body>
            {data.map(row => (
              // This `key` will need to change if we group by cycle or anything else
              <Table.Row key={String(row['player.playerId'])}>
                <CellValueToDisplay
                  key={`playerLink-${row['player.playerId']}`}
                  value={row?.playerLink}
                  columnRule="link"
                  eventKey="playerLink"
                />
                {columns.map(key => {
                  const metricKey = getMetricKeyFromColumnKey(key);
                  const columnRule = getColumnRule(snakeToCamel(metricKey));
                  const formatRule = columnRule?.formatRule || 'float-2';
                  const path =
                    columnRule?.canDrillDown === false
                      ? undefined
                      : `metricKey=${metricKey}&playerId=${String(row['player.playerId'])}`;

                  return (
                    <CellValueToDisplay
                      key={key}
                      value={row[key]}
                      columnRule={formatRule}
                      eventKey={key}
                      previewLinkParams={path}
                    />
                  );
                })}
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
    </TableWrapper>
  );
};

const PlayerAggsTableWithData = () => {
  const [{ data, isPending }] = useAtom(playerAggsDataAtom);
  return isPending ? <LoadingState /> : <AggTable data={data || []} />;
};

export const PlayerAggsTableWithPagination = () => (
  <ErrorBoundary fallback={<GeneralErrorBoundaryFallback />}>
    <PlayerAggsTableWithData />
    <TablePagination dataLengthAtom={playerAggsCountAtom} />
  </ErrorBoundary>
);
