import { atom } from 'jotai';
import { WidgetVis } from '@/types/widget';
import { unwrap } from 'jotai/utils';
import { ConfigDisplaySettings } from '@/types/userConfigs';
import { radarTemplateIdAtom } from '../radar';
import { pitchOrientationAtom, pitchCropAtom, showArrowsAtom, isStartPlacementAtom } from '../visualisation';
import { dashboardConfigsAtom } from '../queries/userConfigs';
import { selectedDashboardIdAtom } from './dashboard';

export const dashboardToSaveAtom = atom<null | WidgetVis[]>(null);

export const dashboardEditModeAtom = atom<boolean>(false);

export const dashboardWidgetsToRenderAtom = atom(async get => {
  const savedDashboards = await get(dashboardConfigsAtom);
  const selectedDashboardId = get(selectedDashboardIdAtom);

  const unsavedDashboard = get(dashboardToSaveAtom);

  if (savedDashboards.length === 0) return [];
  if (unsavedDashboard) return unsavedDashboard;

  /* this is to handle the current implementation where you go straight to your only dashboard
  rather than actually select one */
  /* TODO (PPC-1417: selectedDashboardIdAtom transition away from undefined) */
  if (selectedDashboardId === undefined) return savedDashboards[0].definition.widgets;

  const selectedDashboard = savedDashboards.find(({ configId }) => configId === selectedDashboardId);
  /* you should never get to this else block as you can't select a dashboard that doesn't exist
  but this ternary handles the "undefined" from find */
  /* TODO (PPC-1398: Dashboard route fallback for uncreated dashboard) */
  return selectedDashboard ? selectedDashboard.definition.widgets : /* istanbul ignore next */ [];
});

export const unwrappedDashboardWidgetsToRenderAtom = unwrap(dashboardWidgetsToRenderAtom, prev => prev || []);

export const widgetDisplaySettingsAtom = atom(async get => {
  const persistValues: ConfigDisplaySettings = {
    radarTemplateId: await get(radarTemplateIdAtom),
    pitchOrientation: get(pitchOrientationAtom),
    pitchCrop: get(pitchCropAtom),
    showArrows: get(showArrowsAtom),
    isStartPlacement: get(isStartPlacementAtom),
  };
  return persistValues;
});
