import { defaultUserTeamAndPlayerIdsAtom } from '@/atoms/queries/user';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';
import { useShouldShowHiddenFeature } from './useShouldShowHiddenFeature';

export const useNavigationItems = () => {
  const {
    t,
    i18n: { language: locale },
  } = useTranslation('navigation');
  const { data } = useAtomValue(defaultUserTeamAndPlayerIdsAtom);
  const shouldShowFeature = useShouldShowHiddenFeature();

  return {
    primary: [
      {
        icon: 'Dashboard',
        /* TODO (PPC-1422: Remove showFeature flag from dashboard navigation) */
        path: `/${locale}/${shouldShowFeature ? 'dashboards' : /* istanbul ignore next */ 'dashboard'}`,
        label: t('primary.dashboard'),
      },
      {
        icon: 'PlayerSoccer',
        path: data ? `/${locale}/player/${data.playerId}/radar` : '#player',
        label: t('primary.player'),
      },
      {
        icon: 'TeamSoccer',
        path: data ? `/${locale}/team/${data.teamId}/radar` : '#team',
        label: t('primary.team'),
      },
      {
        icon: 'IqScout',
        path: `/${locale}/data-locker`,
        label: `${t('primary.dataLocker')}`,
      },
    ],
    footer: [
      {
        icon: 'AccountSettings',
        path: `/${locale}/settings`,
        label: t('primary.settings'),
      },
    ],
  };
};
