import { KeysToCamelCase } from '@/types/generic';
import { NestedObject } from '@/types/object';

const isNestedObject = (val: NestedObject[string]): val is NestedObject =>
  !Array.isArray(val) && typeof val === 'object' && val !== null;

export const snakeToCamel = (str: string) => str.replace(/_([a-z0-9])/gi, (_, letter) => letter.toUpperCase());
export const kebabToCamel = (str: string) => str.replace(/-([a-z0-9])/gi, (_, letter) => letter.toUpperCase());
export const camelToSnakeCase = (str: string) => str.replace(/[A-Z]|\d+/g, letter => `_${letter.toLowerCase()}`);

// Convert object keys from snake to camel case. Works with nested objects / arrays
export const objSnakeToCamel = <T extends NestedObject>(record: T): KeysToCamelCase<T> =>
  Object.entries(record).reduce((newObj, [key, value]) => {
    const camelKey = snakeToCamel(key);
    if (Array.isArray(value)) {
      return {
        ...newObj,
        [camelKey]: value.map(v => (isNestedObject(v) ? objSnakeToCamel(v) : v)),
      };
    }
    return {
      ...newObj,
      [camelKey]: isNestedObject(value) ? objSnakeToCamel(value) : value,
    };
  }, {} as KeysToCamelCase<T>);
