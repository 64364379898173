import { ButtonIcon, Menu, MenuItem, MenuItemText, Icon } from '@statsbomb/kitbag-components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const SupportLinks = ({ isMobile }: { isMobile?: boolean }) => {
  const { t } = useTranslation('general');
  const [isOpen, setIsOpen] = useState(false);
  const menuItems = [
    { label: t('goToAssist'), link: 'https://statsbombhelp.zendesk.com/hc/' },
    { label: t('contactUs'), link: 'mailto:support@statsbomb.com' },
    { label: t('shareFeedback'), link: 'https://info.statsbomb.com/StatsBomb-Beta-Testing' },
    {
      label: t('termsAndConditions'),
      link: 'https://docs.google.com/document/d/e/2PACX-1vS8lFrLU7iLYmn5JF02DufykVas3T6mBfXSyr7NbUGnWzxg-rKLr0mN-sqFeascpqK7oY6dxb34-B46/pub',
    },
  ];

  return (
    <>
      <ButtonIcon
        displayText="hidden"
        icon="Support"
        size={isMobile ? 'regular' : 'small'}
        onClick={() => setIsOpen(!isOpen)}
        variant={isMobile ? 'ghost' : 'secondary'}
        title={t('support')}
      >
        {t('support')}
      </ButtonIcon>
      {isOpen && (
        <span className="absolute top-7 right-0 z-10">
          <Menu onOutsideClick={() => setIsOpen(false)}>
            {menuItems.map(item => (
              <MenuItem key={item.label}>
                <MenuItemText>
                  <a
                    className="absolute top-0 left-0 px-2 py-[6px] h-full w-full"
                    href={item.link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {item.label}
                    <span className="absolute right-2">
                      <Icon variant="ChevronRight" size="small" />
                    </span>
                  </a>
                </MenuItemText>
              </MenuItem>
            ))}
          </Menu>
        </span>
      )}
    </>
  );
};
