import { atomWithMutation } from 'jotai-tanstack-query';
import {
  UserConfigDashboard,
  UserConfigFilter,
  UserConfigPatchMutationParams,
  UserConfigPutMutationParams,
  UserConfigType,
} from '@/types/userConfigs';
import { Gender, UserConfigDefinition } from '@statsbomb/parachute-types';
import { fetchClientAtom } from '../../queries/client';

export const isValidUserConfigDefinition = ({
  configType,
  configDefinition,
}: {
  configType: UserConfigType;
  configDefinition: UserConfigDefinition;
}) => {
  if (configType === 'filters') {
    const { filters } = configDefinition as UserConfigFilter;
    return filters !== undefined;
  }

  if (configType === 'gender') {
    const { gender } = configDefinition;
    return Object.values(Gender).some(g => g === gender);
  }

  if (configType === 'settings') {
    const { teamId } = configDefinition;
    return typeof teamId === 'number';
  }

  return (configDefinition as UserConfigDashboard).widgets !== undefined;
};

const getIsSuccessfulResponse = (status: number) => status >= 200 && status < 300;

export const upsertUserConfigAtom = atomWithMutation(get => {
  const mutationKey = ['upsertUserConfig'];

  const mutationFn = async (userConfigMutations: UserConfigPutMutationParams[]) => {
    const { fetch } = get(fetchClientAtom);

    const areAllDefinitionsValid = userConfigMutations.every(({ configType, configDefinition }) => {
      const isValid = isValidUserConfigDefinition({ configType, configDefinition });
      if (!isValid) {
        // eslint-disable-next-line no-console
        console.error(
          `Bad config definition provided for ${configType} config. ${JSON.stringify(configDefinition, null, 2)}`,
        );
      }
      return isValid;
    });

    if (!areAllDefinitionsValid) {
      return { isSuccess: false };
    }

    const itemDefinitions = userConfigMutations.map(({ configId, configType, configDefinition }) => ({
      config_id: configId || crypto.randomUUID(),
      type: configType,
      definition: configDefinition,
    }));

    const response = await fetch('/user/configs', {
      method: 'PUT',
      body: JSON.stringify(itemDefinitions),
      headers: {
        'Content-type': 'application/json',
      },
    });

    return {
      configIds: itemDefinitions.map(({ config_id: configId }) => configId),
      isSuccess: getIsSuccessfulResponse(response.status),
    };
  };

  return { mutationFn, mutationKey };
});

export const deleteUserConfigAtom = atomWithMutation(get => {
  const mutationKey = ['deleteUserConfig'];
  const mutationFn = async (configId: string) => {
    const { fetch } = get(fetchClientAtom);

    const response = await fetch(`/user/config/${configId}`, {
      method: 'DELETE',
      headers: {
        'Content-type': 'application/json',
      },
    });

    return { isSuccess: getIsSuccessfulResponse(response.status) };
  };

  return { mutationFn, mutationKey };
});

export const patchUserConfigAtom = atomWithMutation(get => {
  const mutationKey = ['patchUserConfig'];
  const mutationFn = async ({ configId, configDefinition }: UserConfigPatchMutationParams) => {
    const { fetch } = get(fetchClientAtom);

    const response = await fetch(`/user/configs`, {
      method: 'PATCH',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify([{ config_id: configId, definition: configDefinition }]),
    });

    return { isSuccess: getIsSuccessfulResponse(response.status) };
  };

  return { mutationFn, mutationKey };
});
