import { LinkObject } from '@/types/generic';
import { Typography } from '@statsbomb/kitbag-components';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const linkSections: { [key: string]: string } = {
  playerLink: 'player',
  teamLink: 'team',
};

export const TableLink = ({ id, label, type }: LinkObject) => {
  const {
    i18n: { language },
  } = useTranslation();

  const section = linkSections[type];
  const url = section ? `/${language}/${section}/${id}/radar` : '#';

  return (
    // TODO (KC-344: Remove text size override once supported in KC)
    <Typography as={Link} variant="linkRegular" className="!text-xs underline-offset-4" to={url} relative="path">
      {label}
    </Typography>
  );
};
