import { Typography } from '@statsbomb/kitbag-components';
import { PitchViz, PositionSelectionLayer } from '@statsbomb/kitbag-datavis';
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';
import { selectedPositionsAtom } from '@/atoms/filters/highLevel/positions';
import { usePositionUIElements } from '@/hooks/usePositionUIElements';
import { ResetButton } from '../button/ResetButton';

export const PitchPositionFilter = () => {
  const [selectedPositions, setSelectedPositions] = useAtom(selectedPositionsAtom);
  const positionUIElements = usePositionUIElements();
  const { t } = useTranslation(['filters', 'events']);

  return (
    <div className="flex flex-col gap-y-2">
      <div className="flex items-center gap-2">
        <Typography variant="headingMedium" className="flex-grow">
          {t('position', { ns: 'filters' })}
        </Typography>
        <span data-testid="position-filter-reset">
          <ResetButton handleClick={() => setSelectedPositions([])} />
        </span>
      </div>
      <PitchViz
        overlay={
          <PositionSelectionLayer
            playerPositions={positionUIElements}
            selectedPositions={selectedPositions}
            onPositionClick={position => {
              const newPositions = selectedPositions.includes(position)
                ? selectedPositions.filter(selectedPosition => selectedPosition !== position)
                : [position, ...selectedPositions];

              setSelectedPositions(newPositions);
            }}
          />
        }
      />
    </div>
  );
};
