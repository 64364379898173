import { selectedPlayerNameAtom } from '@/atoms/player/info';
import { Typography } from '@statsbomb/kitbag-components';
import { useAtomValue } from 'jotai';

export const EntityName = () => {
  const entityName = useAtomValue(selectedPlayerNameAtom);

  if (!entityName) return null;

  return (
    <Typography as="span" variant="headingMedium" data-testid="entity-name">
      {entityName}
    </Typography>
  );
};
