import { Grid } from '@statsbomb/kitbag-components';
import classNames from 'classnames';
import React, { Children } from 'react';
import { useOutlet } from 'react-router-dom';

export const FiftyFiftyColumnLayout = ({ children }: { children: ReadonlyArray<React.ReactNode> & { length: 2 } }) => {
  const childrenArray = Children.toArray(children);
  const [columnOne, columnTwo] = childrenArray;
  const outlet = useOutlet();

  const lgGridSize = outlet ? 12 : 6;

  return (
    <div className={classNames({ 'column-divider-wrapper': !outlet })}>
      <Grid>
        <Grid item sm={12} lg={lgGridSize}>
          <div className={classNames({ 'lg:mr-4': !outlet })}>{columnOne}</div>
        </Grid>
        <Grid item sm={12} lg={lgGridSize}>
          <div className={classNames({ 'lg:hidden': !outlet }, 'border-t-8 border-canvas-primary-main my-4 -mx-4')} />
          <div className={classNames({ 'lg:ml-4': !outlet })}>{columnTwo}</div>
        </Grid>
      </Grid>
    </div>
  );
};
