import { navigationOpenAtom } from '@/atoms/navigation';
import { useApplicationReferenceData } from '@/hooks/dataFetching/useApplicationReferenceData';
import { useFilterStateHistory } from '@/hooks/useFilterStateHistory';
import { useNavigationItems } from '@/hooks/useNavigationItems';
import { useOnBackButtonClick } from '@/hooks/useOnBackButtonClick';
import { useTheme } from 'styled-components';
import {
  AppBar,
  SideNavigation,
  SideNavigationHeader,
  SideNavigationItem,
  SideNavigationMenuList,
  TopBarInfo,
} from '@statsbomb/kitbag-components';
import { useAtom, useSetAtom } from 'jotai';
import { ReactNode, Suspense } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { isSettingsPanelOpenAtom } from '@/atoms/sidebar';
import LogoIcon from '../../images/logos/sbIconLogo.svg';
import LogoText from '../../images/logos/sbTextLogo.svg';
import { SideNavFooterLinks } from '../navigation/SideNavFooterLinks';
import { SupportLinks } from '../topBar/SupportLinks';
import { Loading } from '../translations/Generic';
import { GenericToast } from '../alerts/GenericToast/GenericToast';

const LogoLink = ({ id, children }: { id: string; children: ReactNode }) => {
  const navigationItems = useNavigationItems();
  const defaultTeamLink = navigationItems.primary.find(item => item.icon === 'TeamSoccer')!;

  return (
    <Link to={defaultTeamLink.path}>
      <div data-testid={`logo-link-${id}`}>{children}</div>
    </Link>
  );
};

export const AppShellWithData = ({
  topBar,
  subnav,
  children,
}: {
  topBar: ReactNode;
  subnav?: ReactNode;
  children: ReactNode;
}) => {
  useApplicationReferenceData();
  useFilterStateHistory();
  useOnBackButtonClick();
  const setIsSettingsPanelOpen = useSetAtom(isSettingsPanelOpenAtom);

  const [isNavOpen, setIsNavOpen] = useAtom(navigationOpenAtom);
  const navigationItems = useNavigationItems();
  const { pathname } = useLocation();
  const theme = useTheme();
  const isTouch = theme.mqMatch('md');
  // The split always has 3 elements, an empty string, the language and the section
  const pathSection = String(pathname.split('/').at(2));

  return (
    <>
      <div className="flex h-full isolate" data-testid="appshell">
        <div className="z-10">
          <SideNavigation version={2} isOpen={isNavOpen} isTouch={isTouch}>
            <SideNavigationHeader
              isOpen={isNavOpen}
              isTouch={isTouch}
              logoIcon={
                <LogoLink id="icon">
                  <LogoIcon />
                </LogoLink>
              }
              logoText={
                <LogoLink id="text">
                  <LogoText />
                </LogoLink>
              }
              onClick={() => setIsNavOpen(!isNavOpen)}
              optionalControl={
                <div className="relative">
                  <SupportLinks isMobile />
                </div>
              }
            />
            <div className="flex-1">
              <SideNavigationMenuList>
                {navigationItems.primary.map(({ icon, path, label }) => (
                  <SideNavigationItem
                    key={path}
                    version={2}
                    icon={icon}
                    component={Link}
                    componentProps={{
                      to: path,
                      onClick: () => {
                        if (isTouch) setIsNavOpen(false);
                        setIsSettingsPanelOpen(false);
                      },
                    }}
                    isSelected={path.includes(pathSection)}
                  >
                    {label}
                  </SideNavigationItem>
                ))}
              </SideNavigationMenuList>
            </div>
            <SideNavFooterLinks isTouch={isTouch} />
          </SideNavigation>
        </div>
        <div className="flex flex-col w-full overflow-x-hidden grow appbar-wrapper ">
          <AppBar version={2}>
            <TopBarInfo isTouch={isTouch}>{topBar}</TopBarInfo>
            {subnav}
          </AppBar>
          {children}
        </div>
      </div>
      <GenericToast />
    </>
  );
};

export const AppShell = ({
  topBar,
  subnav,
  children,
}: {
  topBar: ReactNode;
  subnav?: ReactNode;
  children: ReactNode;
}) => (
  <Suspense fallback={<Loading />}>
    <AppShellWithData topBar={topBar} subnav={subnav}>
      {children}
    </AppShellWithData>
  </Suspense>
);
