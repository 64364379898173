import { userConfigsAtom } from '@/atoms/queries/userConfigs';
import { userConfigIdToRenameAtom } from '@/atoms/userConfigs';
import { ButtonWithMenu, MenuItem, MenuItemText, Icon } from '@statsbomb/kitbag-components';
import { useAtomValue, useSetAtom } from 'jotai';
import { Suspense, useState } from 'react';
import { useTranslation } from 'react-i18next';

const RenameUserConfigMenuItem = ({ configId, onClick }: { configId: string; onClick: (name: string) => void }) => {
  const { t } = useTranslation('general');
  const userConfigs = useAtomValue(userConfigsAtom);

  return (
    <MenuItem
      onClick={() => {
        const userConfigName =
          userConfigs.data.find(userConfig => userConfig.configId === configId)?.definition.name || '';

        onClick(userConfigName);
      }}
    >
      <div className="flex gap-x-2 items-center">
        <Icon variant="Edit" size="small" />
        <MenuItemText>{t('rename')}</MenuItemText>
      </div>
    </MenuItem>
  );
};

const DeleteUserConfigMenuItem = ({
  configId,
  onClick,
}: {
  configId: string;
  onClick: (id: string, name: string) => void;
}) => {
  const { t } = useTranslation('general');
  const userConfigs = useAtomValue(userConfigsAtom);

  return (
    <MenuItem
      onClick={() => {
        const userConfigNameToDelete =
          userConfigs.data.find(userConfig => userConfig.configId === configId)?.definition.name || t('untitled');

        onClick(configId, userConfigNameToDelete);
      }}
    >
      <div className="flex gap-x-2 items-center">
        <Icon variant="Delete" size="small" />
        <MenuItemText>{t('delete')}</MenuItemText>
      </div>
    </MenuItem>
  );
};

export interface UserConfigMenuProps {
  configId: string;
  onRenameClick: (name: string) => void;
  onDeleteClick: (id: string, name: string) => void;
}

export const UserConfigMenu = ({ configId, onRenameClick, onDeleteClick }: UserConfigMenuProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const setUserConfigIdToRename = useSetAtom(userConfigIdToRenameAtom);
  const { t } = useTranslation('userConfig');

  return (
    <ButtonWithMenu
      placement="bottom-end"
      isOpen={isOpen}
      onClick={() => setIsOpen(true)}
      onOutsideClick={() => setIsOpen(false)}
      variant="ghost"
      icon="More"
      title={t('menuTitle')}
    >
      {/* suspense here as the options use async atoms */}
      <Suspense>
        <RenameUserConfigMenuItem
          configId={configId}
          onClick={(name: string) => {
            onRenameClick(name);

            setUserConfigIdToRename(configId);
            setIsOpen(false);
          }}
        />
        <DeleteUserConfigMenuItem
          configId={configId}
          onClick={(id: string, name: string) => {
            setIsOpen(false);
            onDeleteClick(id, name);
          }}
        />
      </Suspense>
    </ButtonWithMenu>
  );
};
