import { selectedBodyPartsAtom, selectedPassHeightAtom } from '@/atoms/eventData';
import { maxAgeSelectedAtom, minAgeSelectedAtom } from '@/atoms/filters/dataLocker/ageRange';
import { maxHeightSelectedAtom, minHeightSelectedAtom } from '@/atoms/filters/dataLocker/heightRange';
import { maxMinutesSelectedAtom, minMinutesSelectedAtom } from '@/atoms/filters/dataLocker/minMinutes';
import { selectedPreferredFootAtom } from '@/atoms/filters/dataLocker/preferredFoot';
import { selectedEventNamesAtom, selectedOutcomesAtom } from '@/atoms/filters/events';
import { selectedEndDateAtom, selectedGameRangeAtom, selectedStartDateAtom } from '@/atoms/filters/highLevel';
import { selectedCompetitionIdsAtom } from '@/atoms/filters/highLevel/competitions';
import { selectedPlayerIdsAtom } from '@/atoms/filters/highLevel/players';
import { selectedPositionsAtom } from '@/atoms/filters/highLevel/positions';
import { selectedSeasonIdsAtom } from '@/atoms/filters/highLevel/seasons';
import { selectedTeamIdsAtom } from '@/atoms/filters/highLevel/teams';
import { selectedPitchFilterEndZonesAtom, selectedPitchFilterStartZonesAtom } from '@/atoms/filters/pitchFilter';
import { metricKeyAtom } from '@/atoms/metric';
import { eventDataFilterParamsAtom } from '@/atoms/queries/eventData';
import { isMobileFiltersPanelOpenAtom, isSettingsPanelOpenAtom } from '@/atoms/sidebar';
import { selectedVideoIdsBaseAtom } from '@/atoms/video';
import { selectedVisEventsAtom } from '@/atoms/vis/selection';
import {
  isStartPlacementAtom,
  overwrittenVisualisationAtom,
  pitchCropAtom,
  pitchOrientationDashboardWidgetAtom,
  selectedVisualisationAtom,
  showArrowsAtom,
} from '@/atoms/visualisation';
import { useAutoResetSelectionLayer } from '@/hooks/useAutoResetSelectionLayer';
import { TopBar } from '@/components/topBar/TopBar';
import { TopBarHeading } from '@/components/topBar/TopBarHeading';
import { useAtomValue } from 'jotai';
import { ScopeProvider } from 'jotai-scope';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { eventsDataVisibleColumnsAtom } from '@/atoms/dataLocker/events/table';
import { selectedFilterSetIdAtom } from '@/atoms/filters/userFilters';
import { SectionWrapper } from '../SectionWrapper';
import { AppShell } from '../AppShell';

const DataLockerInner = () => {
  const filterParams = useAtomValue(eventDataFilterParamsAtom);
  useAutoResetSelectionLayer(filterParams);

  return (
    <SectionWrapper section="dataLocker">
      <Outlet />
    </SectionWrapper>
  );
};

export const DataLockerLayout = () => {
  const { t } = useTranslation('navigation');

  return (
    <ScopeProvider
      atoms={[
        selectedGameRangeAtom,
        selectedSeasonIdsAtom,
        selectedCompetitionIdsAtom,
        selectedTeamIdsAtom,
        selectedPlayerIdsAtom,
        selectedStartDateAtom,
        selectedEndDateAtom,
        selectedVisEventsAtom,
        eventsDataVisibleColumnsAtom,
        selectedVideoIdsBaseAtom,
        selectedEventNamesAtom,
        selectedOutcomesAtom,
        selectedBodyPartsAtom,
        selectedPassHeightAtom,
        selectedPitchFilterStartZonesAtom,
        selectedPitchFilterEndZonesAtom,
        showArrowsAtom,
        selectedVisualisationAtom,
        overwrittenVisualisationAtom,
        isSettingsPanelOpenAtom,
        isMobileFiltersPanelOpenAtom,
        pitchCropAtom,
        pitchOrientationDashboardWidgetAtom,
        metricKeyAtom,
        minAgeSelectedAtom,
        maxAgeSelectedAtom,
        minMinutesSelectedAtom,
        maxMinutesSelectedAtom,
        minHeightSelectedAtom,
        maxHeightSelectedAtom,
        selectedPositionsAtom,
        selectedPreferredFootAtom,
        selectedFilterSetIdAtom,
        isStartPlacementAtom,
      ]}
    >
      <AppShell
        topBar={
          <TopBar>
            <TopBarHeading>{t('primary.dataLocker')}</TopBarHeading>
          </TopBar>
        }
      >
        <DataLockerInner />
      </AppShell>
    </ScopeProvider>
  );
};
