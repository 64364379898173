import { ConfigFilterAtomValues } from '@/types/userConfigs';
import { MAX_AGE, MIN_AGE } from '../filters/ageRange';
import { MAX_HEIGHT, MIN_HEIGHT } from '../filters/heightRange';
import { MAX_MINUTES, DEFAULT_MIN_MINUTES } from '../filters/minMinutes';
import { gameTypeOptions } from '../gameMetrics';
import { GAME_RANGE_FILTER_OPTIONS, PERIOD_RANGE_OPTIONS } from '../gameRange';

export const filterAtomDefaults: ConfigFilterAtomValues = {
  gameType: gameTypeOptions[0],
  periodRange: PERIOD_RANGE_OPTIONS[0],
  gameRange: GAME_RANGE_FILTER_OPTIONS[0],
  startDate: '',
  endDate: '',
  competitionIds: [],
  deselectedGameIds: [],
  // This needs to be null so that we revert to the default season for the page
  // when clicking reset
  seasonIds: null,
  playerIds: [],
  teamIds: [],
  positions: [],
  eventNames: [],
  outcomes: [],
  bodyParts: [],
  passHeights: [],
  keeperActions: [],
  pitchStartZones: [],
  pitchEndZones: [],

  minAge: MIN_AGE,
  maxAge: MAX_AGE,
  minHeight: MIN_HEIGHT,
  maxHeight: MAX_HEIGHT,
  minMinutes: DEFAULT_MIN_MINUTES,
  maxMinutes: MAX_MINUTES,
  preferredFoot: [],
};

/**
 * Sometimes the setting we want to consider default in the persisted config
 * is different to the actual atom default. seasonId is an example of this - the
 * atom default is null (which means it selects some default seasons relevant to
 * the page), but when reading the config in we want to consider no selected seasons
 * to be the default (as you're not filtering at all by season)
 */
export const filterAtomCountDefaults: ConfigFilterAtomValues = {
  ...filterAtomDefaults,
  seasonIds: [],
};
