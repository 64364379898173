import React, { Suspense, useEffect, useState } from 'react';
import { ConfirmationDialog, TextField } from '@statsbomb/kitbag-components';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { widgetIdToEditAtom } from '@/atoms/dashboard/widget';
import { useTranslation } from 'react-i18next';
import { dashboardToSaveAtom, unwrappedDashboardWidgetsToRenderAtom } from '@/atoms/dashboard/dashboardSaving';
import { usePatchUserConfig } from '@/hooks/usePatchUserConfig';
import { useShouldShowHiddenFeature } from '@/hooks/useShouldShowHiddenFeature';
import { useParams } from 'react-router-dom';

const EditWidgetTitleModalWithData = () => {
  const { t } = useTranslation(['general', 'widget']);

  const dashboardWidgets = useAtomValue(unwrappedDashboardWidgetsToRenderAtom);
  const setDashboardToSave = useSetAtom(dashboardToSaveAtom);
  const [widgetId, setWidgetId] = useAtom(widgetIdToEditAtom);
  const [titleInput, setTitleInput] = useState('');

  const patchUserConfig = usePatchUserConfig();
  const shouldShowFeature = useShouldShowHiddenFeature();
  const { dashboardId } = useParams();

  const selectedWidgetIndex = dashboardWidgets.findIndex(widget => widget.widgetId === widgetId);
  const selectedWidget = dashboardWidgets[selectedWidgetIndex];
  const isOpen = !!selectedWidget?.widgetId;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => setTitleInput(e.target.value);

  const handleConfirm = async () => {
    const newWidgetConfig = {
      ...selectedWidget,
      title: titleInput,
    };
    const updatedDashboardToSave = dashboardWidgets.with(selectedWidgetIndex, newWidgetConfig);

    /* istanbul ignore else */
    if (shouldShowFeature && dashboardId) {
      await patchUserConfig({ configId: dashboardId, configDefinition: { widgets: updatedDashboardToSave } });
    } else {
      setDashboardToSave(updatedDashboardToSave);
    }

    setWidgetId(null);
  };

  // auto populate/clear the title input
  useEffect(() => {
    setTitleInput(selectedWidget?.widgetId ? selectedWidget.title : '');
  }, [selectedWidget?.widgetId]);

  return (
    <ConfirmationDialog
      id="edit-widget-title"
      title={t('editTitle', { ns: 'widget' })}
      isOpen={isOpen}
      onConfirm={handleConfirm}
      onCancel={() => setWidgetId(null)}
      cancelLabel={t('cancel')}
      confirmLabel={t('save')}
      isDisabledConfirm={!titleInput}
    >
      <TextField
        // @ts-ignore - getting a TS error caused on KC side (KC-81)
        id="edit-title-input"
        label="Label"
        labelPosition="none"
        onChange={handleChange}
        value={titleInput}
      />
    </ConfirmationDialog>
  );
};

export const EditWidgetTitleModal = () => (
  <Suspense>
    <EditWidgetTitleModalWithData />
  </Suspense>
);
