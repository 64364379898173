export const EVENTS_DATA_LOCKER_DEFAULT_COLUMNS = [
  'event.type',
  'event.attributes.outcome',
  'playerLink',
  'teamLink',
  'competition.name',
  'season.name',
  'game.name',
  'game.date',
  'event.attributes.minute',
  'event.attributes.second',
  'dataScienceEvent.attributes.xg',
  'event.x',
  'event.y',
  'event.attributes.endX',
  'event.attributes.endY',
  'event.attributes.touch',
  'event.attributes.passHeight',
  'event.attributes.distance',
  'event.attributes.duration',
  'event.attributes.bodyPart',
  'event.attributes.inAttackingThird',
  'event.attributes.inAttackingHalf',
  'event.attributes.insideAttackingThird',
  'event.attributes.distanceToOwnGoal',
  'event.attributes.insideAttackingHalf',
  'event.attributes.distanceToOpponentsGoal',
];
