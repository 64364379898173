import { isCreateDashboardModalOpenAtom } from '@/atoms/dashboard/dashboard';
import { DashboardsTable } from '@/components/dashboard/DashboardsTable/DashboardsTable';
import { PageTitle } from '@/components/head/PageTitle';
import { AppShell } from '@/components/layout/AppShell';
import { CreateDashboardModal } from '@/components/modal/CreateDashboardModal/CreateDashboardModal';
import { RenameDashboardModal } from '@/components/modal/RenameDashboardModal/RenameDashboardModal';
import { EntityTopBarBase } from '@/components/topBar/EntityTopBar';
import { ButtonIcon, Card, Typography } from '@statsbomb/kitbag-components';
import { useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { DeleteDashboardModal } from '@/components/modal/DeleteDashboardModal/DeleteDashboardModal';

export const DashboardsPage = () => {
  const { t } = useTranslation(['navigation', 'dashboard']);
  const setIsCreateDashboardModalOpen = useSetAtom(isCreateDashboardModalOpenAtom);

  return (
    <>
      <PageTitle>{t('dashboardsHeading', { ns: 'dashboard' })}</PageTitle>
      <AppShell topBar={<EntityTopBarBase entityName={t('dashboardsHeading', { ns: 'dashboard' })} />}>
        {/* TODO (KC-345: Card component colours don't match designs - can remove the bg-canvas style) */}
        {/* TODO (KC-348: Card full height variant - will allow us to remove the [&>div]:h-full selector) */}
        <div className="px-2 py-4 -mt-6 [&>div]:bg-canvas-tertiary-main [&>div]:h-full h-full">
          {/* TODO (KC-346: Card variant without box shadow) */}
          {/* TODO (KC-347: Card with divider) */}
          {/* TODO (KC-349: Card to support 16px padding) */}
          <Card>
            <div className="pt-2 px-2 pb-4 flex gap-4">
              <Typography variant="headingRegular">{t('dashboardsTitle', { ns: 'dashboard' })}</Typography>
              <ButtonIcon
                icon="PlusAdd"
                displayText="left"
                variant="secondary"
                onClick={() => setIsCreateDashboardModalOpen(true)}
                size="small"
              >
                {t('createDashboardText', { ns: 'dashboard' })}
              </ButtonIcon>
            </div>
            <DashboardsTable />
          </Card>
        </div>
        <CreateDashboardModal />
        <RenameDashboardModal />
        <DeleteDashboardModal />
      </AppShell>
    </>
  );
};
