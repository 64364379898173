import { VisDisplayMode } from '@/types/visualisation';
import { ErrorBoundary } from '@sentry/react';
import { Grid } from '@statsbomb/kitbag-components';
import { FunctionComponent, useEffect } from 'react';
import { useVisDisplayMode } from '@/hooks/useVisDisplayMode';
import { isSettingsPanelOpenAtom } from '@/atoms/sidebar';
import { useSetAtom } from 'jotai';
import { GeneralErrorBoundaryFallback } from '../errorBoundary/ErrorBoundaryFallback';
import { FiftyFiftyColumnLayout } from '../layout/FiftyFiftyColumnLayout';

export const VisDisplaySection = ({
  defaultVisDisplayMode = VisDisplayMode.BOTH,
  EventsTableComponent,
  visualisationComponent,
}: {
  defaultVisDisplayMode?: VisDisplayMode;
  EventsTableComponent: FunctionComponent;
  visualisationComponent: JSX.Element;
}) => {
  const [visDisplayMode] = useVisDisplayMode(defaultVisDisplayMode);
  const setIsSettingsPanelOpen = useSetAtom(isSettingsPanelOpenAtom);

  const isDisplayBoth = visDisplayMode === VisDisplayMode.BOTH;
  const isDisplayTable = visDisplayMode === VisDisplayMode.TABLE;
  const isDisplayVis = visDisplayMode === VisDisplayMode.VIS;

  const isDisplayTableOnly = isDisplayTable && !isDisplayBoth;

  useEffect(() => {
    if (isDisplayTableOnly) {
      setIsSettingsPanelOpen(false);
    }
  }, [isDisplayTableOnly]);

  const lgGridSize = isDisplayBoth ? 6 : 12;
  return (
    <ErrorBoundary fallback={<GeneralErrorBoundaryFallback />}>
      {isDisplayBoth && (
        <>
          <div className="border-t-8 border-canvas-primary-main -mt-4 mb-4 -mx-4 top-bar-hr" />
          <FiftyFiftyColumnLayout>
            {visualisationComponent}
            <EventsTableComponent />
          </FiftyFiftyColumnLayout>
        </>
      )}
      {/* Get the visualisation centered in the grid */}
      {isDisplayVis && !isDisplayBoth && (
        <Grid>
          <Grid item sm={12} lg={lgGridSize}>
            <div className="flex justify-center w-full">
              <div className="flex-grow max-w-[1000px]">{visualisationComponent}</div>
            </div>
          </Grid>
        </Grid>
      )}
      {isDisplayTableOnly && (
        <Grid>
          <Grid item sm={12} lg={lgGridSize}>
            <EventsTableComponent />
          </Grid>
        </Grid>
      )}
    </ErrorBoundary>
  );
};
