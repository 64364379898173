import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonIcon, Icon, Table, Typography } from '@statsbomb/kitbag-components';
import { NestedObject } from '@/types/object';
import { DataTableColumn } from '@/types/table';
import { EmptyState } from '@/components/contentState/EmptyState';
import { LoadingState } from '@/components/contentState/LoadingState';
import { CellValueToDisplay } from '@/components/tables/CellValueToDisplay';
import { noop } from '@/utils/noop';
import { getMetricKeyFromColumnKey } from '@/utils/metrics';
import { Link } from 'react-router-dom';
import { useSortTable } from '@/hooks/useSortTable';
import { getTranslationColumns } from '@/utils/translations';
import { alignTableCell, getHighlightedColumnIndex } from '@/utils/table';
import { GameMenu } from '../games/GameMenu';
import { HasVideoAccess } from '../video/HasVideoAccess';
import { TableWrapper } from './TableWrapper';
import { NO_VIDEO_COLUMNS, PERMANENT_COLUMNS } from './GameAggregatesTable.constants';

const ProcessingAggregates = () => {
  const { t } = useTranslation('games');

  return (
    <div className="flex flex-row gap-x-1">
      <Icon variant="Info" size="small" />
      <Typography variant="bodyMedium" as="span">
        {t('processingAggregates')}
      </Typography>
    </div>
  );
};

interface GameAggregatesTableProps {
  data: NestedObject[];
  isLoading?: boolean;
  columns?: readonly DataTableColumn[];
  isVideoColumnVisible?: boolean;
}

export const GameAggregatesTable = ({
  data,
  isLoading = false,
  columns = [],
  isVideoColumnVisible,
}: GameAggregatesTableProps) => {
  const { t } = useTranslation(['events', 'games', 'video']);
  const [openMenuRow, setOpenMenuRow] = useState<number | null>(null);
  const playVideoButtonText = t('playVideo', { ns: 'video' });
  const { orderBy, handleSort, getSortedState } = useSortTable();

  const hasData = data.length > 0;
  if (isLoading) return <LoadingState />;
  if (!hasData) return <EmptyState />;

  const columnsWithMetaData = [...PERMANENT_COLUMNS, ...columns];

  const sortedColumnIndex = orderBy ? columnsWithMetaData.findIndex(col => col.key === orderBy) : -1;
  const highlightedColumnIndex = getHighlightedColumnIndex(sortedColumnIndex, !!isVideoColumnVisible);

  return (
    <TableWrapper>
      <div className="overflow-x-auto">
        <Table isHeadSticky withBorder={false} highlightedColumn={highlightedColumnIndex}>
          <Table.Head>
            <Table.Row>
              <HasVideoAccess>
                <Table.HeaderCell textAlign="left" size="regular">
                  <span>{t('gameVideo', { ns: 'games' })}</span>
                </Table.HeaderCell>
              </HasVideoAccess>
              {columnsWithMetaData.map(({ key, formatRule }, index) => {
                const { translationKey, translationNs } = getTranslationColumns(key);
                const isLowerBetter =
                  formatRule === 'string' || formatRule === 'string-translate' || formatRule === 'link';
                return (
                  <Table.HeaderCell
                    key={key}
                    textAlign={alignTableCell(formatRule)}
                    sortCb={() => handleSort(key, isLowerBetter)}
                    sortState={getSortedState(key)}
                    size="regular"
                  >
                    <span data-testid={`column-${index}`}>{t(translationKey, { ns: translationNs })}</span>
                  </Table.HeaderCell>
                );
              })}
            </Table.Row>
          </Table.Head>

          <Table.Body>
            {data.map((row, rowIndex) => {
              const rowId = String(row['game.gameId']);
              const columnsToRenderValues = row.hasAggregates ? columnsWithMetaData : PERMANENT_COLUMNS;

              return (
                <Table.Row key={rowId} className="[&:hover_.invisible]:visible [&:focus-within_.invisible]:visible">
                  <HasVideoAccess>
                    <Table.DataCell>
                      <ButtonIcon
                        size="small"
                        displayText="right"
                        variant="ghost"
                        icon="ChevronDown"
                        onClick={() => setOpenMenuRow(rowIndex)}
                      >
                        {t('watchVideo', { ns: 'games' })}
                      </ButtonIcon>
                      <GameMenu
                        isOpen={openMenuRow === rowIndex}
                        onOutsideClick={() => setOpenMenuRow(null)}
                        onClickMatchGoals={noop}
                        onClickSetPieces={noop}
                      />
                    </Table.DataCell>
                  </HasVideoAccess>
                  {columnsToRenderValues.map(({ key, formatRule }) => {
                    const value = row[key];
                    const linkParams = `?metricKey=${getMetricKeyFromColumnKey(key)}&gameId=${row['game.gameId']}`;

                    return (
                      <CellValueToDisplay key={key} value={value} columnRule={formatRule} eventKey={key}>
                        <HasVideoAccess>
                          {/* Do not show the play button for columns with no video or cells which have an event count of zero */}
                          {!NO_VIDEO_COLUMNS.includes(key) && formatRule === 'integer' && value !== 0 && (
                            <Link to={`./video${linkParams}`} className="invisible">
                              <ButtonIcon
                                as="span"
                                size="small"
                                variant="ghost"
                                icon="Play"
                                title={playVideoButtonText}
                              >
                                {playVideoButtonText}
                              </ButtonIcon>
                            </Link>
                          )}
                        </HasVideoAccess>
                      </CellValueToDisplay>
                    );
                  })}
                  {!row.hasAggregates &&
                    columns.map((_, index) =>
                      index !== 0 ? null : (
                        <Table.DataCell key={`${rowId}-processing-aggs`} colSpan={columns.length}>
                          <ProcessingAggregates />
                        </Table.DataCell>
                      ),
                    )}
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </div>
    </TableWrapper>
  );
};
