import { metricKeyAtom } from '@/atoms/metric';
import { playerIdAtom } from '@/atoms/player/player';
import { useSetPlayerEventDataDebouncedFilterParams } from '@/hooks/apiFilterParams/useSetPlayerEventDataDebouncedFilterParams';
import PlayerPreview from '@/pages/player/preview/PlayerPreview';
import { useSetAtom } from 'jotai';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

export const PlayerAggsPreview = () => {
  useSetPlayerEventDataDebouncedFilterParams();
  const [searchParams] = useSearchParams();
  const metricKey = searchParams.get('metricKey');
  const playerId = searchParams.get('playerId');

  const setMetricKey = useSetAtom(metricKeyAtom);
  const setPlayerId = useSetAtom(playerIdAtom);

  useEffect(() => {
    if (metricKey && playerId) {
      setMetricKey(metricKey);
      setPlayerId(Number(playerId));
    }
  }, [metricKey, playerId]);

  if (!playerId || !metricKey) return null;

  return <PlayerPreview />;
};
