import { useSection } from '@/hooks/useSection';
import { Section } from '@/types/generic';
import { ReactNode } from 'react';
import { useAtomsToHydrate } from '@/hooks/useAtomsToHydrate';
import { LoadingState } from '../contentState/LoadingState';

/* This wrapper is so that we await being in the correct section before hydrating the atoms */
const ChildrenWrapperToHydrate = ({ children }: { children: ReactNode }) => {
  useAtomsToHydrate();

  return children;
};

export const SectionWrapper = ({ children, section }: { children: ReactNode; section: Section }) => {
  const isSectionSet = useSection(section);

  return isSectionSet ? <ChildrenWrapperToHydrate>{children}</ChildrenWrapperToHydrate> : <LoadingState />;
};
