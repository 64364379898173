import { itemsPerPageAtom, pageAtom } from '@/atoms/pagination';
import { ITEMS_PER_PAGE_OPTIONS } from '@/consts/interface';
import { ItemsPerPageOption, Nullable } from '@/types/generic';
import { Dropdown, Pagination } from '@statsbomb/kitbag-components';
import { useAtom, useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';
import { PromiseAtom } from '@/types/atom';
import { Suspense } from 'react';
import { convertToDropdownOption } from '@/utils/object';

export const TablePaginationBase = ({ dataLength }: { dataLength: number }) => {
  const [activePage, setActivePage] = useAtom(pageAtom);
  const [itemsPerPage, setItemsPerPage] = useAtom(itemsPerPageAtom);
  const itemsPerPageOptions = ITEMS_PER_PAGE_OPTIONS;

  const { t } = useTranslation('general');
  return (
    <>
      <Pagination
        activePage={activePage}
        dataLength={dataLength}
        itemsPerPage={itemsPerPage}
        onChange={({ pageSelected }: { pageSelected: number }) => {
          setActivePage(pageSelected);
        }}
        maxVisiblePageButtons={5}
        firstPageLabel={t('pagination.firstPageLabel')}
        lastPageLabel={t('pagination.lastPageLabel')}
        nextPageLabel={t('pagination.nextPageLabel')}
        pageLabel={t('pagination.pageLabel')}
        previousPageLabel={t('pagination.previousPageLabel')}
        labelText={t('pagination.labelText')}
        ariaLabel={t('pagination.ariaLabel')}
        hasEdgePageButtons
        hasPageButtons
        withLabel
      />
      {/*
      The "container" class is needed to prevent the dropdown from becoming so narrow you can't read the selected value
      on small screens or where the pagination is rendered in a narrow space (such as the preview sidebar pane).
      */}
      <div className="[&_[class$=container]]:min-w-max">
        <Dropdown
          id="events-table-items-per-page"
          labelPosition="left"
          value={convertToDropdownOption(itemsPerPage, itemsPerPage)}
          label={`${t('pagination.numItems')}:`}
          menuPlacement="top"
          options={itemsPerPageOptions}
          onChange={(option: ItemsPerPageOption) => {
            setItemsPerPage(option.value);
          }}
        />
      </div>
    </>
  );
};

export const PaginationWithData = ({ dataLengthAtom }: { dataLengthAtom: PromiseAtom<Nullable<number>> }) => {
  const dataLength = useAtomValue(dataLengthAtom);
  return (
    dataLength !== null && (
      /* TODO (PPC-311: End class below is temp until this is resolved https://statsbomb.atlassian.net/jira/software/c/projects/RC/boards/150?selectedIssue=RC-90) */
      <div className="flex justify-end gap-4 pt-4 [&>div:first-child]:grow">
        <TablePaginationBase dataLength={dataLength} />
      </div>
    )
  );
};

export const TablePagination = ({ dataLengthAtom }: { dataLengthAtom: PromiseAtom<Nullable<number>> }) => (
  <Suspense>
    <PaginationWithData dataLengthAtom={dataLengthAtom} />
  </Suspense>
);
