import { HighLevelFilterMapAtom } from '@/types/filters';
import { Option } from '@/types/generic';
import { convertOptionsToValues } from '@/utils/array';
import { getOptionsAtomForSection } from '@/utils/atoms/highLevelFilters';
import { atom } from 'jotai';
import { unwrap } from 'jotai/utils';
import { filterAtomDefaults } from '@/consts/defaults/filterAtomDefaults';
import { playerCompetitionOptionsAtom } from '../../queries/players';
import { teamCompetitionOptionsAtom } from '../../queries/teams';
import { eventDataCompetitionsOptionsAtom } from '../dataLocker/competitions';

const competitionSectionMapAtom = atom<HighLevelFilterMapAtom<Option[]>>(() => ({
  player: playerCompetitionOptionsAtom,
  team: teamCompetitionOptionsAtom,
  dataLocker: eventDataCompetitionsOptionsAtom,
}));

export const competitionOptionsAtom = atom(getOptionsAtomForSection(competitionSectionMapAtom));

export const selectedCompetitionIdsAtom = atom(filterAtomDefaults.competitionIds);

export const unwrappedCompetitionOptionsAtom = unwrap(competitionOptionsAtom, prev => prev || []);
export const selectedCompetitionsAtom = atom(get => {
  const competitionOptions = get(unwrappedCompetitionOptionsAtom);
  const selectedCompetitionIds = get(selectedCompetitionIdsAtom);

  return competitionOptions.filter(({ value }) => selectedCompetitionIds.includes(value));
});

// when querying by gender, querying by competition gender is more reliable than player gender.
// There are places where the query doesn't support filtering by competition gender but we can still achieve
// the same result by using the competitions which are already filtered by gender, as the argument for the query
export const competitionsToQueryAtom = atom(get => {
  const selectedCompetitionIds = get(selectedCompetitionIdsAtom);
  const unwrappedCompetitionOptions = get(unwrappedCompetitionOptionsAtom);
  if (!selectedCompetitionIds.length && !unwrappedCompetitionOptions.length) {
    return [];
  }

  const competitionsToQuery = selectedCompetitionIds.length
    ? selectedCompetitionIds
    : convertOptionsToValues(unwrappedCompetitionOptions);

  return competitionsToQuery;
});
