import { ITEMS_PER_PAGE } from '@/consts/interface';

export interface DataTableColumn {
  formatRule: string;
  key: string;
  group?: string;
  canDrillDown?: boolean;
}

export type ItemsPerPage = (typeof ITEMS_PER_PAGE)[number];

export enum TableColumnsEditorTabs {
  COLUMN_SELECT = 'column-select',
  COLUMN_SORT = 'column-sort',
}
