import { atom } from 'jotai';
import React from 'react';

const toastDataBaseAtom = atom<{
  toastName?: string;
  entityName?: string;
  content?: React.ReactNode;
  type: 'success' | 'error';
  isOpen: boolean;
}>({ type: 'success', isOpen: false });

export const closeToastAtom = atom(null, (get, set) =>
  set(toastDataBaseAtom, { ...get(toastDataBaseAtom), isOpen: false }),
);

export const toastDataAtom = atom(
  get => get(toastDataBaseAtom),
  (get, set, toastName: string, isSuccess: boolean, entityName?: string, content?: React.ReactNode) =>
    set(toastDataBaseAtom, {
      toastName,
      entityName,
      content,
      type: isSuccess ? 'success' : 'error',
      isOpen: true,
    }),
);
