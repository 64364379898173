import { LayoutWithFilters } from '@/components/layout/LayoutWithFilters';
import { TableScopeProvider } from '@/components/tables/TableScopeProvider';
import { Grid, Typography } from '@statsbomb/kitbag-components';
import { useTranslation } from 'react-i18next';
import { TablePagination } from '@/components/tables/TablePagination';
import { SectionTopBar } from '@/components/sectionTopBar/SectionTopBar';
import { PageTitle } from '@/components/head/PageTitle';
import { useAtomValue } from 'jotai';
import { unwrappedSelectedTeamNameAtom } from '@/atoms/team/info';
import { useEntityPageTitle } from '@/hooks/useEntityPageTitle';
import { TeamHighLevelFilters } from '@/components/team/filters/TeamHighLevelFilters';
import { teamGameAggsCountAtom } from '@/atoms/queries/team/teamGameAggData';
import { TeamGameAggsTable } from '@/components/team/tables/TeamGameAggsTable';

export const TeamGamesPage = () => {
  const { t } = useTranslation(['games', 'navigation']);
  const teamName = useAtomValue(unwrappedSelectedTeamNameAtom);
  const pageTitle = useEntityPageTitle(t('secondary.games', { ns: 'navigation' }), teamName);

  return (
    <>
      <PageTitle>{pageTitle}</PageTitle>
      <LayoutWithFilters filters={<TeamHighLevelFilters />}>
        <Grid>
          <Grid item>
            <TableScopeProvider>
              <SectionTopBar>
                <Typography variant="headingRegular">{t('latestPerformances')}</Typography>
              </SectionTopBar>
              <TeamGameAggsTable />
              <TablePagination dataLengthAtom={teamGameAggsCountAtom} />
            </TableScopeProvider>
          </Grid>
        </Grid>
      </LayoutWithFilters>
    </>
  );
};
