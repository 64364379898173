import { loadableTeamSelectedGamesForQueryAtom } from '@/atoms/filters/team/teamGames';
import { loadableTeamRadarDataAtom } from '@/atoms/queries/team/teamRadar';
import { RadarChart } from '@/components/radar/RadarChart';
import { TeamRadarLabel } from '@/components/radar/RadarLabel';
import { Suspense } from 'react';

export const TeamRadarChart = ({ isWidget }: { isWidget?: boolean }) => (
  <>
    <RadarChart
      loadableSelectedGamesAtom={loadableTeamSelectedGamesForQueryAtom}
      loadableRadarDataAtom={loadableTeamRadarDataAtom}
      isWidget={isWidget}
    />
    <Suspense>
      <TeamRadarLabel />
    </Suspense>
  </>
);
