import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './globals.css';
import '@statsbomb/kitbag-datavis/dist/style.css';
import './i18n';
import { BrowserRouter, Route } from 'react-router-dom';
import { ErrorBoundary } from '@sentry/react';
import { HelmetProvider } from 'react-helmet-async';
import { Loading } from './components/translations/Generic';
import { Providers } from './components/Providers';
import { PlayerRoutes } from './routes/playerRoutes';
import { DataLockerRoutes } from './routes/dataLockerRoutes';
import { RedirectToLocalised } from './components/routes/RedirectToLocalised';
import { ProtectedRoute } from './components/routes/ProtectedRoute';
import { GeneralErrorBoundaryFallback } from './components/errorBoundary/ErrorBoundaryFallback';
import { SentryRoutes } from './utils/errorMonitoring';
import { TeamRoutes } from './routes/teamRoutes';
import { Head } from './components/head/Head';
import Dashboard from './pages/Dashboard';
import { RedirectToTeam } from './components/routes/RedirectToTeam';
import { Settings } from './pages/Settings';
import { GroupOnlyRoute } from './components/routes/GroupOnlyRoute';
import { DashboardsPage } from './pages/dashboard/DashboardsPage';

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <HelmetProvider>
      <Head />
      <ErrorBoundary fallback={<GeneralErrorBoundaryFallback />}>
        <Suspense fallback={<Loading />}>
          <BrowserRouter>
            <Providers>
              <SentryRoutes>
                <Route path="/">
                  <Route path="" element={<RedirectToLocalised />} />
                  <Route path=":locale">
                    <Route path="" element={<ProtectedRoute component={RedirectToTeam} />} />
                    <Route path="player/:playerId/*" element={<ProtectedRoute component={PlayerRoutes} />} />
                    <Route path="team/:teamId/*" element={<ProtectedRoute component={TeamRoutes} />} />
                    <Route path="data-locker/*" element={<ProtectedRoute component={DataLockerRoutes} />} />
                    {/* TODO (PPC-1384: Remove old dashboard route) */}
                    <Route path="dashboard" element={<ProtectedRoute component={Dashboard} />} />
                    <Route path="dashboard/:dashboardId" element={<ProtectedRoute component={Dashboard} />} />
                    <Route
                      path="dashboards"
                      element={
                        <GroupOnlyRoute group="statsbomb">
                          <ProtectedRoute component={DashboardsPage} />
                        </GroupOnlyRoute>
                      }
                    />
                    <Route path="settings/*" element={<ProtectedRoute component={Settings} />} />
                  </Route>
                </Route>
              </SentryRoutes>
            </Providers>
          </BrowserRouter>
        </Suspense>
      </ErrorBoundary>
    </HelmetProvider>
  </React.StrictMode>,
);
