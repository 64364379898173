import { TEAM_RADAR_TEMPLATES } from '@/consts/radar';
import { useTranslation } from 'react-i18next';
import { RadarCard } from '@/components/radar/RadarCard';
import { Typography } from '@statsbomb/kitbag-components';
import { ColumnTopBar } from '@/components/columnTopBar/ColumnTopBar';
import { FiftyFiftyColumnLayout } from '@/components/layout/FiftyFiftyColumnLayout';
import { TeamRadarChart } from './TeamRadarChart';
import { TeamRadarTableGroup } from './TeamRadarTableGroup';

export const TeamRadar = () => {
  const { t } = useTranslation(['radar', 'entity', 'visualisation']);
  const label = t('dropdownLabel', { entity: t('team.one', { ns: 'entity' }) });

  return (
    <FiftyFiftyColumnLayout>
      <RadarCard templates={TEAM_RADAR_TEMPLATES} label={label}>
        <TeamRadarChart />
      </RadarCard>
      <>
        <ColumnTopBar>
          <Typography variant="headingRegular">{t(`viewing.table`, { ns: 'visualisation' })}</Typography>
        </ColumnTopBar>
        <TeamRadarTableGroup />
      </>
    </FiftyFiftyColumnLayout>
  );
};
