import {
  useSensors,
  useSensor,
  PointerSensor,
  KeyboardSensor,
  DragEndEvent,
  DndContext,
  closestCenter,
} from '@dnd-kit/core';
import {
  sortableKeyboardCoordinates,
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { SetStateAction } from 'react';
import { toggleValue } from '@/utils/array';
import { SortableItem } from './SortableItem';

// Uses dnd kit - Reference: https://docs.dndkit.com/

export const SortableContent = ({
  visibleColumns,
  setVisibleColumns,
}: {
  visibleColumns: Array<string>;
  setVisibleColumns: (value: SetStateAction<string[]>) => void;
}) => {
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
      keyboardCodes: {
        start: ['Space'],
        cancel: ['Escape'],
        end: ['Space'],
      },
    }),
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over?.id && active.id !== over.id) {
      setVisibleColumns(column => {
        const oldIndex = column.indexOf(active.id as string);
        const newIndex = column.indexOf(over.id as string);
        const newOrder = arrayMove(column, oldIndex, newIndex);

        return newOrder;
      });
    }
  };

  const toggleColumn = (columnKey: string) => setVisibleColumns(toggleValue(columnKey, visibleColumns));

  return (
    <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      <SortableContext items={visibleColumns} strategy={verticalListSortingStrategy}>
        <ul className="flex flex-col gap-2 overflow-hidden px-4 pb-4 pt-0.5">
          {visibleColumns.map(column => (
            <SortableItem key={column} id={column} toggleColumn={toggleColumn} />
          ))}
        </ul>
      </SortableContext>
    </DndContext>
  );
};
