import { playerGameAggsAtom, playerGameAggsPositions } from '@/atoms/queries/player/playerGameAggData';
import { useAtomValue } from 'jotai';
import { loadable } from 'jotai/utils';

/**
 * Eagerly fetch data needed on the player page. At the moment
 * this is just for player match page, as we load all of a players
 * matches in one go rather than page by page. But this could also be
 * used for other things in the future.
 */
export const usePlayerReferenceData = () => {
  useAtomValue(loadable(playerGameAggsAtom));
  useAtomValue(loadable(playerGameAggsPositions));
};
