import { useSetPlayerAggsDebouncedFilterParams } from '@/hooks/apiFilterParams/useSetPlayerAggsDebouncedFilterParams';
import { PlayerAggsGeneralFilters } from '../filters/PlayerAggsGeneralFilters';
import { DataLockerHighLevelFilters } from './filters/DataLockerHighLevelFilters';
import { UserFilters } from '../filters/UserFilters/UserFilters';

export const PlayerAggsFilters = () => {
  useSetPlayerAggsDebouncedFilterParams();

  return (
    <div className="flex flex-col">
      <UserFilters />
      <DataLockerHighLevelFilters />
      <PlayerAggsGeneralFilters />
    </div>
  );
};
