import { Button, ButtonGroup } from '@statsbomb/kitbag-components';
import { useAtom, useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { selectedGameRangeAtom } from '@/atoms/filters/highLevel';
import { GAME_RANGE_FILTER_OPTIONS } from '@/consts/gameRange';
import { GameRangeFilterOption } from '@/types/filters';
import { useEffect } from 'react';
import { resetGameRangeFiltersAtom } from '@/atoms/filters/resetFilters';
import { DateRangeSelector } from './DateRangeSelector';
import { PeriodRangeSelector } from './PeriodRangeSelector';

const gameRangeFilterMap = {
  dateRange: DateRangeSelector,
  period: PeriodRangeSelector,
};

export const GameRangeFilterGroup = ({
  gameRangeOptions = GAME_RANGE_FILTER_OPTIONS,
}: {
  gameRangeOptions?: GameRangeFilterOption[];
}) => {
  const { t } = useTranslation(['filters', 'entity']);
  const [selectedGameRange, setSelectedGameRange] = useAtom(selectedGameRangeAtom);
  // Set the game range filter to the first valid option
  useEffect(() => {
    if (!gameRangeOptions.find(option => option.key === selectedGameRange.key)) {
      setSelectedGameRange(gameRangeOptions[0]);
    }
  });
  const resetSelectedGames = useSetAtom(resetGameRangeFiltersAtom);
  const GameRangeComponent = gameRangeFilterMap[selectedGameRange.key];

  return (
    <div className="flex flex-col justify-center px-3 py-4 border gap-y-4 border-canvas-primary-weak tabular-nums">
      {/* TODO (PPC-293: RC fix button group size/wrapping when too many items for space) */}
      <div className="overflow-x-auto">
        <div className="min-w-max">
          <ButtonGroup>
            {gameRangeOptions.map(option => (
              <Button
                key={option.key}
                size="small"
                onClick={() => {
                  setSelectedGameRange(option);
                  resetSelectedGames();
                }}
                variant={selectedGameRange.key === option.key ? 'primary' : 'ghost'}
              >
                {t(option.translation.key, { ns: option.translation.ns })}
              </Button>
            ))}
          </ButtonGroup>
        </div>
      </div>
      <GameRangeComponent />
    </div>
  );
};
