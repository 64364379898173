import { SetStateAction, useState } from 'react';
import { useAtomValue } from 'jotai';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { ButtonWithMenu, Icon, MenuItem, MenuItemText, Typography } from '@statsbomb/kitbag-components';
import { tableColumnsEditorActiveTabAtom } from '@/atoms/table';
import { TableColumnsEditorTabs } from '@/types/table';
import { SortableContent } from './SortableContent';

export const ColumnSort = ({
  visibleColumns,
  setVisibleColumns,
  defaultColumns,
}: {
  visibleColumns: string[];
  setVisibleColumns: (value: SetStateAction<string[]>) => void;
  defaultColumns: string[];
}) => {
  const { t } = useTranslation(['table', 'general']);
  const activeTab = useAtomValue(tableColumnsEditorActiveTabAtom);
  const [isOpen, setIsOpen] = useState(false);

  const handleMenuOption = (columns: string[]) => {
    setVisibleColumns(columns);
    setIsOpen(false);
  };

  return (
    <div
      className={classNames('bg-canvas-secondary-main overflow-y-auto w-full md:!w-2/5', {
        'hidden md:block': activeTab !== TableColumnsEditorTabs.COLUMN_SORT,
      })}
    >
      <div className="flex items-center justify-between p-4 sticky top-0 bg-canvas-secondary-main">
        <Typography variant="headingMedium" as="h2" className="tabular-nums">
          {t('selectedColumnsCount', { count: visibleColumns.length })}
        </Typography>
        <ButtonWithMenu
          placement="bottom-end"
          isOpen={isOpen}
          onClick={() => setIsOpen(true)}
          onOutsideClick={() => setIsOpen(false)}
          variant="ghost"
          icon="More"
          title={t('optionsMenu')}
        >
          <MenuItem onClick={() => handleMenuOption([])} disabled={!visibleColumns.length}>
            <div className="flex gap-x-2 items-center">
              <Icon variant="Erase" size="small" />
              <MenuItemText>{t('clearAll', { ns: 'general' })}</MenuItemText>
            </div>
          </MenuItem>
          <MenuItem onClick={() => handleMenuOption(defaultColumns)}>
            <div className="flex gap-x-2 items-center">
              <Icon variant="Reset" size="small" />
              <MenuItemText>{t('resetToDefault')}</MenuItemText>
            </div>
          </MenuItem>
        </ButtonWithMenu>
      </div>
      <SortableContent visibleColumns={visibleColumns} setVisibleColumns={setVisibleColumns} />
    </div>
  );
};
