import { loadablePlayerRadarDataAtom } from '@/atoms/queries/player/playerRadar';
import { loadablePlayerSelectedGamesForQueryAtom } from '@/atoms/filters/player/playerGames';
import { RadarChart } from '@/components/radar/RadarChart';
import { PlayerRadarLabel } from '@/components/radar/RadarLabel';
import { Suspense } from 'react';

export const PlayerRadarChart = ({ isWidget }: { isWidget?: boolean }) => (
  <>
    <RadarChart
      loadableSelectedGamesAtom={loadablePlayerSelectedGamesForQueryAtom}
      loadableRadarDataAtom={loadablePlayerRadarDataAtom}
      isWidget={isWidget}
    />
    <Suspense>
      <PlayerRadarLabel />
    </Suspense>
  </>
);
