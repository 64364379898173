import { playerName } from '@/atoms/queries/players';
import { SuspenseAtomResult } from '@/types/jotai';
import { EventMarker, EventWithRels } from '@statsbomb/parachute-types';
import { MarkerEvent, ArrowEvent } from '@/types/event';
import { VideoDescription } from '@/types/video';
import { addLinks, flattenAndAddPrefix } from '../object';
import { objSnakeToCamel } from '../queries';

export const processEventDataAtom = (rawEventData: SuspenseAtomResult<EventWithRels[]>) =>
  (rawEventData.data || []).map(data => {
    const flattenedEventData = flattenAndAddPrefix(objSnakeToCamel(data), '.', false);

    return addLinks(flattenedEventData, [
      { label: playerName(data.player), id: data.player.player_id, type: 'playerLink' },
      { label: data.team.name, id: data.team.team_id, type: 'teamLink' },
    ]);
  });

export const convertMarkerDataIntoMarkers = (eventMarkersData: EventMarker[] = []) =>
  eventMarkersData.reduce(
    (acc, event) => {
      const { markerEvents, arrowEvents } = acc;
      if (event.start_x == null || event.start_y == null) return acc;

      const baseEvent = {
        eventId: event.event_id,
        videoEventId: event.video_event_id || undefined,
        startX: event.start_x,
        startY: event.start_y,
      };

      return event.end_x != null && event.end_y != null
        ? {
            markerEvents,
            arrowEvents: [
              ...arrowEvents,
              {
                ...baseEvent,
                endX: event.end_x,
                endY: event.end_y,
              },
            ],
          }
        : { markerEvents: [...markerEvents, baseEvent], arrowEvents };
    },
    {
      markerEvents: <MarkerEvent[]>[],
      arrowEvents: <ArrowEvent[]>[],
    },
  );

export const convertMarkerDataIntoVideoDescriptions = (eventMarkersData: EventMarker[] = []) =>
  eventMarkersData.map(
    event =>
      ({
        eventId: event.event_id,
        videoEventId: event.video_event_id,
        type: event.type,
        minute: event.minute,
        second: event.second,
        playerName: event.player_name,
        playerNickname: event.player_nickname,
        gameName: event.game_name,
        competitionName: event.competition_name,
        seasonName: event.season_name,
      }) as VideoDescription,
  );
