import { defaultSeasonsAtom } from '@/atoms/filters/highLevel/seasons';
import { renderedRadarDataAtom } from '@/atoms/radar';
import { METRIC_KEY } from '@/consts/searchKeys';
import { useUpdateQueryString } from '@/hooks/useUpdateQueryString';
import { LoadableRadarDataAtom, SelectedGamesAtom } from '@/types/radar';
import { snakeToCamel } from '@/utils/queries';
import { Radar } from '@statsbomb/kitbag-datavis';
import { useAtomValue, useSetAtom } from 'jotai';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { selectedVisualisationAtom, showArrowsAtom } from '@/atoms/visualisation';
import { useResetSelectionLayer } from '@/hooks/useResetSelectionLayer';
import { widgetIdAtom } from '@/atoms/dashboard/widget';
import { addSuffix } from '@/utils/string';
import { useGetEntity } from '@/hooks/useGetEntity';
import { useDeepEffect } from '@/hooks/useDeepEffect';
import { Loading } from '../translations/Generic';

const RadarChartBase = ({ isLoading, isInteractive = true }: { isLoading?: boolean; isInteractive?: boolean }) => {
  const {
    t,
    i18n: { language: locale },
  } = useTranslation('general');
  const renderedRadarData = useAtomValue(renderedRadarDataAtom);
  const widgetId = useAtomValue(widgetIdAtom);
  const { entity } = useGetEntity();

  const translatedRenderedRadarData = renderedRadarData.map(data => ({
    ...data,
    label: t(`${snakeToCamel(data.key)}.name`, { ns: 'metrics' }),
  }));
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const createQueryString = useUpdateQueryString(searchParams);

  const setSelectedVis = useSetAtom(selectedVisualisationAtom);
  const setShowArrows = useSetAtom(showArrowsAtom);
  const resetSelectionLayer = useResetSelectionLayer();

  const handleClicks = ({ interaction, slice }: { interaction?: string; slice?: number }) => {
    if (interaction !== 'click' || slice === undefined) return;

    const dataForSlice = translatedRenderedRadarData[slice];
    if (!dataForSlice) return;

    setSelectedVis(null);
    setShowArrows(false);
    resetSelectionLayer();

    navigate(`preview?${createQueryString([{ key: METRIC_KEY, value: dataForSlice.key }])}`);
  };

  return (
    <>
      <Radar
        id={addSuffix(entity, widgetId)}
        data={translatedRenderedRadarData}
        handleInteraction={isInteractive ? handleClicks : undefined}
        locale={locale}
      />
      {isLoading && <Loading />}
    </>
  );
};

// We want to know when we're loading new data for the radar so that we can render a loading message.
// But we can't use suspense in this case as that will cause us to lose the smooth animation from one
// radar state to the next (I don't really understand this to be honest, but allowing it to fallback to
// the suspense causes a full radar to be rendered)
const RadarChartWithData = ({
  loadableRadarDataAtom,
  isInteractive,
}: {
  loadableRadarDataAtom: LoadableRadarDataAtom;
  isInteractive: boolean;
}) => {
  const newRadarDataLoadable = useAtomValue(loadableRadarDataAtom);

  const newRadarData = newRadarDataLoadable.state === 'hasData' && newRadarDataLoadable.data;
  const setRenderedRadarData = useSetAtom(renderedRadarDataAtom);

  useDeepEffect(() => {
    if (newRadarData) setRenderedRadarData(newRadarData);
  }, [newRadarData]);

  return <RadarChartBase isLoading={newRadarDataLoadable.state === 'loading'} isInteractive={isInteractive} />;
};

// We want to prevent the aggregates query from being called until there are selected
// games and default seasons are populated. This component ensures that by rendering a
// placeholder component until those things are loaded
const RadarChartWithFallback = ({
  loadableSelectedGamesAtom,
  loadableRadarDataAtom,
}: {
  loadableSelectedGamesAtom: SelectedGamesAtom;
  loadableRadarDataAtom: LoadableRadarDataAtom;
}) => {
  const defaultSeasons = useAtomValue(defaultSeasonsAtom);
  const loadableSelectedGames = useAtomValue(loadableSelectedGamesAtom);

  return !defaultSeasons || loadableSelectedGames.state === 'loading' ? (
    <RadarChartBase isLoading />
  ) : (
    <RadarChartWithData loadableRadarDataAtom={loadableRadarDataAtom} isInteractive />
  );
};

export const RadarChart = ({
  loadableSelectedGamesAtom,
  loadableRadarDataAtom,
  isWidget = false,
}: {
  loadableSelectedGamesAtom: SelectedGamesAtom;
  loadableRadarDataAtom: LoadableRadarDataAtom;
  isWidget?: boolean;
}) => (
  <Suspense fallback={<RadarChartBase isLoading />}>
    {/* we don't care about waiting on the default seasons if in a widget */}
    {isWidget ? (
      <RadarChartWithData loadableRadarDataAtom={loadableRadarDataAtom} isInteractive={false} />
    ) : (
      <RadarChartWithFallback
        loadableSelectedGamesAtom={loadableSelectedGamesAtom}
        loadableRadarDataAtom={loadableRadarDataAtom}
      />
    )}
  </Suspense>
);
