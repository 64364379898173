import React, { useState, useEffect, useRef, ReactNode } from 'react';
import { Toggle, Dropdown, ConfirmationDialog, Typography } from '@statsbomb/kitbag-components';
import { useTranslation } from 'react-i18next';
import { useAtom } from 'jotai';
import { isExportModalOpenAtom } from '@/atoms/exporting';
import ExportTemplate from './ExportTemplate';
import { exportVisual } from './Export.helper';
import { DOWNLOAD_FORMAT_OPTIONS, EXPORT_TYPE, ExportType, X_PADDING } from './Export.constants';

/**
 * An export modal with customization options
 * Takes an SVG and displays it within an export template
 */
export const ExportModal = ({
  children,
  title,
  secondaryTitle = '',
  info1 = '',
  info2 = '',
  info3 = '',
  fileName,
  customWidth = 0,
}: {
  children: ReactNode;
  title: string;
  secondaryTitle?: string;
  info1?: string;
  info2?: string;
  info3?: string;
  fileName?: string;
  customWidth?: number;
}) => {
  const exportRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation(['export', 'general']);

  const [isExportModalOpen, setIsExportModalOpen] = useAtom(isExportModalOpenAtom);
  const [isExporting, setIsExporting] = useState(false);
  const [exportType, setExportType] = useState(DOWNLOAD_FORMAT_OPTIONS[0]);
  const [withLogo, setWithLogo] = useState(true);

  const exportName = fileName || t('defaultFileName');

  // exporting blocks the UI thread, so it's important to ensure that isExporting
  // is set synchronously before running the export function
  useEffect(() => {
    if (isExporting) {
      /* timeout here allows us to wait for the loading button to render as disabled
      before continuing the download else we only see the button disabled briefly before the download
      finishes */
      setTimeout(async () => {
        await exportVisual(exportRef, EXPORT_TYPE[exportType.value as ExportType], exportName);

        setIsExporting(false);
        setIsExportModalOpen(false);
      }, 50);
    }
  }, [isExporting]);

  const width = customWidth + X_PADDING;
  const widthInPx = `${width}px`;

  return (
    isExportModalOpen && (
      <ConfirmationDialog
        id="export-visualisation-modal"
        title={t('title')}
        isOpen
        cancelLabel={t('close', { ns: 'general' })}
        confirmLabel={t('export')}
        onCancel={() => setIsExportModalOpen(false)}
        onConfirm={() => setIsExporting(true)}
        isPendingConfirm={isExporting}
      >
        <div className="flex justify-center gap-2">
          <div className="max-w-full max-h-[60vh] overflow-visible">
            <ExportTemplate
              ref={exportRef}
              width={widthInPx}
              title={title}
              secondaryTitle={secondaryTitle}
              info1={info1}
              info2={info2}
              info3={info3}
              withLogo={withLogo}
            >
              {children}
            </ExportTemplate>
          </div>
          <div className="w-[30%]">
            <aside className="w-full max-w-full bg-canvas-tertiary-main border-2 border-canvas-primary-main">
              <Typography variant="headingRegular" as="header" className="p-4">
                {t('exportSettings')}
              </Typography>
              <div className="flex flex-col p-4 pt-0 gap-4">
                <Typography as="span" variant="bodyMedium">
                  {t('imageWidth', { width: `${width.toFixed()}px` })}
                </Typography>
                <Dropdown
                  id="image-type-dropdown"
                  options={DOWNLOAD_FORMAT_OPTIONS}
                  label={t('imageType')}
                  onChange={setExportType}
                  value={exportType}
                  isSearchable={false}
                />
                <Toggle
                  id="export-logo-toggle"
                  label={t('statsBombLogo', { ns: 'general' })}
                  checked={withLogo}
                  onChange={() => setWithLogo(!withLogo)}
                />
              </div>
            </aside>
          </div>
        </div>
      </ConfirmationDialog>
    )
  );
};
