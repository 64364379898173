import { teamIdAtom } from '@/atoms/team/team';
import { LoadingState } from '@/components/contentState/LoadingState';
import { useSetMetricKey } from '@/hooks/useMetricKey';
import { useAtomValue } from 'jotai';
import { Outlet } from 'react-router-dom';
import { useSetTeamId } from '@/hooks/useTeamId';
import { useUnsetPlayerId } from '@/hooks/usePlayerId';
import { TeamNav } from '@/components/navigation/TeamNav';
import { ScopeProvider } from 'jotai-scope';
import { selectedGameRangeAtom, deselectedGamesAtom } from '@/atoms/filters/highLevel';
import { selectedSeasonIdsAtom } from '@/atoms/filters/highLevel/seasons';
import { renderedRadarDataAtom, selectedRadarTemplateIdAtom } from '@/atoms/radar';
import {
  isStartPlacementAtom,
  pitchCropAtom,
  pitchOrientationDashboardWidgetAtom,
  selectedVisualisationAtom,
  showArrowsAtom,
} from '@/atoms/visualisation';
import { EntityTopBar } from '@/components/topBar/EntityTopBar';
import { selectedTeamNameAtom } from '@/atoms/team/info';
import { selectedGameTypeAtom } from '@/atoms/filters/gameMetrics';
import { useAutoResetSelectionLayer } from '@/hooks/useAutoResetSelectionLayer';
import { teamEventMetricFiltersAtom } from '@/atoms/queries/team/teamEventData';
import { useAutoResetPagination } from '@/hooks/useAutoResetPagination';
import { selectedCompetitionIdsAtom } from '@/atoms/filters/highLevel/competitions';
import { selectedVideoIdsBaseAtom } from '@/atoms/video';
import { TableScopeProvider } from '@/components/tables/TableScopeProvider';
import { selectedFilterSetIdAtom } from '@/atoms/filters/userFilters';
import { AppShell } from '../AppShell';
import { SectionWrapper } from '../SectionWrapper';

const TeamLayoutInner = () => {
  const teamEventMetricFilters = useAtomValue(teamEventMetricFiltersAtom);
  useAutoResetSelectionLayer(teamEventMetricFilters);
  useAutoResetPagination(teamEventMetricFilters);

  return (
    <SectionWrapper section="team">
      <Outlet />
    </SectionWrapper>
  );
};

export const TeamLayout = () => {
  useUnsetPlayerId();
  useSetTeamId();
  useSetMetricKey();

  const teamId = useAtomValue(teamIdAtom);

  return (
    <ScopeProvider
      atoms={[
        selectedCompetitionIdsAtom,
        selectedSeasonIdsAtom,
        selectedGameRangeAtom,
        selectedGameTypeAtom,
        deselectedGamesAtom,
        renderedRadarDataAtom,
        selectedVisualisationAtom,
        showArrowsAtom,
        pitchCropAtom,
        pitchOrientationDashboardWidgetAtom,
        selectedRadarTemplateIdAtom,
        selectedVideoIdsBaseAtom,
        selectedFilterSetIdAtom,
        isStartPlacementAtom,
      ]}
    >
      <AppShell topBar={<EntityTopBar selectedEntityNameAtom={selectedTeamNameAtom} />} subnav={<TeamNav />}>
        <TableScopeProvider>{teamId ? <TeamLayoutInner /> : <LoadingState />}</TableScopeProvider>
      </AppShell>
    </ScopeProvider>
  );
};
