import { competitionsToQueryAtom } from '@/atoms/filters/highLevel/competitions';
import { defaultSeasonsAtom } from '@/atoms/filters/highLevel/seasons';
import { playerAggsFilterParamsAtom, playerAggsFilterParamsDebounceObject } from '@/atoms/queries/player/playerAggData';
import { DEFAULT_DEBOUNCE_MS } from '@/consts/api';
import { useAtomValue, useSetAtom } from 'jotai';
import { useDeepEffect } from '../useDeepEffect';

export const useSetPlayerAggsDebouncedFilterParams = () => {
  const defaultSeasons = useAtomValue(defaultSeasonsAtom);
  const competitionsToQuery = useAtomValue(competitionsToQueryAtom);
  const playerAggsFilterParams = useAtomValue(playerAggsFilterParamsAtom);
  const {
    debouncedValueAtom: playerAggsFilterParamsDebounceAtom,
    timeoutDelayAtom: playerAggsFilterParamsTimeoutDelayAtom,
  } = playerAggsFilterParamsDebounceObject;
  const setPlayerAggsFilterParamsDebounce = useSetAtom(playerAggsFilterParamsDebounceAtom);
  const setPlayerAggsFilterParamsTimeoutDelay = useSetAtom(playerAggsFilterParamsTimeoutDelayAtom);

  useDeepEffect(() => {
    if (!defaultSeasons || competitionsToQuery.length === 0) return;
    setPlayerAggsFilterParamsDebounce(playerAggsFilterParams);
    // Ensure that the timeout delay is set (we initialise this to 0 so that we don't debounce
    // on initial page load)
    setPlayerAggsFilterParamsTimeoutDelay(DEFAULT_DEBOUNCE_MS);
  }, [defaultSeasons, competitionsToQuery, playerAggsFilterParams]);
};
