import { RadarHeader } from '@/components/radar/RadarHeader';
import RadarTemplateDropdown from '@/components/radar/RadarTemplateDropdown';
import { RadarTemplate } from '@/types/radar';
import { useTranslation } from 'react-i18next';
import { useExportTitle } from '@/hooks/exporting/useExportTitle';
import { useAtomValue } from 'jotai';
import { unwrappedSelectedVisualisationAtom } from '@/atoms/visualisation';
import { formatFileName } from '@/utils/array';
import { useExportVisInfo } from '@/hooks/exporting/useExportVisInfo';
import { selectedRadarTemplateKeyAtom } from '@/atoms/radar';
import { ExportModal } from '../export/ExportModal';

export const RadarCard = ({
  templates,
  label,
  children,
}: {
  templates: RadarTemplate[];
  label: string;
  children: React.ReactNode;
}) => {
  const { t } = useTranslation(['radar', 'visualisation']);
  const selectedVis = useAtomValue(unwrappedSelectedVisualisationAtom);
  const selectedRadarTemplateKey = useAtomValue(selectedRadarTemplateKeyAtom);

  const title = useExportTitle();
  const secondaryTitle = selectedRadarTemplateKey ? t(`templates.${selectedRadarTemplateKey}`) : '';
  const { info1, info2, info3 } = useExportVisInfo();
  const selectedVisName = selectedVis?.visualisationKey && t(selectedVis.visualisationKey, { ns: 'visualisation' });
  const fileName = formatFileName([title, secondaryTitle, selectedVisName]);

  return (
    <>
      <RadarHeader>
        <RadarTemplateDropdown templates={templates} label={label} />
      </RadarHeader>
      <ExportModal
        title={title}
        secondaryTitle={secondaryTitle}
        info1={info1}
        info2={info2}
        info3={info3}
        fileName={fileName}
        customWidth={600}
      >
        <div className="mx-auto w-[500px]">{children}</div>
      </ExportModal>
      <div className="mx-auto max-w-[550px]">{children}</div>
    </>
  );
};
