import { teamSelectedGamesAtom } from '@/atoms/filters/team/teamGames';
import { sectionAtom } from '@/atoms/general';
import { playerAggsMinutesPlayedAtom } from '@/atoms/queries/player/playerAggData';
import { useDecimalPlaces } from '@/hooks/useDecimalPlaces';
import { Typography } from '@statsbomb/kitbag-components';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

const RadarLabelBase = ({ radarLabel }: { radarLabel: number }) => {
  const { t } = useTranslation('radar');
  const location = useLocation();
  const formatNumberDecimals = useDecimalPlaces();
  const section = useAtomValue(sectionAtom);
  const radar90s = formatNumberDecimals(1, radarLabel / 90);

  if (!radarLabel || location.pathname.includes('dashboard')) return null;

  return (
    <Typography variant="bodyRegular" as="legend" className="text-center mt-6">
      {section === 'player' ? t('legend.player', { value: radar90s }) : t('legend.team', { value: radarLabel })}
    </Typography>
  );
};

export const TeamRadarLabel = () => {
  const radarLabel = useAtomValue(teamSelectedGamesAtom);
  return <RadarLabelBase radarLabel={radarLabel.length} />;
};

export const PlayerRadarLabel = () => {
  const radarLabel = useAtomValue(playerAggsMinutesPlayedAtom);
  return <RadarLabelBase radarLabel={radarLabel} />;
};
