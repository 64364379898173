import { patchUserConfigAtom } from '@/atoms/mutations/userConfigs/userConfigs';
import { userConfigsAtom } from '@/atoms/queries/userConfigs';
import { UserConfigPatchMutationParams } from '@/types/userConfigs';
import { useAtomValue } from 'jotai';

export const usePatchUserConfig = () => {
  const userConfigs = useAtomValue(userConfigsAtom);
  const { mutateAsync: patchUserConfig } = useAtomValue(patchUserConfigAtom);

  const handleUpsertUserConfig = async (mutationParams: UserConfigPatchMutationParams) => {
    const { isSuccess } = await patchUserConfig(mutationParams);
    if (isSuccess) {
      await userConfigs.refetch();
    }
    return { isSuccess };
  };

  return handleUpsertUserConfig;
};
