import React, { useState } from 'react';
import { useAtomValue, useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, ButtonIcon, Icon, Typography } from '@statsbomb/kitbag-components';
import { widgetIdFiltersToDisplayAtom } from '@/atoms/dashboard/widget';
import { unwrappedSelectedTeamInfoAtom } from '@/atoms/queries/teams';
import { WidgetSettingsMenu } from './WidgetSettingsMenu';
import { GenderIcon } from '../icons/GenderIcon';

interface WidgetProps {
  children: React.ReactNode;
  title: string;
  description: string;
  navigationPath: string;
  onClickEdit: () => void;
  onClickDelete: () => void;
  onClickTitle: () => void;
  editMode?: boolean;
  filtersCount?: number;
  id: string;
}

export const Widget = ({
  id,
  title,
  children,
  description,
  navigationPath,
  onClickEdit,
  onClickDelete,
  onClickTitle,
  editMode = false,
  filtersCount = 0,
}: WidgetProps) => {
  const { t } = useTranslation(['widget', 'general', 'gender']);
  const [isOpen, setIsOpen] = useState(false);
  const setWidgetIdFilters = useSetAtom(widgetIdFiltersToDisplayAtom);
  const label = t('settings');

  const team = useAtomValue(unwrappedSelectedTeamInfoAtom);

  return (
    <article className="h-[500px] flex flex-col border border-canvas-secondary-main bg-canvas-tertiary-main">
      <header className="p-4 border-b border-canvas-secondary-main">
        <div className="flex items-center justify-between gap-2">
          <Link
            to={navigationPath}
            onClick={onClickTitle}
            className="flex flex-1 gap-1 overflow-hidden hover:underline"
          >
            <Typography variant="headingRegular" as="h3" className="truncate" title={title}>
              {title}
            </Typography>
            {team && <GenderIcon gender={team.gender} />}
          </Link>
          <div className="flex items-center gap-2">
            {filtersCount > 0 && (
              <Button onClick={() => setWidgetIdFilters(id)} size="small" variant="ghost">
                <Icon variant="Filter" size="small" />
                <Typography variant="headingSmall" as="span">{`${filtersCount} ${t(
                  `filters.${filtersCount > 1 ? 'other' : 'one'}`,
                  {
                    ns: 'general',
                  },
                )}`}</Typography>
              </Button>
            )}
            {editMode && (
              <span className="relative">
                <ButtonIcon
                  size="small"
                  ariaLabel={label}
                  icon="More"
                  onClick={() => setIsOpen(true)}
                  title={label}
                  variant="ghost"
                />
                {isOpen && (
                  <WidgetSettingsMenu
                    onClickDelete={onClickDelete}
                    onClickEdit={onClickEdit}
                    onOutsideClick={() => setIsOpen(false)}
                  />
                )}
              </span>
            )}
          </div>
        </div>
        <Typography variant="bodyMedium" as="p" className="opacity-sb-secondary truncate" title={description}>
          {description}
        </Typography>
      </header>
      <div className="p-4 flex-1 min-h-0">{children}</div>
    </article>
  );
};
