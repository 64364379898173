import { PLAYER_RADAR_TEMPLATES } from '@/consts/radar';
import { useTranslation } from 'react-i18next';
import { RadarCard } from '@/components/radar/RadarCard';
import { Typography } from '@statsbomb/kitbag-components';
import { ColumnTopBar } from '@/components/columnTopBar/ColumnTopBar';
import { FiftyFiftyColumnLayout } from '@/components/layout/FiftyFiftyColumnLayout';
import { PlayerRadarChart } from './PlayerRadarChart';
import { PlayerRadarTableGroup } from './PlayerRadarTableGroup';

export const PlayerRadar = () => {
  const { t } = useTranslation(['radar', 'entity', 'visualisation']);
  const label = t('dropdownLabel', { entity: t('player.one', { ns: 'entity' }) });

  return (
    <FiftyFiftyColumnLayout>
      <RadarCard templates={PLAYER_RADAR_TEMPLATES} label={label}>
        <PlayerRadarChart />
      </RadarCard>
      <>
        <ColumnTopBar>
          <Typography variant="headingRegular">{t(`viewing.table`, { ns: 'visualisation' })}</Typography>
        </ColumnTopBar>
        <PlayerRadarTableGroup />
      </>
    </FiftyFiftyColumnLayout>
  );
};
