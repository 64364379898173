import { atomWithQuery, atomWithSuspenseQuery } from 'jotai-tanstack-query';
import { EventWithRels, Team, User } from '@statsbomb/parachute-types';
import { atom } from 'jotai';
import { settingsConfigAtom } from '@/atoms/queries/userConfigs';
import { unwrap } from 'jotai/utils';
import { fetchClientAtom } from './client';

export const userAtom = atomWithSuspenseQuery(get => {
  const queryKey = ['user'] as const;
  const queryFn = async () => {
    const { fetch } = get(fetchClientAtom);
    return (await fetch(`/user`)) as Promise<User>;
  };
  return { queryKey, queryFn };
});

export const userDefaultTeamIdAtom = atom(async get => {
  const individualDefaultTeam = await get(settingsConfigAtom);
  if (individualDefaultTeam) return individualDefaultTeam.definition.teamId;
  return (await get(userAtom)).data.team_id;
});

const unwrappedUserDefaultTeamIdAtom = unwrap(userDefaultTeamIdAtom, prev => prev || undefined);

export const defaultUserTeamAndPlayerIdsAtom = atomWithQuery(get => {
  const queryKey = ['defaultTeamAndPlayerIds', get(unwrappedUserDefaultTeamIdAtom)] as const;
  const queryFn = async ({ queryKey: [_, teamId] }: { queryKey: typeof queryKey }) => {
    const { fetch } = get(fetchClientAtom);

    const [playerData] = (await fetch(`/team/${teamId}/players?limit=1`)) as EventWithRels[];
    const playerId = playerData.player.player_id;

    return { teamId, playerId };
  };
  const enabled = get(unwrappedUserDefaultTeamIdAtom) !== undefined;
  return { queryKey, queryFn, enabled };
});

const rawDefaultTeamInfoAtom = atomWithSuspenseQuery(get => {
  const { fetch } = get(fetchClientAtom);
  const queryKey = ['defaultTeamInfo', get(userDefaultTeamIdAtom)] as const;

  const queryFn = async ({ queryKey: [_, defaultTeamId] }: { queryKey: typeof queryKey }) => {
    const teamId = await defaultTeamId;

    return (await fetch(`/teams?eq[team_id]=${teamId}`)) as Promise<Team[]>;
  };

  return { queryKey, queryFn };
});

export const defaultTeamInfoAtom = atom(async get => (await get(rawDefaultTeamInfoAtom)).data[0]);
