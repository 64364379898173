import { DISPLAY_MODE } from '@/consts/searchKeys';
import { VisDisplayMode } from '@/types/visualisation';
import { useSearchParams } from 'react-router-dom';

export const useVisDisplayMode = (defaultDisplayMode: VisDisplayMode = VisDisplayMode.BOTH) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const visDisplayMode = (searchParams.get(DISPLAY_MODE) as VisDisplayMode) || defaultDisplayMode;

  const setVisDisplayMode = (mode: VisDisplayMode) => {
    searchParams.set(DISPLAY_MODE, mode);
    setSearchParams(searchParams);
  };

  return [visDisplayMode, setVisDisplayMode] as const;
};
