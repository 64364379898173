import { ButtonWithMenu, MenuItem, MenuItemText, Icon } from '@statsbomb/kitbag-components';
import { useAtom, useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { isExportModalOpenAtom } from '@/atoms/exporting';
import { isVisOptionsMenuOpenAtom } from '@/atoms/visualisation';
import { SaveToDashboardMenuItemProps } from '@/types/widget';
import { useShouldShowHiddenFeature } from '@/hooks/useShouldShowHiddenFeature';
import { SaveToDashboardMenuItem as SaveToDashboardMenuItemOld } from '../widget/SaveToDashboardMenuItemOLD';
import { SaveToDashboardMenuItem } from '../widget/SaveToDashboardMenuItem';
import { SaveWidgetToDashboardModal } from '../modal/SaveWidgetToDashboardModal/SaveWidgetToDashboardModal';

export const VisualisationMenu = ({ visType, dataType }: SaveToDashboardMenuItemProps) => {
  const { t } = useTranslation(['general', 'export', 'widget']);
  const [isVisOptionsMenuOpen, setIsVisOptionsMenuOpen] = useAtom(isVisOptionsMenuOpenAtom);
  const setIsExportModalOpen = useSetAtom(isExportModalOpenAtom);

  const shouldShowFeature = useShouldShowHiddenFeature();
  return (
    <>
      <ButtonWithMenu
        placement="bottom-end"
        isOpen={isVisOptionsMenuOpen}
        onClick={() => setIsVisOptionsMenuOpen(true)}
        onOutsideClick={() => setIsVisOptionsMenuOpen(false)}
        variant="ghost"
        icon="More"
        title={t('optionsMenu')}
      >
        {shouldShowFeature ? (
          <SaveToDashboardMenuItem {...{ visType, dataType }} />
        ) : (
          /* istanbul ignore next */ <SaveToDashboardMenuItemOld {...{ visType, dataType }} />
        )}
        <MenuItem
          onClick={() => {
            setIsVisOptionsMenuOpen(false);
            setIsExportModalOpen(true);
          }}
        >
          <div className="flex gap-x-2 items-center">
            <Icon variant="Download" size="small" />
            <MenuItemText>{t('export', { ns: 'export' })}</MenuItemText>
          </div>
        </MenuItem>
      </ButtonWithMenu>
      <SaveWidgetToDashboardModal />
    </>
  );
};
