import { useAtomValue, useSetAtom } from 'jotai';
import { widgetIdToEditAtom } from '@/atoms/dashboard/widget';
import { WidgetVis } from '@/types/widget';
import {
  dashboardEditModeAtom,
  dashboardToSaveAtom,
  unwrappedDashboardWidgetsToRenderAtom,
} from '@/atoms/dashboard/dashboardSaving';
import { entityVisualisationComponents } from '@/consts/widget';
import { useWidgetNavigationPath } from '@/hooks/useWidgetNavigationPath';
import { getWidgetAtomsToHydrate } from '@/utils/atoms/atomHydration';
import { atomsToHydrateAtom } from '@/atoms/hydration';
import { getFilterCount } from '@/utils/filters';
import { useShouldShowHiddenFeature } from '@/hooks/useShouldShowHiddenFeature';
import { usePatchUserConfig } from '@/hooks/usePatchUserConfig';
import { useParams } from 'react-router-dom';
import { Suspense } from 'react';
import { Widget } from './Widget';
import { WidgetScopeProvider } from './WidgetScopeProvider';
import { WidgetAtomHydration } from './WidgetAtomHydration';

const WidgetWrapperWithData = ({
  filters,
  displaySettings,
  entity,
  entityId,
  vis,
  widgetId,
  title,
  description,
}: WidgetVis) => {
  const dashboardEditMode = useAtomValue(dashboardEditModeAtom);
  const dashboardWidgets = useAtomValue(unwrappedDashboardWidgetsToRenderAtom);
  const setDashboardToSave = useSetAtom(dashboardToSaveAtom);
  const setWidgetIdToEdit = useSetAtom(widgetIdToEditAtom);
  const setAtomsToHydrate = useSetAtom(atomsToHydrateAtom);
  const navigationPath = useWidgetNavigationPath(entity, entityId, vis, filters.metric);
  const { dashboardId } = useParams();
  const shouldShowFeature = useShouldShowHiddenFeature();
  const patchUserConfig = usePatchUserConfig();

  const Component = entityVisualisationComponents[entity]?.[vis]!;

  const deleteWidget = async () => {
    const updatedDashboard = dashboardWidgets.filter(widget => widget.widgetId !== widgetId);

    /* istanbul ignore else */
    if (shouldShowFeature && dashboardId) {
      await patchUserConfig({ configId: dashboardId, configDefinition: { widgets: updatedDashboard } });
    } else {
      setDashboardToSave(updatedDashboard);
    }
  };

  return (
    <WidgetScopeProvider>
      <WidgetAtomHydration {...{ entity, entityId, widgetId, filters, displaySettings }}>
        <Widget
          id={widgetId}
          title={title}
          description={description}
          navigationPath={navigationPath}
          onClickTitle={() =>
            setAtomsToHydrate(getWidgetAtomsToHydrate({ widgetId, entity, entityId, filters, displaySettings }))
          }
          onClickEdit={() => setWidgetIdToEdit(widgetId)}
          onClickDelete={deleteWidget}
          editMode={shouldShowFeature ? true : /* istanbul ignore next */ dashboardEditMode}
          filtersCount={getFilterCount(filters)}
        >
          <Component isWidget />
        </Widget>
      </WidgetAtomHydration>
    </WidgetScopeProvider>
  );
};

export const WidgetWrapper = (props: WidgetVis) => (
  <Suspense>
    <WidgetWrapperWithData {...props} />
  </Suspense>
);
