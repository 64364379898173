import { Menu, MenuItem, MenuItemText, Icon } from '@statsbomb/kitbag-components';
import { useTranslation } from 'react-i18next';

interface GameMenuProps {
  isOpen: boolean;
  onOutsideClick: () => void;
  onClickMatchGoals: () => void;
  onClickSetPieces: () => void;
}

export const GameMenu = ({ isOpen, onOutsideClick, onClickMatchGoals, onClickSetPieces }: GameMenuProps) => {
  const { t } = useTranslation('games');

  return (
    isOpen && (
      <Menu testId="game-menu" onOutsideClick={onOutsideClick}>
        <MenuItem onClick={onClickMatchGoals}>
          <div className="flex items-center gap-0.5">
            <Icon variant="Play" size="small" />
            <MenuItemText>{t('gameGoals')}</MenuItemText>
          </div>
        </MenuItem>
        <MenuItem onClick={onClickSetPieces}>
          <div className="flex items-center gap-0.5">
            <Icon variant="Play" size="small" />
            <MenuItemText>{t('playerActions')}</MenuItemText>
          </div>
        </MenuItem>
      </Menu>
    )
  );
};
