import dayjs from 'dayjs';
import { isLinkObject } from '@/types/generic';
import { Icon, Typography } from '@statsbomb/kitbag-components';
import { NestedObject } from '@/types/object';
import { Link } from 'react-router-dom';
import { ReactNode } from 'react';
import { DATE_FORMAT } from '@/consts/date';
import { useDecimalPlaces } from '@/hooks/useDecimalPlaces';
import { alignTableCell } from '@/utils/table';
import { TableLink } from './TableLink';
import { UuidButton } from '../button/UuidButton';
import { TableCell } from './TableCell';

export const CellValueToDisplay = ({
  value,
  columnRule,
  eventKey,
  previewLinkParams,
  children,
}: {
  value: NestedObject[string];
  columnRule: string | undefined;
  eventKey: string;
  previewLinkParams?: string;
  children?: ReactNode;
}) => {
  const cellAlignment = alignTableCell(columnRule);
  const formatNumberDecimals = useDecimalPlaces();
  const wholeNumberFormat = (value: number) => formatNumberDecimals(0, value);
  const twoDecimalsNumberFormat = (value: number) => formatNumberDecimals(2, value);
  let cellValue = null;

  switch (columnRule) {
    case 'string':
    case 'string-translate':
      if (value) cellValue = value;
      break;
    case 'integer':
      if (typeof value === 'number') cellValue = wholeNumberFormat(value);
      break;
    case 'percentage':
      if (typeof value === 'number') cellValue = wholeNumberFormat(value * 100);
      break;
    case 'float-2':
      if (typeof value === 'number') cellValue = twoDecimalsNumberFormat(value);
      break;
    case 'timestamptz':
      if (dayjs(String(value)).isValid()) cellValue = dayjs(String(value)).format(`${DATE_FORMAT} HH:mm:ss`);
      break;
    case 'duration-m':
    case 'duration-s':
      if (dayjs(Number(value)).isValid()) cellValue = dayjs(Number(value)).format('mm:ss:SSS');
      break;
    case 'boolean':
      if (value)
        cellValue = (
          <span className="flex justify-center" data-testid="formatted-boolean-icon">
            <Icon variant="TickDone" size="small" />
          </span>
        );
      break;
    case 'object':
    case 'array':
    case 'UUID':
      cellValue = <UuidButton />;
      break;
    case 'link':
      if (isLinkObject(value)) {
        const { id, label } = value;
        cellValue = <TableLink {...{ id, label, type: eventKey }} />;
      }
      break;
    default:
      cellValue = value?.toString();
      break;
  }

  const cellValueToRender =
    previewLinkParams && cellValue !== null ? (
      <Typography
        as={Link}
        variant="linkRegular"
        // TODO (KC-344: Remove text size override once supported in KC)
        // TODO (KC-202: Highlighted columns can't be clicked - remove the z-index below once this has been resolved)
        className="!text-xs relative z-10 underline-offset-4"
        to={`./preview?${previewLinkParams}`}
      >
        {cellValue}
      </Typography>
    ) : (
      (cellValue as string | JSX.Element)
    );

  const returnValue = children ? (
    <span className="inline-flex w-max items-center gap-1">
      {children}
      {cellValueToRender}
    </span>
  ) : (
    cellValueToRender
  );

  return <TableCell textAlign={cellAlignment}>{returnValue}</TableCell>;
};
