import { playerEventsForMetricAtom } from '@/atoms/queries/player/playerEventData';
import { useAtomValue } from 'jotai';
import { Suspense } from 'react';
import { tableColumnsAtom } from '@/atoms/queries/tableColumns';
import { PreviewEventsBase } from '@/components/tables/PreviewEvents';

const PlayerPreviewEventsWithData = () => {
  const eventsForMetric = useAtomValue(playerEventsForMetricAtom);
  const eventDataTableColumns = useAtomValue(tableColumnsAtom);

  return <PreviewEventsBase eventsForMetric={eventsForMetric} eventDataTableColumns={eventDataTableColumns} />;
};

export const PlayerPreviewEvents = () => (
  <Suspense fallback={<PreviewEventsBase isLoading />}>
    <PlayerPreviewEventsWithData />
  </Suspense>
);
