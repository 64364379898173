import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Icon, Typography } from '@statsbomb/kitbag-components';
import { useAtomValue } from 'jotai';
import { dashboardWidgetsToRenderAtom } from '@/atoms/dashboard/dashboardSaving';
import { WidgetWrapper } from '@/components/widget/WidgetWrapper';
import { LoadingState } from '@/components/contentState/LoadingState';
import { loadable } from 'jotai/utils';
import { GeneralErrorBoundaryFallback } from '@/components/errorBoundary/ErrorBoundaryFallback';

export const DashboardWidgets = () => {
  const loadableDashboardToDisplay = useAtomValue(loadable(dashboardWidgetsToRenderAtom));

  const { t } = useTranslation('dashboard');

  if (loadableDashboardToDisplay.state === 'loading') return <LoadingState />;
  if (loadableDashboardToDisplay.state === 'hasError') return <GeneralErrorBoundaryFallback />;
  const dashboardToDisplay = loadableDashboardToDisplay.data;
  return dashboardToDisplay.length ? (
    <div className="pb-2">
      <Grid page>
        {dashboardToDisplay.map(item => (
          <Grid item sm={12} md={6} xl={4} key={item.widgetId}>
            <WidgetWrapper {...item} />
          </Grid>
        ))}
      </Grid>
    </div>
  ) : (
    <div className="flex flex-1 justify-center items-center">
      <div className="flex flex-col items-center">
        <Icon variant="Info" />
        <Typography variant="headingLarge">{t('emptyDashboard.title', { ns: 'dashboard' })}</Typography>
        <Typography variant="bodyRegular">{t('emptyDashboard.description', { ns: 'dashboard' })}</Typography>
      </div>
    </div>
  );
};
