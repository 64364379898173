import { ReactNode, Suspense } from 'react';
import { useAtomValue } from 'jotai';
import { PromiseAtom } from '@/types/atom';
import { useGetEntity } from '@/hooks/useGetEntity';
import { selectedTeamInfoAtom } from '@/atoms/queries/teams';
import { TopBar } from './TopBar';
import { TopBarHeading } from './TopBarHeading';
import { GenderIcon } from '../icons/GenderIcon';

const TeamTopBarHeader = ({ teamName }: { teamName?: string }) => {
  const selectedTeamInfo = useAtomValue(selectedTeamInfoAtom);
  const gender = selectedTeamInfo ? selectedTeamInfo.gender : '';

  return (
    <span className="flex items-center gap-1">
      {teamName}
      <GenderIcon gender={gender} />
    </span>
  );
};

export const EntityTopBarBase = ({ children, entityName = '' }: { children?: ReactNode; entityName?: ReactNode }) => (
  <TopBar>
    <TopBarHeading>{entityName}</TopBarHeading>
    {children}
  </TopBar>
);

const EntityTopBarWithData = ({
  selectedEntityNameAtom,
}: {
  selectedEntityNameAtom: PromiseAtom<string | undefined>;
}) => {
  const { entity } = useGetEntity();
  const entityName = useAtomValue(selectedEntityNameAtom);
  const header = entity === 'team' ? <TeamTopBarHeader teamName={entityName} /> : entityName;

  return <EntityTopBarBase entityName={header} />;
};

export const EntityTopBar = ({
  selectedEntityNameAtom,
}: {
  selectedEntityNameAtom: PromiseAtom<string | undefined>;
}) => (
  <Suspense fallback={<EntityTopBarBase />}>
    <EntityTopBarWithData selectedEntityNameAtom={selectedEntityNameAtom} />
  </Suspense>
);
