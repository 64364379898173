import React from 'react';
import { Icon, Menu, MenuItem, MenuItemText } from '@statsbomb/kitbag-components';
import { useTranslation } from 'react-i18next';

interface WidgetSettingsMenuProps {
  onClickEdit: () => void;
  onClickDelete: () => void;
  onOutsideClick: () => void;
}

export const WidgetSettingsMenu = ({ onClickEdit, onClickDelete, onOutsideClick }: WidgetSettingsMenuProps) => {
  const { t } = useTranslation('general');

  return (
    <span className="absolute top-7 right-0">
      <Menu onOutsideClick={onOutsideClick}>
        <MenuItem onClick={onClickEdit}>
          <div className="flex items-center gap-1">
            <Icon variant="Edit" size="small" />
            <MenuItemText>{t('edit')}</MenuItemText>
          </div>
        </MenuItem>
        <MenuItem onClick={onClickDelete}>
          <div className="flex items-center gap-0.5">
            <Icon variant="Delete" size="small" />
            <MenuItemText>{t('delete')}</MenuItemText>
          </div>
        </MenuItem>
      </Menu>
    </span>
  );
};
