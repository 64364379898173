import {
  dashboardIdToDeleteAtom,
  dashboardNameAtom,
  isRenameDashboardModalOpenAtom,
} from '@/atoms/dashboard/dashboard';
import { dashboardConfigsAtom } from '@/atoms/queries/userConfigs';
import { UserConfigMenu } from '@/components/menu/UserConfigMenu/UserConfigMenu';
import { TopBar } from '@/components/topBar/TopBar';
import { TopBarHeading } from '@/components/topBar/TopBarHeading';
import { UserConfigDashboardDefinition } from '@/types/userConfigs';
import { ButtonIcon } from '@statsbomb/kitbag-components';
import { useAtomValue, useSetAtom } from 'jotai';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

const DashboardTopBarNewBase = ({ dashboards }: { dashboards: UserConfigDashboardDefinition[] }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation(['navigation', 'dashboard', 'general']);
  const navigate = useNavigate();
  const { dashboardId } = useParams();

  const selectedDashboardName = dashboards.find(({ configId }) => configId === dashboardId)?.definition.name;

  const setDashboardName = useSetAtom(dashboardNameAtom);
  const setIsRenamingModalOpen = useSetAtom(isRenameDashboardModalOpenAtom);
  const setDashboardIdToDeleteAtom = useSetAtom(dashboardIdToDeleteAtom);

  return (
    <TopBar>
      <ButtonIcon
        onClick={() => navigate(`/${language}/dashboards`)}
        size="small"
        icon="ChevronLeft"
        displayText="left"
        variant="ghost"
        /* TODO (PPC-1385: Enable all dashboards button) */
        disabled
      >
        {t('dashboardsTitle', { ns: 'dashboard' })}
      </ButtonIcon>
      <TopBarHeading>{selectedDashboardName || ''}</TopBarHeading>
      {dashboardId && (
        <UserConfigMenu
          configId={dashboardId}
          onRenameClick={(dashboardName: string) => {
            setDashboardName(dashboardName);
            setIsRenamingModalOpen(true);
          }}
          onDeleteClick={(id: string, name: string) => {
            setDashboardIdToDeleteAtom(id);
            setDashboardName(name);
          }}
        />
      )}
    </TopBar>
  );
};

const DashboardTopBarNewWithData = () => {
  const dashboards = useAtomValue(dashboardConfigsAtom);
  return <DashboardTopBarNewBase dashboards={dashboards} />;
};

export const DashboardTopBarNew = () => (
  <Suspense fallback={<DashboardTopBarNewBase dashboards={[]} />}>
    <DashboardTopBarNewWithData />
  </Suspense>
);
