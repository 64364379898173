import React from 'react';
import { useTranslation } from 'react-i18next';
import { MenuItem, MenuItemText, Icon } from '@statsbomb/kitbag-components';
import { SaveToDashboardMenuItemProps } from '@/types/widget';
import { useSetAtom } from 'jotai';
import { isVisOptionsMenuOpenAtom } from '@/atoms/visualisation';
import { saveWidgetToDashboardDataAtom, isSaveWidgetToDashboardModalOpenAtom } from '@/atoms/dashboard/dashboard';

export const SaveToDashboardMenuItem = ({ visType, dataType }: SaveToDashboardMenuItemProps) => {
  const { t } = useTranslation('widget');

  const setIsVisOptionsMenuOpen = useSetAtom(isVisOptionsMenuOpenAtom);
  const setIsSaveWidgetToDashboardModalOpen = useSetAtom(isSaveWidgetToDashboardModalOpenAtom);
  const setSaveWidgetToDashboardData = useSetAtom(saveWidgetToDashboardDataAtom);

  return (
    <MenuItem
      onClick={() => {
        setIsVisOptionsMenuOpen(false);
        setSaveWidgetToDashboardData({
          visType,
          dataType,
        });
        setIsSaveWidgetToDashboardModalOpen(true);
      }}
    >
      <div data-testid="save-to-dashboard-menu-item" className="flex gap-x-2 items-center">
        <Icon variant="PlusAdd" size="small" />
        <MenuItemText>{t('saveToDashboard', { ns: 'widget' })}</MenuItemText>
      </div>
    </MenuItem>
  );
};
