import { saveWidgetToDashboardDataAtom, isSaveWidgetToDashboardModalOpenAtom } from '@/atoms/dashboard/dashboard';
import { unwrappedDashboardConfigsAtom } from '@/atoms/queries/userConfigs';
import { closeToastAtom, toastDataAtom } from '@/atoms/toast';
import { TableCell } from '@/components/tables/TableCell';
import { TableWrapper } from '@/components/tables/TableWrapper';
import { useSaveItemToDashboard } from '@/hooks/useSaveItemToDashboard';
import { UserConfigDashboardDefinition } from '@/types/userConfigs';
import { toggleValue } from '@/utils/array';
import { Button, Checkbox, ConfirmationDialog, Table, Typography } from '@statsbomb/kitbag-components';
import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai';
import { RESET } from 'jotai/utils';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const selectedDashboardIdsAtom = atom<string[]>([]);

const DashboardTableBase = ({ dashboards }: { dashboards: UserConfigDashboardDefinition[] }) => {
  const [selectedDashboardIds, setSelectedDashboardIds] = useAtom(selectedDashboardIdsAtom);
  const { t } = useTranslation('dashboard');

  return (
    <TableWrapper>
      <div className="overflow-x-auto">
        {/* TODO (KC-350: Table variant with non alternating row colours) */}
        <Table withBorder={false}>
          <Table.Head>
            <Table.Row>
              <Table.HeaderCell size="regular">{t('dashboardsCount', { count: dashboards.length })}</Table.HeaderCell>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {dashboards?.map(dashboard => {
              const id = dashboard.configId;
              const isChecked = selectedDashboardIds.includes(id);
              const dashboardName = dashboard.definition.name || t('untitled', { ns: 'general' });

              return (
                <Table.Row key={id} data-testid={`dashboard-row-${id}`}>
                  <TableCell>
                    <div className="flex items-center gap-x-2">
                      <Checkbox
                        id={`dashboard-row-${id}-checkbox`}
                        size="small"
                        checked={isChecked}
                        onChange={() => setSelectedDashboardIds(toggleValue(id, selectedDashboardIds))}
                        label={`${dashboardName}-checkbox`}
                        withLabel={false}
                      />
                      <Typography variant="bodyRegular" as="span">
                        {dashboardName}
                      </Typography>
                    </div>
                  </TableCell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </div>
    </TableWrapper>
  );
};

const DashboardTableWithData = () => {
  const dashboards = useAtomValue(unwrappedDashboardConfigsAtom);

  return <DashboardTableBase dashboards={dashboards} />;
};

const SaveWidgetToDashboardModalWithData = () => {
  const [selectedDashboardIds, setSelectedDashboardIds] = useAtom(selectedDashboardIdsAtom);
  const [isSaveWidgetToDashboardModalOpen, setIsSaveWidgetToDashboardModalOpen] = useAtom(
    isSaveWidgetToDashboardModalOpenAtom,
  );
  const [{ visType, dataType }, setSaveWidgetToDashboardData] = useAtom(saveWidgetToDashboardDataAtom);

  const navigate = useNavigate();
  const setToastData = useSetAtom(toastDataAtom);
  const closeToast = useSetAtom(closeToastAtom);

  const {
    t,
    i18n: { language },
  } = useTranslation(['dashboard', 'general']);
  const translatedVisType = t('radar', { ns: 'visualisation' });

  const saveItemToDashboard = useSaveItemToDashboard();

  const closeAndResetModal = () => {
    setIsSaveWidgetToDashboardModalOpen(false);
    setSelectedDashboardIds([]);
  };

  const onConfirm = async () => {
    const isSuccess = await saveItemToDashboard(visType, dataType, selectedDashboardIds);

    const toastContent =
      /* we only want to show the button if we are saving to a single dashboard */
      isSuccess && selectedDashboardIds.length === 1 ? (
        <Button
          size="small"
          variant="secondary"
          onClick={() => {
            closeToast();
            navigate(`/${language}/dashboard/${selectedDashboardIds[0]}`);
          }}
        >
          {t('viewDashboard', { ns: 'widget' })}
        </Button>
      ) : null;

    setToastData('addToDashboard', isSuccess, translatedVisType, toastContent);
    setSaveWidgetToDashboardData(RESET);

    closeAndResetModal();
  };

  return (
    <ConfirmationDialog
      id="add-widget-to-dashboard-modal"
      title={t('selectDashboards')}
      isOpen={isSaveWidgetToDashboardModalOpen}
      confirmLabel={t('confirm', { ns: 'general' })}
      onConfirm={onConfirm}
      onCancel={closeAndResetModal}
      isDisabledConfirm={selectedDashboardIds.length === 0}
    >
      <Suspense fallback={<DashboardTableBase dashboards={[]} />}>
        <DashboardTableWithData />
      </Suspense>
    </ConfirmationDialog>
  );
};

export const SaveWidgetToDashboardModal = () => (
  <Suspense>
    <SaveWidgetToDashboardModalWithData />
  </Suspense>
);
