import { Table, Typography } from '@statsbomb/kitbag-components';
import { useTranslation } from 'react-i18next';
import { useAtomValue, useSetAtom } from 'jotai';
import { dashboardConfigsAtom } from '@/atoms/queries/userConfigs';
import { Suspense } from 'react';
import dayjs from 'dayjs';
import { DATE_FORMAT } from '@/consts/date';
import { Link } from 'react-router-dom';
import { UserConfigDashboardDefinition } from '@/types/userConfigs';
import { UserConfigMenu } from '@/components/menu/UserConfigMenu/UserConfigMenu';
import {
  dashboardIdToDeleteAtom,
  dashboardNameAtom,
  isRenameDashboardModalOpenAtom,
} from '@/atoms/dashboard/dashboard';
import { TableWrapper } from '../../tables/TableWrapper';
import { TableCell } from '../../tables/TableCell';

const DashboardsTableBase = ({ dashboards }: { dashboards?: UserConfigDashboardDefinition[] }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation(['dashboard', 'general']);

  const setDashboardName = useSetAtom(dashboardNameAtom);
  const setIsRenamingModalOpen = useSetAtom(isRenameDashboardModalOpenAtom);
  const setDashboardIdToDeleteAtom = useSetAtom(dashboardIdToDeleteAtom);

  return (
    <TableWrapper classes="z-0">
      <div className="overflow-x-auto">
        {/* TODO (KC-350: Table variant with non alternating row colours) */}
        <Table withBorder={false}>
          <Table.Head>
            <Table.Row>
              <Table.HeaderCell size="regular">{t('dashboardsCount', { count: dashboards?.length })}</Table.HeaderCell>
              <Table.HeaderCell size="regular">{t('lastModified', { ns: 'general' })}</Table.HeaderCell>
              <Table.HeaderCell />
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {dashboards?.map(dashboard => {
              const id = dashboard.configId;
              const navigationPath = `/${language}/dashboard/${id}`;

              return (
                /* TODO (KC-351: Hoverable table rows) */
                <Table.Row key={id} data-testid={`dashboard-row-${id}`} className="cursor-pointer">
                  <TableCell>
                    <Link to={navigationPath} className="block">
                      <Typography variant="bodyRegular" as="span">
                        {dashboard.definition.name || t('untitled', { ns: 'general' })}
                      </Typography>
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Link to={navigationPath} className="block">
                      <Typography as="span" variant="bodyRegular">
                        {dayjs(dashboard.updatedAt).format(DATE_FORMAT)}
                      </Typography>
                    </Link>
                  </TableCell>
                  <TableCell>
                    <div className="flex">
                      {/* TODO (PPC-1345: Whole row clickable discussion) */}
                      <Link to={navigationPath} className="mx-auto w-full" />
                      <div className="justify-end">
                        <UserConfigMenu
                          configId={id}
                          onRenameClick={(dashboardName: string) => {
                            setDashboardName(dashboardName);
                            setIsRenamingModalOpen(true);
                          }}
                          onDeleteClick={(id: string, name: string) => {
                            setDashboardIdToDeleteAtom(id);
                            setDashboardName(name);
                          }}
                        />
                      </div>
                    </div>
                  </TableCell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </div>
    </TableWrapper>
  );
};

const DashboardsTableWithData = () => {
  const dashboards = useAtomValue(dashboardConfigsAtom);

  return <DashboardsTableBase dashboards={dashboards} />;
};

export const DashboardsTable = () => (
  <Suspense fallback={<DashboardsTableBase />}>
    <DashboardsTableWithData />
  </Suspense>
);
