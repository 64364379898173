import { Table } from '@statsbomb/kitbag-components';
import { useTranslation } from 'react-i18next';
import { NestedObject } from '@/types/object';
import { snakeToCamel } from '@/utils/queries';
import { CellValueToDisplay } from '@/components/tables/CellValueToDisplay';
import { DataTableColumn } from '@/types/table';
import { translateValue } from '@/utils/table';
import { PreviewTableState } from '../contentState/PreviewTableState';
import { TableWrapper } from './TableWrapper';
import { TableScopeProvider } from './TableScopeProvider';

export const PreviewEventsBase = ({
  eventsForMetric = [],
  eventDataTableColumns,
  isLoading = false,
}: {
  eventsForMetric?: NestedObject[];
  eventDataTableColumns?: DataTableColumn[];
  isLoading?: boolean;
}) => {
  const { t } = useTranslation('events');
  const eventKeys = ['event.type', 'event.attributes.outcome', 'event.x', 'event.y'];
  const hasData = eventsForMetric.length > 0;

  return (
    <TableScopeProvider>
      <TableWrapper>
        <div className="max-h-56 overflow-auto">
          <Table withBorder={false}>
            <Table.Head>
              <Table.Row>
                {eventKeys.map((key, index) => (
                  <Table.HeaderCell key={key} textAlign="left" size="regular">
                    <span data-testid={`column-${index}`}>{t(`keys.${key}`)}</span>
                  </Table.HeaderCell>
                ))}
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {eventsForMetric.map(event => (
                <Table.Row key={String(event['event.eventId'])}>
                  {eventKeys.map(key => {
                    const columnRule = eventDataTableColumns?.find(column => snakeToCamel(column.key) === key)
                      ?.formatRule;
                    const value = translateValue(key, event[key], columnRule, t);
                    return <CellValueToDisplay key={key} eventKey={key} value={value} columnRule={columnRule} />;
                  })}
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
          <PreviewTableState isLoading={isLoading} hasData={hasData} />
        </div>
      </TableWrapper>
    </TableScopeProvider>
  );
};
