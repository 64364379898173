import { toastDataAtom } from '@/atoms/toast';
import { useDeleteUserConfig } from '@/hooks/useDeleteUserConfig';
import { useAtom, useSetAtom } from 'jotai';
import { dashboardIdToDeleteAtom, dashboardNameAtom } from '@/atoms/dashboard/dashboard';
import { RESET } from 'jotai/utils';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { noop } from '@/utils/noop';
import { GenericDeleteModal } from '../GenericDeleteModal/GenericDeleteModal';

const DeleteDashboardModalWithData = ({ afterDeleteHandler }: { afterDeleteHandler: () => void }) => {
  const deleteUserConfig = useDeleteUserConfig();
  const setToastData = useSetAtom(toastDataAtom);

  const [dashboardIdToDelete, setDashboardIdToDelete] = useAtom(dashboardIdToDeleteAtom);
  const [dashboardName, setDashboardName] = useAtom(dashboardNameAtom);

  const { t } = useTranslation('navigation');

  return (
    dashboardIdToDelete && (
      <GenericDeleteModal
        id="delete-dashboard-modal"
        itemToDelete={dashboardName}
        itemType={t('primary.dashboard').toLowerCase()}
        onConfirm={async () => {
          const isSuccess = await deleteUserConfig(dashboardIdToDelete);
          setToastData('deleteDashboard', isSuccess, dashboardName);

          setDashboardIdToDelete(RESET);
          setDashboardName(RESET);

          afterDeleteHandler();
        }}
        onCancel={() => {
          setDashboardIdToDelete(RESET);
          setDashboardName(RESET);
        }}
      />
    )
  );
};

export const DeleteDashboardModal = ({ afterDeleteHandler = noop }: { afterDeleteHandler?: () => void }) => (
  <Suspense>
    <DeleteDashboardModalWithData afterDeleteHandler={afterDeleteHandler} />
  </Suspense>
);
