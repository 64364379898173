import { setAtomsToHydrateAtom } from '@/atoms/hydration';
import { useSetWidgetDataDebouncedFilterParams } from '@/hooks/apiFilterParams/useSetWidgetDataDebouncedFilterParams';
import { WidgetHydrateComponent } from '@/types/widget';
import { getWidgetAtomsToHydrate } from '@/utils/atoms/atomHydration';
import { useSetAtom } from 'jotai';
import { useEffect } from 'react';

// TODO (PPC-1276: Do we need both useAtomsToHydrate and WidgetAtomHydration?)
export const WidgetAtomHydration = ({
  children,
  filters,
  displaySettings,
  widgetId,
  entity,
  entityId,
}: WidgetHydrateComponent) => {
  useSetWidgetDataDebouncedFilterParams();
  const atomsToHydrate = getWidgetAtomsToHydrate({ widgetId, entity, entityId, filters, displaySettings });
  const setAtomsToHydrate = useSetAtom(setAtomsToHydrateAtom);

  useEffect(() => setAtomsToHydrate(atomsToHydrate), []);

  return children;
};
