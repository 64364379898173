import { atomWithDebounce } from '@/atoms/debounce';
import { pageAndOrderParamsAtom } from '@/atoms/general';
import { metricKeyAtom } from '@/atoms/metric';
import { playerIdAtom } from '@/atoms/player/player';
import { EVENT_MARKERS_LIMIT } from '@/consts/visualisations';
import { Nullable } from '@/types/generic';
import { NestedObject } from '@/types/object';
import { convertFilterParamsToString, hasEmptyEqFilters } from '@/utils/api';
import {
  convertMarkerDataIntoMarkers,
  convertMarkerDataIntoVideoDescriptions,
  processEventDataAtom,
} from '@/utils/atoms/eventData';
import { EventMarker, EventWithRels } from '@statsbomb/parachute-types';
import { atom } from 'jotai';
import { atomWithSuspenseQuery } from 'jotai-tanstack-query';
import { fetchClientAtom } from '../client';

export const playerEventMetricFiltersDebounceObject = atomWithDebounce<Nullable<NestedObject>>(null);
export const { debouncedValueAtom: playerEventMetricFiltersAtom } = playerEventMetricFiltersDebounceObject;

const playerRawEventMarkersAtom = atomWithSuspenseQuery(get => {
  const queryKey = [
    'eventMarkersForMetric',
    get(metricKeyAtom),
    get(playerIdAtom),
    get(playerEventMetricFiltersAtom),
  ] as const;

  const queryFn = async ({ queryKey: [, metricKey, playerId, filterParams] }: { queryKey: typeof queryKey }) => {
    if (!playerId || !metricKey || hasEmptyEqFilters(filterParams)) return [];
    const { fetch } = get(fetchClientAtom);
    return (await fetch(
      `/player/${playerId}/metric/${metricKey}/event-markers${convertFilterParamsToString({
        ...filterParams,
        limit: EVENT_MARKERS_LIMIT,
      })}`,
    )) as Promise<EventMarker[]>;
  };

  return { queryKey, queryFn };
});

export const playerEventMarkersForMetricAtom = atom(async get => {
  const playerEventMarkersForMetricAtom = await get(playerRawEventMarkersAtom);
  return convertMarkerDataIntoMarkers(playerEventMarkersForMetricAtom.data);
});

const playerRawEventsAtom = atomWithSuspenseQuery(get => {
  const queryKey = [
    'eventsForMetric',
    get(metricKeyAtom),
    get(playerIdAtom),
    get(playerEventMetricFiltersAtom),
    get(pageAndOrderParamsAtom),
  ] as const;

  const queryFn = async ({
    queryKey: [, metricKey, playerId, filterParams, pageAndOrderParams],
  }: {
    queryKey: typeof queryKey;
  }) => {
    if (!playerId || !metricKey || hasEmptyEqFilters(filterParams)) return [];
    const { fetch } = get(fetchClientAtom);
    return (await fetch(
      `/player/${playerId}/metric/${metricKey}/events${convertFilterParamsToString({
        ...pageAndOrderParams,
        ...filterParams,
      })}`,
    )) as Promise<EventWithRels[]>;
  };

  return { queryKey, queryFn };
});
export const playerEventsForMetricAtom = atom(async get => processEventDataAtom(await get(playerRawEventsAtom)));

const playerRawEventsCountAtom = atomWithSuspenseQuery(get => {
  const queryKey = [
    'eventsForMetricCount',
    get(metricKeyAtom),
    get(playerIdAtom),
    get(playerEventMetricFiltersAtom),
  ] as const;

  const queryFn = async ({ queryKey: [, metricKey, playerId, filterParams] }: { queryKey: typeof queryKey }) => {
    if (!playerId || !metricKey || hasEmptyEqFilters(filterParams)) return 0;
    const { fetch } = get(fetchClientAtom);
    return (await fetch(
      `/player/${playerId}/metric/${metricKey}/events/count${convertFilterParamsToString(filterParams)}`,
    )) as Promise<number>;
  };

  return { queryKey, queryFn };
});
export const playerEventsForMetricCountAtom = atom(async get => (await get(playerRawEventsCountAtom)).data);

export const playerEventVideoDescriptionsAtom = atom(async get => {
  const playerEventMarkersForMetricAtom = await get(playerRawEventMarkersAtom);
  return convertMarkerDataIntoVideoDescriptions(playerEventMarkersForMetricAtom.data);
});
