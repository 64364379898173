import { useAtomValue } from 'jotai';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Entity } from '@/types/entity';
import { RadarTemplate } from '@/types/radar';
import { PLAYER_RADAR_TEMPLATES, TEAM_RADAR_TEMPLATES } from '@/consts/radar';
import { widgetDisplaySettingsAtom } from '@/atoms/dashboard/dashboardSaving';
import { useGetEntity } from './useGetEntity';

const entityTemplateNameMap: Record<Entity, RadarTemplate[]> = {
  player: PLAYER_RADAR_TEMPLATES,
  team: TEAM_RADAR_TEMPLATES,
};

// a hook for getting the correct translated radar template name for an entity
export const useRadarTemplateName = () => {
  const { entity } = useGetEntity();
  const { pathname } = useLocation();
  const { t } = useTranslation('radar');
  const displaySettings = useAtomValue(widgetDisplaySettingsAtom);

  if ((entity !== 'team' && entity !== 'player') || !pathname.includes('radar')) {
    return '';
  }

  const selectedTemplateKey = entityTemplateNameMap[entity].find(
    template => template.id === displaySettings.radarTemplateId,
  )?.labelKey;

  const selectedTemplateName = t(`templates.${selectedTemplateKey}`, {
    ns: 'radar',
  });

  return selectedTemplateName;
};
