import { playerDataColumnsAtom, playerDataVisibleColumnsAtom } from '@/atoms/dataLocker/player/table';
import { EditColumnsButton } from '@/components/button/EditColumnsButton';
import { GeneralErrorBoundaryFallback } from '@/components/errorBoundary/ErrorBoundaryFallback';
import { ErrorBoundary } from '@sentry/react';
import { Suspense, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonWithMenu, Typography } from '@statsbomb/kitbag-components';
import { toCsvFilename } from '@/components/export/Export.helper';
import { PLAYER_DATA_LOCKER_DEFAULT_COLUMNS } from '@/consts/dataLocker/player';
import { SectionTopBar } from '../sectionTopBar/SectionTopBar';
import { ExportCsvMenuItem } from '../export/ExportCsvMenuItem';

const PlayerAggDataControlsBase = ({
  heading,
  disabled,
  csvDownloadUrl,
  tableDescription,
}: {
  heading: string;
  disabled: boolean;
  csvDownloadUrl: string;
  tableDescription: string;
}) => {
  const { t } = useTranslation(['visualisation', 'csv', 'general', 'entity', 'navigation']);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <ErrorBoundary fallback={<GeneralErrorBoundaryFallback />}>
      <SectionTopBar>
        <Typography variant="headingRegular">{heading}</Typography>
        <div className="flex flex-row gap-2 ml-auto">
          <EditColumnsButton
            disabled={disabled}
            columnsAtom={playerDataColumnsAtom}
            visibleColumnsAtom={playerDataVisibleColumnsAtom}
            defaultColumns={PLAYER_DATA_LOCKER_DEFAULT_COLUMNS}
          />
        </div>
        <ButtonWithMenu
          icon="More"
          isOpen={isOpen}
          onClick={() => setIsOpen(true)}
          onOutsideClick={() => setIsOpen(false)}
          placement="bottom-end"
          variant="ghost"
          title={t('optionsMenu', { ns: 'general' })}
          disabled={disabled}
        >
          <ExportCsvMenuItem url={csvDownloadUrl} fileName={toCsvFilename(tableDescription)} setIsOpen={setIsOpen} />
        </ButtonWithMenu>
      </SectionTopBar>
    </ErrorBoundary>
  );
};

export const PlayerAggDataControls = ({
  heading,
  csvDownloadUrl,
  tableDescription,
}: {
  heading: string;
  csvDownloadUrl: string;
  tableDescription: string;
}) => (
  <Suspense
    fallback={
      <PlayerAggDataControlsBase
        heading={heading}
        csvDownloadUrl={csvDownloadUrl}
        tableDescription={tableDescription}
        disabled
      />
    }
  >
    <PlayerAggDataControlsBase
      heading={heading}
      csvDownloadUrl={csvDownloadUrl}
      tableDescription={tableDescription}
      disabled={false}
    />
  </Suspense>
);
