import { ColumnTopBar } from '../columnTopBar/ColumnTopBar';
import { VisualisationMenu } from '../visualisations/VisualisationMenu';

export const RadarHeader = ({ children }: { children?: React.ReactNode }) => (
  <ColumnTopBar>
    <div className="flex flex-1 items-center justify-between">
      {children}
      <VisualisationMenu visType="radar" dataType="aggregate" />
    </div>
  </ColumnTopBar>
);
