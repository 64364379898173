import { ButtonIcon } from '@statsbomb/kitbag-components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PrimitiveAtom, useAtomValue } from 'jotai';
import { PromiseAtom } from '@/types/atom';
import { DataTableColumn } from '@/types/table';
import { EditTableColumnsModal } from '../modal/EditTableColumnsModal/EditTableColumnsModal';

export const EditColumnsButton = ({
  disabled,
  columnsAtom,
  visibleColumnsAtom,
  defaultColumns,
}: {
  disabled?: boolean;
  columnsAtom: PromiseAtom<DataTableColumn[]>;
  visibleColumnsAtom: PrimitiveAtom<string[]>;
  defaultColumns: string[];
}) => {
  const [showEditColumnsModal, setShowEditColumnsModal] = useState(false);
  const visibleColumns = useAtomValue(visibleColumnsAtom);
  const { t } = useTranslation(['table', 'general']);

  return (
    <>
      <ButtonIcon
        size="small"
        icon="Visibility"
        displayText="left"
        variant="secondary"
        onClick={() => setShowEditColumnsModal(true)}
        disabled={disabled}
        title={t('openColumnsModal')}
      >
        {`${t('editColumns')} (${visibleColumns.length})`}
      </ButtonIcon>
      {showEditColumnsModal && (
        <EditTableColumnsModal
          setShowEditColumnsModal={setShowEditColumnsModal}
          columnsAtom={columnsAtom}
          visibleColumnsAtom={visibleColumnsAtom}
          defaultColumns={defaultColumns}
        />
      )}
    </>
  );
};
