import { ReactNode } from 'react';
import { useNavigate, useOutlet } from 'react-router-dom';
import { ErrorBoundary } from '@sentry/react';
import env from '@/env';
import { GRID_ITEM_STYLES, SCROLLABLE_GRID_PARENT_STYLES, SCROLLABLE_GRID_ITEM_STYLES } from '@/consts/grids';
import { Grid } from '@statsbomb/kitbag-components';
import classNames from 'classnames';
import { isMobileFiltersPanelOpenAtom, isSettingsPanelOpenAtom } from '@/atoms/sidebar';
import { useAtom } from 'jotai';
import { GeneralErrorBoundaryFallback } from '../errorBoundary/ErrorBoundaryFallback';
import { DebugPanel } from '../debug/DebugPanel';
import { MobileFilterButton } from '../button/MobileFilterButton';
import { MobileModalCloseButton } from '../button/MobileModalCloseButton';
import { SettingsPanel } from '../settings/SettingsPanel';

export const LayoutWithFilters = ({ filters, children }: { filters: ReactNode; children: ReactNode }) => {
  const outlet = useOutlet();
  const [isMobileFiltersPanelOpen, setIsMobileFiltersPanelOpen] = useAtom(isMobileFiltersPanelOpenAtom);
  const [isSettingsPanelOpen, setIsSettingsPanelOpen] = useAtom(isSettingsPanelOpenAtom);
  const navigate = useNavigate();
  const handleCloseMobileModal = () => {
    if (outlet) {
      navigate(-1);
    } else {
      setIsSettingsPanelOpen(false);
      setIsMobileFiltersPanelOpen(false);
    }
  };

  return (
    <ErrorBoundary fallback={<GeneralErrorBoundaryFallback />}>
      <div className="grid-with-filters">
        <div
          className={classNames(GRID_ITEM_STYLES, SCROLLABLE_GRID_PARENT_STYLES, 'mobile-modal', {
            hidden: !isMobileFiltersPanelOpen && !isSettingsPanelOpen,
          })}
        >
          {isSettingsPanelOpen && <SettingsPanel />}
          <div className={classNames(SCROLLABLE_GRID_ITEM_STYLES, { hidden: isSettingsPanelOpen })}>
            <MobileModalCloseButton />
            {filters}
          </div>
        </div>
        <div className="grid-wrapper">
          <Grid>
            <Grid item xs={12} lg={outlet ? 8 : 12}>
              <div className={GRID_ITEM_STYLES}>
                <MobileFilterButton />
                {children}
              </div>
            </Grid>
            {outlet && (
              <Grid item xs={12} lg={4}>
                <div className={classNames(GRID_ITEM_STYLES, 'mobile-modal preview-pane')}>{outlet}</div>
              </Grid>
            )}
          </Grid>
        </div>
        {(outlet || isMobileFiltersPanelOpen || isSettingsPanelOpen) && (
          <div
            data-testid="mobile-modal-bg"
            className="mobile-modal-bg"
            onClick={handleCloseMobileModal}
            role="presentation"
          />
        )}
      </div>
      {/* @istanbul-ignore */}
      {env.VITE_NODE_ENV === 'development' && <DebugPanel />}
    </ErrorBoundary>
  );
};
