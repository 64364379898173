import { ErrorBoundary } from '@sentry/react';
import { Flag } from '@statsbomb/kitbag-components';

// We do not have a flag for these country codes yet and do not want to display the warning in the console
const EXCLUDED_FLAGS = ['SAM', 'NCAM', 'GAEA'];

export const FlagIcon = ({ countryCode, size = 'regular' }: { countryCode: string; size?: string }) => {
  if (EXCLUDED_FLAGS.includes(countryCode)) return null;
  return (
    <ErrorBoundary fallback={<span>{countryCode}</span>}>
      <span className="[&>div]:flex">
        <Flag countryCode={countryCode} size={size} />
      </span>
    </ErrorBoundary>
  );
};
