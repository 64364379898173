import { competitionsToQueryAtom } from '@/atoms/filters/highLevel/competitions';
import { defaultSeasonsAtom } from '@/atoms/filters/highLevel/seasons';
import { eventDataFilterParamsAtom, eventDataFilterParamsDebounceObject } from '@/atoms/queries/eventData';
import { DEFAULT_DEBOUNCE_MS } from '@/consts/api';
import { useAtomValue, useSetAtom } from 'jotai';
import { useDeepEffect } from '../useDeepEffect';

export const useSetEventDataDebouncedFilterParams = () => {
  const defaultSeasons = useAtomValue(defaultSeasonsAtom);
  const competitionsToQuery = useAtomValue(competitionsToQueryAtom);
  const eventDataFilterParams = useAtomValue(eventDataFilterParamsAtom);
  const {
    debouncedValueAtom: eventDataFilterParamsDebounceAtom,
    timeoutDelayAtom: eventDataFilterParamsTimeoutDelayAtom,
  } = eventDataFilterParamsDebounceObject;
  const setEventDataFilterParamsDebounce = useSetAtom(eventDataFilterParamsDebounceAtom);
  const setEventDataFilterParamsTimeoutDelay = useSetAtom(eventDataFilterParamsTimeoutDelayAtom);

  useDeepEffect(() => {
    if (!defaultSeasons || competitionsToQuery.length === 0) return;
    setEventDataFilterParamsDebounce(eventDataFilterParams);
    // Ensure that the timeout delay is set (we initialise this to 0 so that we don't debounce
    // on initial page load)
    setEventDataFilterParamsTimeoutDelay(DEFAULT_DEBOUNCE_MS);
  }, [defaultSeasons, competitionsToQuery, eventDataFilterParams]);
};
