import { filtersToPersistAtom } from '@/atoms/filters/persistedFilters';
import {
  isLoadFilterSetModalOpenAtom,
  isSaveFilterModalOpenAtom,
  filterSetNameAtom,
  selectedFilterSetAtom,
} from '@/atoms/filters/userFilters';
import { filterSetsAtom } from '@/atoms/queries/userConfigs';
import { useUpsertFilterSetConfig } from '@/hooks/useUpsertFilterSetConfig';
import { UserConfigFilterDefinition } from '@/types/userConfigs';
import {
  Button,
  ButtonIcon,
  Menu,
  MenuItem,
  MenuItemText,
  Flyout,
  Icon,
  Typography,
  useRect,
} from '@statsbomb/kitbag-components';
import { useTheme } from 'styled-components';
import { useAtomValue, useSetAtom } from 'jotai';
import { Suspense, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface MenuItemProps {
  selectedFilterSet: UserConfigFilterDefinition;
  onClick: () => void;
}

const SaveMenuItem = ({ selectedFilterSet, onClick }: MenuItemProps) => {
  const { t } = useTranslation('general');
  const filtersToPersist = useAtomValue(filtersToPersistAtom);
  const upsertFilterSetConfig = useUpsertFilterSetConfig();

  return (
    <MenuItem
      onClick={() => {
        upsertFilterSetConfig(selectedFilterSet.definition.name, filtersToPersist, selectedFilterSet.configId);
        onClick();
      }}
    >
      <div className="flex gap-x-2 items-center">
        <Icon variant="Save" size="small" />
        <MenuItemText>{t('save', { ns: 'general' })}</MenuItemText>
      </div>
    </MenuItem>
  );
};

const SaveAsMenuItem = ({ selectedFilterSet, onClick }: MenuItemProps) => {
  const { t } = useTranslation('general');
  const setFilterSetName = useSetAtom(filterSetNameAtom);
  const setIsSaveModalOpen = useSetAtom(isSaveFilterModalOpenAtom);

  return (
    <MenuItem
      onClick={() => {
        setFilterSetName(`${selectedFilterSet.definition.name} - ${t('copy')}`);
        setIsSaveModalOpen(true);
        onClick();
      }}
    >
      <div className="flex gap-x-2 items-center">
        <Icon variant="SaveAs" size="small" />
        <MenuItemText>{t('saveAsCopy', { ns: 'general' })}</MenuItemText>
      </div>
    </MenuItem>
  );
};

const SaveFiltersButtonWithMenu = ({
  selectedFilterSet,
  isLoading,
}: {
  selectedFilterSet: UserConfigFilterDefinition;
  isLoading?: boolean;
}) => {
  const { t } = useTranslation('general');
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const theme = useTheme();
  const isTouch = theme.mqMatch('md');
  const [relativeTriggerRef, rect] = useRect<HTMLDivElement>(0);

  const closeMenu = () => setIsMenuOpen(false);

  /* 
    this is to close the menu when the browser is resized
    unfortunately KC doesn't handle this 
  */
  useEffect(closeMenu, [rect]);

  return (
    <div ref={relativeTriggerRef} data-testid="menu-save-button">
      <ButtonIcon
        variant="secondary"
        displayText="right"
        icon="ChevronDown"
        onClick={() => setIsMenuOpen(true)}
        size="small"
        disabled={isLoading}
      >
        {t('save')}
      </ButtonIcon>
      {isMenuOpen && (
        <Flyout
          testId="user-filters-menu"
          triggerPosition={rect}
          placement="bottom-start"
          /* 125 moves the contents 125px to the left for mobile
          rather than coming off the side */
          offset={{ left: isTouch ? 125 : 0, top: 0 }}
        >
          <Menu onOutsideClick={closeMenu}>
            <SaveMenuItem selectedFilterSet={selectedFilterSet} onClick={closeMenu} />
            <SaveAsMenuItem selectedFilterSet={selectedFilterSet} onClick={closeMenu} />
          </Menu>
        </Flyout>
      )}
    </div>
  );
};

const UserFiltersButtonsBase = ({ isLoading, hasFilterSets }: { isLoading?: boolean; hasFilterSets?: boolean }) => {
  const { t } = useTranslation('general');

  const selectedFilterSet = useAtomValue(selectedFilterSetAtom);

  const filterSetName = selectedFilterSet ? selectedFilterSet.definition.name : t('filters.other');

  const setIsLoadModalOpen = useSetAtom(isLoadFilterSetModalOpenAtom);
  const setIsSaveModalOpen = useSetAtom(isSaveFilterModalOpenAtom);

  return (
    <div data-testid="user-filters-buttons" className="flex justify-between gap-2 pb-4 items-center">
      <Typography variant="headingMedium" as="p" className="truncate" title={filterSetName}>
        {filterSetName}
      </Typography>
      <div className="flex gap-x-2">
        <Button
          onClick={() => setIsLoadModalOpen(true)}
          isDisabled={isLoading || !hasFilterSets}
          size="small"
          variant="secondary"
        >
          {t('load')}
        </Button>
        {!selectedFilterSet ? (
          <Button variant="secondary" onClick={() => setIsSaveModalOpen(true)} size="small" isDisabled={isLoading}>
            {t('save')}
          </Button>
        ) : (
          <SaveFiltersButtonWithMenu selectedFilterSet={selectedFilterSet} isLoading={isLoading} />
        )}
      </div>
    </div>
  );
};

const UserFiltersButtonsWithData = () => {
  const filterSets = useAtomValue(filterSetsAtom);
  return <UserFiltersButtonsBase hasFilterSets={filterSets.length > 0} />;
};

export const UserFiltersButtons = () => (
  <Suspense fallback={<UserFiltersButtonsBase isLoading />}>
    <UserFiltersButtonsWithData />
  </Suspense>
);
