import { PageSubNav } from './PageSubNav';

const playerNavItems = [
  {
    translation: { key: 'secondary.overview' },
    route: 'radar',
  },
  {
    translation: { key: 'secondary.pitchVis' },
    route: 'vis',
  },
  {
    translation: { key: 'secondary.games' },
    route: 'matches',
  },
];

export const PlayerNav = () => <PageSubNav items={playerNavItems} />;
