import { fetchClientAtom } from '@/atoms/queries/client';
import { MenuItem, Icon, MenuItemText } from '@statsbomb/kitbag-components';
import { SetStateAction, useAtomValue, useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import React, { Dispatch } from 'react';
import { toastDataAtom } from '@/atoms/toast';
import { createLinkAndDownload } from './Export.helper';

/**
 * This BOM needs to be prepended to the CSV file to get Excel to render non-ascii characters in a utf-8 encoded file
 * correctly
 */
const UTF_8_BYTE_ORDER_MARKER = new Uint8Array([0xef, 0xbb, 0xbf]);

const addLanguageQueryParam = (url: string, language: string) =>
  url.includes('?') ? `${url}&lang=${language}` : `${url}?lang=${language}`;

export const ExportCsvMenuItem = ({
  url,
  fileName,
  setIsOpen,
}: {
  url: string;
  fileName?: string;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const { t } = useTranslation('csv');
  const { fetch } = useAtomValue(fetchClientAtom);
  const setToastData = useSetAtom(toastDataAtom);
  const {
    i18n: { language },
  } = useTranslation('general');

  const handleCsvExport = async () => {
    setIsOpen(false);
    setToastData('exportCsvStarted', true);

    const response = await fetch(addLanguageQueryParam(url, language), {
      headers: { accept: 'text/csv' },
    });

    try {
      if (response.status !== 200) {
        throw new Error('Invalid status');
      }

      // The response from the server already includes the BOM but sadly this isn't correctly added to the Blob, so we
      // need to strip it off and add again using the following code, otherwise you end up with weird (visible)
      // characters in your CSV.
      const csvString = (await response.text()).replace(UTF_8_BYTE_ORDER_MARKER, '');
      const csvStringBlob = new Blob([UTF_8_BYTE_ORDER_MARKER, csvString], {
        type: response.headers.get('content-type'),
      });
      const dataUrl = URL.createObjectURL(csvStringBlob);
      // Because we do not enable the button if the filename is undefined, we know that if we get here this filename must be a string
      createLinkAndDownload(dataUrl, fileName as string);
      setToastData('exportCsvCompleted', true);
    } catch (e) {
      setToastData('exportCsvCompleted', false);
    }
  };

  return (
    <MenuItem onClick={handleCsvExport} disabled={!fileName}>
      <div className="flex gap-x-2 items-center">
        <Icon variant="Download" size="small" />
        <MenuItemText data-testid={`export-${fileName}`}>{t('export')}</MenuItemText>
      </div>
    </MenuItem>
  );
};
