import { teamCompetitionOptionsAtom, teamGamesAtom, teamSeasonOptionsAtom } from '@/atoms/queries/teams';
import { atom } from 'jotai';
import {
  filterGamesByCompetition,
  filterGamesByGameType,
  filterGamesBySeason,
  pipeFilterFunctions,
} from '@/utils/filters';
import { convertToDropdownOption } from '@/utils/object';
import { loadable, unwrap } from 'jotai/utils';
import { FormattedGame, FormattedGameWithTeam } from '@/types/game';
import { getFilteredGames, getGamesBySeason, getSelectedGames } from '@/utils/atoms/game';
import { deselectedGamesAtom } from '../highLevel';
import { selectedGameTypeAtom } from '../gameMetrics';
import { selectedCompetitionIdsAtom } from '../highLevel/competitions';
import { selectedSeasonsToFilterByAtom } from '../highLevel/seasons';

const teamGameFilterFunctionsAtom = atom(get => {
  const selectedGameType = get(selectedGameTypeAtom).value;
  const selectedCompetitionIds = get(selectedCompetitionIdsAtom);
  const selectedSeasonIds = get(selectedSeasonsToFilterByAtom);

  return pipeFilterFunctions<FormattedGame>(
    (entityGame: FormattedGameWithTeam[]) => filterGamesByCompetition(entityGame, selectedCompetitionIds),
    (entityGame: FormattedGameWithTeam[]) => filterGamesBySeason(entityGame, selectedSeasonIds),
    (entityGame: FormattedGameWithTeam[]) => filterGamesByGameType(entityGame, selectedGameType),
  );
});

export const filteredTeamGamesAtom = atom(async get =>
  getFilteredGames(get, teamGamesAtom, teamGameFilterFunctionsAtom),
);

const teamGamesOptionsAtom = atom(async get => {
  const teamGames = await get(filteredTeamGamesAtom);
  return teamGames.map(({ game: { gameId, name } }) => convertToDropdownOption(name, gameId));
});

export const teamSelectedGamesAtom = atom(async get =>
  getSelectedGames(get, teamGamesOptionsAtom, deselectedGamesAtom),
);

const teamSelectedGamesForQueryAtom = atom(async get => {
  const userTeamSelectedGames = await get(teamSelectedGamesAtom);
  return userTeamSelectedGames.length > 0 ? userTeamSelectedGames : get(teamGamesOptionsAtom);
});

export const unwrappedTeamSelectedGamesForQueryAtom = unwrap(teamSelectedGamesForQueryAtom, prev => prev || []);

export const loadableTeamSelectedGamesForQueryAtom = loadable(teamSelectedGamesForQueryAtom);

export const teamGamesBySeasonAtom = atom(get =>
  getGamesBySeason(get, teamSeasonOptionsAtom, teamCompetitionOptionsAtom, filteredTeamGamesAtom),
);
