// TODO (PPC-461: PitchVisualisation - Composition Pattern)
import { Suspense } from 'react';
import { useAtomValue } from 'jotai';
import { VisualisationMap } from '@/types/visualisation';
import { SettingsButton } from '@/components/button/SettingsButton';
import { selectedVisualisationAtom } from '@/atoms/visualisation';
import { hasVideoLicenceAtom } from '@/atoms/user';
import { useTranslation } from 'react-i18next';
import { ExportModal } from '@/components/export/ExportModal';
import { useExportTitle } from '@/hooks/exporting/useExportTitle';
import { formatFileName } from '@/utils/array';
import { useExportVisInfo } from '@/hooks/exporting/useExportVisInfo';
import { metricKeyAtom } from '@/atoms/metric';
import { sectionAtom } from '@/atoms/general';
import { selectedEventNamesAtom } from '@/atoms/filters/events';
import { snakeToCamel } from '@/utils/queries';
import { useExportInfo } from '@/hooks/exporting/useExportInfo';
import { VisualisationComponent } from './VisualisationComponent';
import { VisualisationMenu } from '../VisualisationMenu';

const PitchVisualisationWrapper = ({
  headerComponent,
  visualisations,
  footerComponent,
}: {
  headerComponent?: JSX.Element;
  footerComponent?: JSX.Element;
  visualisations: VisualisationMap;
}) => {
  const { t } = useTranslation(['visualisation', 'events', 'metrics']);
  const section = useAtomValue(sectionAtom);
  const hasVideoLicence = useAtomValue(hasVideoLicenceAtom);
  const selectedVis = useAtomValue(selectedVisualisationAtom);
  const selectedMetricKey = useAtomValue(metricKeyAtom);
  const selectedEvents = useAtomValue(selectedEventNamesAtom);

  const translatedSelectedEvents = selectedEvents.map(key => ({
    label: t(`fields.event.type.${snakeToCamel(key)}`, 'events'),
    value: key,
  }));

  const entitySecondaryTitle = selectedMetricKey ? t(`${snakeToCamel(selectedMetricKey)}.name`, { ns: 'metrics' }) : '';
  const dataLockerSecondaryTitle = useExportInfo(translatedSelectedEvents, 'Events', 3);

  const title = useExportTitle();
  const secondaryTitle = section === 'dataLocker' ? dataLockerSecondaryTitle : entitySecondaryTitle;
  const selectedVisName = t(selectedVis.visualisationKey);
  const { info1, info2, info3 } = useExportVisInfo();
  const fileName = formatFileName([title, secondaryTitle, selectedVisName]);

  return (
    <div className="flex flex-col gap-4">
      <div className="flex justify-between gap-1 items-center">
        {headerComponent}
        <div className="flex gap-2 ml-auto">
          <SettingsButton />
          <VisualisationMenu visType={selectedVis.visualisationKey} dataType="event" />
          <ExportModal
            title={title}
            secondaryTitle={secondaryTitle}
            info1={info1}
            info2={info2}
            info3={info3}
            customWidth={600}
            fileName={fileName}
          >
            <VisualisationComponent allowInteraction={hasVideoLicence} visualisations={visualisations} />
          </ExportModal>
        </div>
      </div>
      <VisualisationComponent allowInteraction={hasVideoLicence} visualisations={visualisations} />
      {footerComponent}
    </div>
  );
};

export const PitchVisualisation = ({
  headerComponent,
  visualisations,
  footerComponent,
}: {
  headerComponent?: JSX.Element;
  footerComponent?: JSX.Element;
  visualisations: VisualisationMap;
}) => (
  <Suspense>
    <PitchVisualisationWrapper {...{ headerComponent, visualisations, footerComponent }} />
  </Suspense>
);
