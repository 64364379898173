/* istanbul ignore file */
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { NotFound } from '@/pages/NotFound';
import { DataLockerLayout } from '@/components/layout/dataLocker/DataLockerLayout';
import { EventDataPage } from '@/pages/dataLocker/eventData/EventDataPage';
import { VideoPreview } from '@/components/video/VideoPreview';
import { PlayerAggsPage } from '@/pages/dataLocker/aggregates/player/PlayerAggsPage';
import { DataLockerVideoPage } from '@/pages/dataLocker/video/DataLockerVideoPage';
import { DataLockerVideoPreview } from '@/pages/dataLocker/video/DataLockerVideoPreview';
import { PlayerAggsPreview } from '@/pages/dataLocker/aggregates/player/PlayerAggsPreview';
import { ProtectedVideoRoute } from '@/components/routes/ProtectedVideoRoute';
import { DataLockerLandingPage } from '@/pages/dataLocker/DataLockerLandingPage';

export const DataLockerRoutes = () => (
  <Routes>
    <Route element={<DataLockerLayout />}>
      <Route path="/" element={<DataLockerLandingPage />} />
      <Route path="events" element={<EventDataPage />}>
        <Route path="preview" element={<ProtectedVideoRoute component={<VideoPreview />} />} />
      </Route>
      <Route path="video" element={<ProtectedVideoRoute component={<DataLockerVideoPage />} />}>
        <Route path="preview" element={<ProtectedVideoRoute component={<DataLockerVideoPreview />} />} />
      </Route>
      <Route path="players" element={<PlayerAggsPage />}>
        <Route path="preview" element={<PlayerAggsPreview />} />
      </Route>
      {/* TODO (PPC-281: not found page) */}
      <Route path="*" element={<NotFound />} />
    </Route>
  </Routes>
);
