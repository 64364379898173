import { Loading } from '@/components/translations/Generic';
import { useAtomValue } from 'jotai';
import { eventDataAtom, eventsDataCountAtom } from '@/atoms/queries/eventData';
import { defaultSeasonsAtom } from '@/atoms/filters/highLevel/seasons';
import { EventsTableWithPagination } from '@/components/tables/EventsTable';

export const DataLockerEventsTable = () => {
  if (!useAtomValue(defaultSeasonsAtom)) return <Loading />;

  return <EventsTableWithPagination eventsAtom={eventDataAtom} dataLengthAtom={eventsDataCountAtom} />;
};
