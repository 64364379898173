import { atomWithDebounce } from '@/atoms/debounce';
import { pageAndOrderParamsAtom } from '@/atoms/general';
import { metricKeyAtom } from '@/atoms/metric';
import { teamIdAtom } from '@/atoms/team/team';
import { EVENT_MARKERS_LIMIT } from '@/consts/visualisations';
import { Nullable } from '@/types/generic';
import { NestedObject } from '@/types/object';
import { convertFilterParamsToString, hasEmptyEqFilters } from '@/utils/api';
import {
  convertMarkerDataIntoMarkers,
  convertMarkerDataIntoVideoDescriptions,
  processEventDataAtom,
} from '@/utils/atoms/eventData';
import { EventMarker, EventWithRels } from '@statsbomb/parachute-types';
import { atom } from 'jotai';
import { atomWithSuspenseQuery } from 'jotai-tanstack-query';
import { fetchClientAtom } from '../client';

export const teamEventMetricFiltersDebounceObject = atomWithDebounce<Nullable<NestedObject>>(null);
export const { debouncedValueAtom: teamEventMetricFiltersAtom } = teamEventMetricFiltersDebounceObject;

const teamRawEventMarkersAtom = atomWithSuspenseQuery(get => {
  const queryKey = [
    'eventMarkersForMetric',
    get(metricKeyAtom),
    get(teamIdAtom),
    get(teamEventMetricFiltersAtom),
  ] as const;

  const queryFn = async ({ queryKey: [, metricKey, teamId, filterParams] }: { queryKey: typeof queryKey }) => {
    if (!teamId || !metricKey || hasEmptyEqFilters(filterParams)) return [];
    const { fetch } = get(fetchClientAtom);
    return (await fetch(
      `/team/${teamId}/metric/${metricKey}/event-markers${convertFilterParamsToString({
        ...filterParams,
        limit: EVENT_MARKERS_LIMIT,
      })}`,
    )) as Promise<EventMarker[]>;
  };

  return { queryKey, queryFn };
});
export const teamEventMarkersForMetricAtom = atom(async get => {
  const teamEventMarkersForMetricAtom = await get(teamRawEventMarkersAtom);
  return convertMarkerDataIntoMarkers(teamEventMarkersForMetricAtom.data);
});

const teamRawEventsAtom = atomWithSuspenseQuery(get => {
  const queryKey = [
    'eventsForMetric',
    get(metricKeyAtom),
    get(teamIdAtom),
    get(teamEventMetricFiltersAtom),
    get(pageAndOrderParamsAtom),
  ] as const;

  const queryFn = async ({
    queryKey: [, metricKey, teamId, filterParams, pageAndOrderParams],
  }: {
    queryKey: typeof queryKey;
  }) => {
    if (!teamId || !metricKey || hasEmptyEqFilters(filterParams)) return [];
    const { fetch } = get(fetchClientAtom);
    return (await fetch(
      `/team/${teamId}/metric/${metricKey}/events${convertFilterParamsToString({
        ...pageAndOrderParams,
        ...filterParams,
      })}`,
    )) as Promise<EventWithRels[]>;
  };

  return { queryKey, queryFn };
});
export const teamEventsForMetricAtom = atom(async get => processEventDataAtom(await get(teamRawEventsAtom)));

const teamRawEventsCountAtom = atomWithSuspenseQuery(get => {
  const queryKey = [
    'eventsForMetricCount',
    get(metricKeyAtom),
    get(teamIdAtom),
    get(teamEventMetricFiltersAtom),
  ] as const;

  const queryFn = async ({ queryKey: [, metricKey, teamId, filterParams] }: { queryKey: typeof queryKey }) => {
    if (!teamId || !metricKey || hasEmptyEqFilters(filterParams)) return 0;
    const { fetch } = get(fetchClientAtom);
    return (await fetch(
      `/team/${teamId}/metric/${metricKey}/events/count${convertFilterParamsToString(filterParams)}`,
    )) as Promise<number>;
  };

  return { queryKey, queryFn };
});
export const teamEventsForMetricCountAtom = atom(async get => (await get(teamRawEventsCountAtom)).data);

export const teamEventVideoDescriptionsAtom = atom(async get => {
  const teamEventMarkersForMetricAtom = await get(teamRawEventMarkersAtom);
  return convertMarkerDataIntoVideoDescriptions(teamEventMarkersForMetricAtom.data);
});
