import { teamEventsForMetricCountAtom } from '@/atoms/queries/team/teamEventData';
import { teamMetricDataAtom } from '@/atoms/team/metric';
import { TeamLayoutWithFilters } from '@/components/layout/team/TeamLayoutWithFilters';
import { MetricKeyDropdown } from '@/components/metric/MetricKeyDropdown';
import { SectionTopBar } from '@/components/sectionTopBar/SectionTopBar';
import { VisDisplaySection } from '@/components/section/VisDisplaySection';
import { TeamVisEventsTable } from '@/components/team/vis/TeamVisEventsTable';
import { EventCountInfoBar } from '@/components/visualisations/EventCountInfoBar';
import { PitchVisualisation } from '@/components/visualisations/PitchVisualisation/PitchVisualisation';
import { teamPitchVisComponents } from '@/consts/visualisationComponents';
import { EntityVisModeTopBar } from '@/components/sectionTopBar/entity/EntityVisModeTopBar';
import { useTranslation } from 'react-i18next';
import { useAtomValue } from 'jotai';
import { unwrappedSelectedTeamNameAtom } from '@/atoms/team/info';
import { PageTitle } from '@/components/head/PageTitle';
import { useEntityPageTitle } from '@/hooks/useEntityPageTitle';

export const TeamVisPage = () => {
  const { t } = useTranslation('navigation');
  const teamName = useAtomValue(unwrappedSelectedTeamNameAtom);
  const pageTitle = useEntityPageTitle(t('secondary.pitchVis'), teamName);

  return (
    <>
      <PageTitle>{pageTitle}</PageTitle>
      <TeamLayoutWithFilters>
        <>
          <SectionTopBar>
            <EntityVisModeTopBar />
          </SectionTopBar>
          <VisDisplaySection
            EventsTableComponent={TeamVisEventsTable}
            visualisationComponent={
              <PitchVisualisation
                visualisations={teamPitchVisComponents}
                headerComponent={<MetricKeyDropdown metricDataAtom={teamMetricDataAtom} />}
                footerComponent={<EventCountInfoBar eventCountAtom={teamEventsForMetricCountAtom} />}
              />
            }
          />
        </>
      </TeamLayoutWithFilters>
    </>
  );
};
