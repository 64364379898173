import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { isCreateDashboardModalOpenAtom } from '@/atoms/dashboard/dashboard';
import { useTranslation } from 'react-i18next';
import { Suspense, useState } from 'react';
import { useUpsertUserConfig } from '@/hooks/useUpsertUserConfig';
import { toastDataAtom } from '@/atoms/toast';
import { unwrappedDashboardConfigsAtom } from '@/atoms/queries/userConfigs';
import { GenericSaveModal } from '../GenericSaveModal/GenericSaveModal';

const CreateDashboardModalBase = () => {
  const { t } = useTranslation(['dashboard', 'general']);
  const [isCreateDashboardModalOpen, setIsCreateDashboardModalOpen] = useAtom(isCreateDashboardModalOpenAtom);
  const dashboards = useAtomValue(unwrappedDashboardConfigsAtom);
  const [dashboardName, setDashboardName] = useState('');
  const setToastData = useSetAtom(toastDataAtom);
  const upsertUserConfig = useUpsertUserConfig();

  const dashboardConfigNames = dashboards
    .filter(({ definition }) => definition.name)
    .map(({ definition }) => definition.name as string);

  return (
    isCreateDashboardModalOpen && (
      <GenericSaveModal
        id="create-dashboard-modal"
        title={t('createDashboardText')}
        onConfirm={async () => {
          const trimmedDashboardName = dashboardName.trim();
          const { isSuccess } = await upsertUserConfig({
            configType: 'dashboard',
            configDefinition: { name: trimmedDashboardName, widgets: [] },
          });

          setIsCreateDashboardModalOpen(false);
          setToastData('createDashboard', isSuccess, trimmedDashboardName);
        }}
        onCancel={() => {
          setIsCreateDashboardModalOpen(false);
          setDashboardName('');
        }}
        confirmLabel={t('create', { ns: 'general' })}
        value={dashboardName}
        onChange={setDashboardName}
        existingConfigNames={dashboardConfigNames}
      />
    )
  );
};

export const CreateDashboardModal = () => (
  <Suspense>
    <CreateDashboardModalBase />
  </Suspense>
);
