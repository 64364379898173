import { tableColumnsAtom } from '@/atoms/queries/tableColumns';
import { EVENTS_DATA_LOCKER_DEFAULT_COLUMNS } from '@/consts/dataLocker/events';
import { atom } from 'jotai';

export const eventsDataColumnsAtom = atom(async get => {
  const allColumns = await get(tableColumnsAtom);
  return allColumns;
});

export const eventsDataVisibleColumnsAtom = atom<string[]>(EVENTS_DATA_LOCKER_DEFAULT_COLUMNS);
