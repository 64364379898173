import React, { Suspense, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAtom, useSetAtom } from 'jotai';
import { WidgetFiltersModal } from '@/components/modal/WidgetFiltersModal/WidgetFiltersModal';
import { dashboardEditModeAtom, dashboardToSaveAtom } from '@/atoms/dashboard/dashboardSaving';
import { CancelEditDashboardModal } from '@/components/alerts/CancelEditDashboardModal/CancelEditDashboardModal';
import { DashboardWidgets } from '@/components/dashboard/DashboardWidgets/DashboardWidgets';
import { EditWidgetTitleModal } from '@/components/modal/EditWidgetTitleModal/EditWidgetTitleModal';
import {
  DashboardTopBarFallback,
  DashboardTopBarWithToast,
} from '@/components/dashboard/DashboardTopBar/DashboardTopBar';
import { AppShell } from '@/components/layout/AppShell';
import { PageTitle } from '@/components/head/PageTitle';
import { RenameDashboardModal } from '@/components/modal/RenameDashboardModal/RenameDashboardModal';
import { DeleteDashboardModal } from '@/components/modal/DeleteDashboardModal/DeleteDashboardModal';
import { useNavigate } from 'react-router-dom';
import { useShouldShowHiddenFeature } from '@/hooks/useShouldShowHiddenFeature';
import { DashboardTopBarNew } from '@/components/dashboard/DashboardTopBar/DashboardTopBarNew';
import { useSetDashboardId } from '@/hooks/useSetDashboardId';

const Dashboard = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation(['navigation', 'dashboard', 'general']);

  const navigate = useNavigate();
  const [isOpenCancelModal, setIsOpenCancelModal] = useState(false);
  const [dashboardToSave, setDashboardToSave] = useAtom(dashboardToSaveAtom);
  const setEditMode = useSetAtom(dashboardEditModeAtom);

  const showHiddenFeature = useShouldShowHiddenFeature();

  const hasUnsavedChanges = !!dashboardToSave;

  useSetDashboardId();

  const handleCancel = () => {
    setIsOpenCancelModal(false);
    setDashboardToSave(null);
    setEditMode(false);
  };

  /* istanbul ignore next */
  const handleCancelEdit = () => {
    // display a modal if the user attempts to cancel edit mode without saving changes
    if (hasUnsavedChanges) {
      setIsOpenCancelModal(true);
    } else {
      handleCancel();
    }
  };

  // cancel the dashboard edit if the user navigates away without "properly" cancelling it first
  // TODO (PPC-768: replace with a custom modal by using `useBlocker` from `react-router-dom` if we are able to upgrade to data router)
  useEffect(() => () => handleCancel(), []);

  const topBar = showHiddenFeature ? (
    <DashboardTopBarNew />
  ) : (
    /* istanbul ignore next */ <Suspense fallback={<DashboardTopBarFallback />}>
      <DashboardTopBarWithToast handleCancelEdit={handleCancelEdit} />
    </Suspense>
  );

  return (
    <>
      <PageTitle>{t('primary.dashboard')}</PageTitle>
      <AppShell topBar={topBar}>
        <DashboardWidgets />
        <CancelEditDashboardModal
          isOpen={isOpenCancelModal}
          onCancel={/* istanbul ignore next */ () => setIsOpenCancelModal(false)}
          onConfirm={handleCancel}
        />
        <EditWidgetTitleModal />
        <WidgetFiltersModal />
      </AppShell>
      <RenameDashboardModal />
      <DeleteDashboardModal afterDeleteHandler={() => navigate(`/${language}/dashboards`)} />
    </>
  );
};

export default Dashboard;
