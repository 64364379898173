import { Grid } from '@statsbomb/kitbag-components';
import { DataLockerEventsTable } from '@/components/dataLocker/DataLockerEventsTable';
import { EventDataControls } from '@/components/controls/EventsDataControls';
import { DataLockerLayoutWithFilters } from '@/components/layout/dataLocker/DataLockerLayoutWithFilters';
import { VisDisplaySection } from '@/components/section/VisDisplaySection';
import { VisDisplayMode } from '@/types/visualisation';
import { PitchVisualisation } from '@/components/visualisations/PitchVisualisation/PitchVisualisation';
import { eventsDataCountAtom, eventDataFilterParamsAtom } from '@/atoms/queries/eventData';
import { EventCountInfoBar } from '@/components/visualisations/EventCountInfoBar';
import { useTranslation } from 'react-i18next';
import { useAutoResetPagination } from '@/hooks/useAutoResetPagination';
import { useAtomValue } from 'jotai';
import { PageTitle } from '@/components/head/PageTitle';
import { dataLockerVisualisationComponents } from '../visComponents';

export const EventDataPage = () => {
  const filterParams = useAtomValue(eventDataFilterParamsAtom);
  useAutoResetPagination(filterParams);
  const { t } = useTranslation('navigation');

  return (
    <>
      <PageTitle>{t('pageTitles.dataLocker', { entity: t('event.one', { ns: 'entity' }) })}</PageTitle>
      <DataLockerLayoutWithFilters>
        <Grid>
          <Grid item>
            <EventDataControls />
            <VisDisplaySection
              defaultVisDisplayMode={VisDisplayMode.TABLE}
              EventsTableComponent={DataLockerEventsTable}
              visualisationComponent={
                <PitchVisualisation
                  visualisations={dataLockerVisualisationComponents}
                  footerComponent={<EventCountInfoBar eventCountAtom={eventsDataCountAtom} />}
                />
              }
            />
          </Grid>
        </Grid>
      </DataLockerLayoutWithFilters>
    </>
  );
};
