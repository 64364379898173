import { ConfirmationDialog } from '@statsbomb/kitbag-components';
import { useTranslation } from 'react-i18next';

interface CancelEditDashboardModalProps {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

export const CancelEditDashboardModal = ({ isOpen, onCancel, onConfirm }: CancelEditDashboardModalProps) => {
  const { t } = useTranslation(['dashboard', 'general']);

  return (
    <ConfirmationDialog
      id="cancel-dashboard-edit"
      title={t('cancelEditModal.title')}
      isOpen={isOpen}
      onConfirm={onConfirm}
      onCancel={onCancel}
      cancelLabel={t('cancel', { ns: 'general' })}
      confirmLabel={t('cancelEditModal.exit')}
    >
      {t('cancelEditModal.message')}
    </ConfirmationDialog>
  );
};
