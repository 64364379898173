import { orderByAtom, orderDirectionAtom } from '@/atoms/table';
import { unwrappedSelectedTeamNameAtom } from '@/atoms/team/info';
import { PlayerAggDataControls } from '@/components/controls/PlayerAggDataControls';
import { PageTitle } from '@/components/head/PageTitle';
import { TeamLayoutWithFilters } from '@/components/layout/team/TeamLayoutWithFilters';
import { PlayerAggsTableWithPagination } from '@/components/tables/PlayerAggsTable';
import { TableScopeProvider } from '@/components/tables/TableScopeProvider';
import { SORT_DIRECTION } from '@/types/ordering';
import { useEntityPageTitle } from '@/hooks/useEntityPageTitle';
import { Grid } from '@statsbomb/kitbag-components';
import { useAtomValue, useSetAtom } from 'jotai';
import { useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { playerAggsFilterParamsDebounceObject } from '@/atoms/queries/player/playerAggData';
import { playersNormalisedAggregatesUrl } from '@/query/url';

const TeamSquadPageTable = () => {
  const setOrderTableBy = useSetAtom(orderByAtom);
  const setOrderDirection = useSetAtom(orderDirectionAtom);

  // Using useLayoutEffect to prevent instances where the table briefly
  // renders without default ordering when navigating via the tabs
  useLayoutEffect(() => {
    // Set squad page default ordering
    setOrderDirection(SORT_DIRECTION.DESC);
    setOrderTableBy('aggregates.minutes_played');
  }, []);

  return <PlayerAggsTableWithPagination />;
};

const { debouncedValueAtom: debouncedPlayerAggsFilterParamsAtom } = playerAggsFilterParamsDebounceObject;

export const TeamSquadPage = () => {
  const { t } = useTranslation('navigation');
  const teamName = useAtomValue(unwrappedSelectedTeamNameAtom);
  const pageTitle = useEntityPageTitle(t('secondary.squad'), teamName);
  const playerAggsFilterParams = useAtomValue(debouncedPlayerAggsFilterParamsAtom);

  return (
    <>
      <PageTitle>{pageTitle}</PageTitle>
      <TeamLayoutWithFilters>
        <Grid>
          <Grid item>
            <PlayerAggDataControls
              heading={t(`secondary.squad`)}
              csvDownloadUrl={playersNormalisedAggregatesUrl(playerAggsFilterParams)}
              tableDescription={pageTitle}
            />
            <TableScopeProvider>
              <TeamSquadPageTable />
            </TableScopeProvider>
          </Grid>
        </Grid>
      </TeamLayoutWithFilters>
    </>
  );
};
