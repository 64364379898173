import { filteredTeamGamesAtom, teamGamesBySeasonAtom, teamSelectedGamesAtom } from '@/atoms/filters/team/teamGames';
import { unwrappedTeamAggsMinutesPlayedAtom } from '@/atoms/queries/team/teamRadar';
import { teamCyclesOrderedAtom } from '@/atoms/queries/teams';
import { RadarFilterCompetitionSummary } from '@/components/radar/filterSummaries/RadarFilterCompetitionSummary';
import { RadarFilterGameSummary } from '@/components/radar/filterSummaries/RadarFilterGameSummary';
import { RadarFilterMinsSummary } from '@/components/radar/filterSummaries/RadarFilterMinsSummary';
import { RadarFilterSeasonSummary } from '@/components/radar/filterSummaries/RadarFilterSeasonSummary';

import { RadarTable } from '@/components/radar/table/RadarTable';
import { useHasEmptyRadar } from '@/hooks/useHasEmptyRadar';
import { useShouldShowHiddenFeature } from '@/hooks/useShouldShowHiddenFeature';
import { Suspense } from 'react';

export const TeamRadarTableGroup = () => {
  const hasEmptyRadar = useHasEmptyRadar();
  const shouldShowHiddenFeature = useShouldShowHiddenFeature();

  return (
    <div className="flex flex-col gap-2 @container">
      <RadarTable />
      {!hasEmptyRadar && shouldShowHiddenFeature && (
        <Suspense>
          <div data-testid="team-radar-filter-summary" className="grid gap-2 grid-cols-1 @xs:grid-cols-2">
            <RadarFilterMinsSummary minutesAtom={unwrappedTeamAggsMinutesPlayedAtom} />
            <RadarFilterCompetitionSummary
              filteredGamesDataAtom={filteredTeamGamesAtom}
              selectedGamesAtom={teamSelectedGamesAtom}
              orderedCyclesAtom={teamCyclesOrderedAtom}
            />
            <RadarFilterSeasonSummary
              filteredGamesDataAtom={filteredTeamGamesAtom}
              selectedGamesAtom={teamSelectedGamesAtom}
            />
            <RadarFilterGameSummary
              gamesGroupedBySeasonAtom={teamGamesBySeasonAtom}
              selectedGamesAtom={teamSelectedGamesAtom}
            />
          </div>
        </Suspense>
      )}
    </div>
  );
};
