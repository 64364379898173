export const splitStringByCapitalLetter = (text: string) => text.split(/(?=[A-Z])/).join(' ');

export const convertToTitleCase = (string: string) => {
  if (string === '') {
    return '';
  }

  return string
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const getPageTitleText = (pathname: string) => {
  if (pathname === '') {
    return '';
  }
  const exceptions = ['preview'];
  const splitPaths = pathname.replace(/\/$/, '').split('/');
  const lastPath = splitPaths[splitPaths.length - 1];
  if (exceptions.includes(lastPath)) {
    const nextLastPath = splitPaths[splitPaths.length - 2];
    return nextLastPath;
  }
  return lastPath;
};

export const addSuffix = (text: string, suffix: null | string) => (!suffix ? text : `${text}-${suffix}`);

// make filenames safe for sensitive OSs - removing periods and apostrophes
export const escapeDownloadString = (str: string) => str.replace(/\.|'/g, '');
