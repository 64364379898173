import { Button, Modal, ModalFooter, ModalHeader } from '@statsbomb/kitbag-components';
import { PrimitiveAtom, useAtom, useAtomValue } from 'jotai';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PromiseAtom } from '@/types/atom';
import { DataTableColumn } from '@/types/table';
import { ColumnSelect } from './ColumnSelect';
import { ColumnSort } from './ColumnSort';
import { MobileTableColumnsEditorTabs } from './MobileTableColumnsEditorTabs';

export const EditTableColumnsModal = ({
  columnsAtom,
  visibleColumnsAtom,
  setShowEditColumnsModal,
  defaultColumns,
}: {
  columnsAtom: PromiseAtom<DataTableColumn[]>;
  visibleColumnsAtom: PrimitiveAtom<string[]>;
  setShowEditColumnsModal: Function;
  defaultColumns: string[];
}) => {
  const { t } = useTranslation(['table', 'general']);

  const columns = useAtomValue(columnsAtom);
  const [tableVisibleColumns, setTableVisibleColumns] = useAtom(visibleColumnsAtom);
  const [modalVisibleColumns, setModalVisibleColumns] = useState(tableVisibleColumns);

  const handleCancelModal = () => {
    setModalVisibleColumns(tableVisibleColumns);
    setShowEditColumnsModal(false);
  };

  const handleConfirmModal = () => {
    setTableVisibleColumns(modalVisibleColumns);
    setShowEditColumnsModal(false);
  };

  return (
    <Modal id="modal-edit-columns" isOpen onCancel={handleCancelModal} onConfirm={handleConfirmModal}>
      <ModalHeader id="modal-edit-columns">{t('editColumns')}</ModalHeader>
      <MobileTableColumnsEditorTabs />
      <div className="flex w-[320px] md:w-[684px] h-[420px]">
        <ColumnSelect
          columns={columns}
          visibleColumns={modalVisibleColumns}
          setVisibleColumns={setModalVisibleColumns}
        />
        <ColumnSort
          visibleColumns={modalVisibleColumns}
          setVisibleColumns={setModalVisibleColumns}
          defaultColumns={defaultColumns}
        />
      </div>
      <ModalFooter>
        <Button onClick={handleCancelModal} variant="ghost">
          {t('cancel', { ns: 'general' })}
        </Button>
        <Button onClick={handleConfirmModal} variant="primary" isDisabled={!modalVisibleColumns.length}>
          {t('apply', { ns: 'general' })}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
