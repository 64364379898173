import { LayoutWithFilters } from '@/components/layout/LayoutWithFilters';
import { TableScopeProvider } from '@/components/tables/TableScopeProvider';
import { Grid, Typography } from '@statsbomb/kitbag-components';
import { useTranslation } from 'react-i18next';
import { PlayerGameAggsTable } from '@/components/player/tables/PlayerGameAggsTable';
import { TablePagination } from '@/components/tables/TablePagination';
import { playerGameAggsCountAtom } from '@/atoms/queries/player/playerGameAggData';
import { PlayerGamesFilters } from '@/components/player/filters/PlayerGamesFilters';
import { SectionTopBar } from '@/components/sectionTopBar/SectionTopBar';
import { PageTitle } from '@/components/head/PageTitle';
import { useAtomValue } from 'jotai';
import { unwrappedSelectedPlayerNameAtom } from '@/atoms/player/info';
import { useEntityPageTitle } from '@/hooks/useEntityPageTitle';

export const PlayerGamesPage = () => {
  const { t } = useTranslation(['games', 'navigation']);
  const playerName = useAtomValue(unwrappedSelectedPlayerNameAtom);
  const pageTitle = useEntityPageTitle(t('secondary.games', { ns: 'navigation' }), playerName);

  return (
    <>
      <PageTitle>{pageTitle}</PageTitle>
      <LayoutWithFilters filters={<PlayerGamesFilters />}>
        <Grid>
          <Grid item>
            <TableScopeProvider>
              <SectionTopBar>
                <Typography variant="headingRegular">{t('latestPerformances')}</Typography>
              </SectionTopBar>
              <PlayerGameAggsTable />
              <TablePagination dataLengthAtom={playerGameAggsCountAtom} />
            </TableScopeProvider>
          </Grid>
        </Grid>
      </LayoutWithFilters>
    </>
  );
};
