import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { dashboardNameAtom, isRenameDashboardModalOpenAtom } from '@/atoms/dashboard/dashboard';
import { RESET } from 'jotai/utils';
import { dashboardConfigsAtom } from '@/atoms/queries/userConfigs';
import { Suspense } from 'react';
import { userConfigIdToRenameAtom } from '@/atoms/userConfigs';
import { usePatchUserConfig } from '@/hooks/usePatchUserConfig';
import { toastDataAtom } from '@/atoms/toast';
import { useTranslation } from 'react-i18next';
import { GenericSaveModal } from '../GenericSaveModal/GenericSaveModal';

const RenameDashboardModalWithData = () => {
  const { t } = useTranslation(['dashboard', 'general']);
  const dashboards = useAtomValue(dashboardConfigsAtom);
  const userConfigIdToRename = useAtomValue(userConfigIdToRenameAtom);
  const [dashboardName, setDashboardName] = useAtom(dashboardNameAtom);
  const [isRenameModalOpen, setIsRenameModalOpen] = useAtom(isRenameDashboardModalOpenAtom);
  const setToastData = useSetAtom(toastDataAtom);

  const patchUserConfig = usePatchUserConfig();

  // Prevent duplicated dashboard names.
  // If we're renaming, we're allowed to set the name to be the same as the dashboard that we're renaming.
  const otherDashboardNames = dashboards
    .filter(({ configId, definition }) => configId !== userConfigIdToRename && definition.name !== undefined)
    .map(({ definition }) => definition.name as string);

  const handleConfirm = async () => {
    const newDashboardName = dashboardName.trim();
    const { isSuccess } = await patchUserConfig({
      configId: userConfigIdToRename,
      configDefinition: { name: newDashboardName },
    });

    setToastData('renameDashboard', isSuccess, newDashboardName);

    setDashboardName(RESET);
    setIsRenameModalOpen(false);
  };

  const handleCancel = () => {
    setDashboardName(RESET);
    setIsRenameModalOpen(false);
  };

  return (
    isRenameModalOpen && (
      <GenericSaveModal
        id="rename-dashboard-modal"
        title={t('renameDashboard')}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        confirmLabel={t('save', { ns: 'general' })}
        value={dashboardName}
        onChange={setDashboardName}
        existingConfigNames={otherDashboardNames}
      />
    )
  );
};

export const RenameDashboardModal = () => {
  const isRenameModalOpen = useAtomValue(isRenameDashboardModalOpenAtom);

  return (
    isRenameModalOpen && (
      <Suspense>
        <RenameDashboardModalWithData />
      </Suspense>
    )
  );
};
