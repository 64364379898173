export const PLAYER_DATA_LOCKER_COLUMNS = [
  { formatRule: 'percentage', key: 'aggregates.aerialRatio', group: '' },
  { formatRule: 'float-2', key: 'aggregates.aerialsPer90', group: '' },
  { formatRule: 'float-2', key: 'aggregates.aggressiveActionsPer90', group: '' },
  { formatRule: 'float-2', key: 'aggregates.assistsPer90', group: '' },
  { formatRule: 'float-2', key: 'aggregates.aveCarryLength', group: '' },
  { formatRule: 'float-2', key: 'aggregates.aveDefensiveActionDistance', group: '' },
  { formatRule: 'float-2', key: 'aggregates.aveGkAggressiveDistance', group: '' },
  { formatRule: 'float-2', key: 'aggregates.avePassLength', group: '' },
  { formatRule: 'float-2', key: 'aggregates.avePressuredPassLength', group: '' },
  { formatRule: 'float-2', key: 'aggregates.aveSuccessfulPassLength', group: '' },
  { formatRule: 'float-2', key: 'aggregates.aveSuccessfulPressuredPassLength', group: '' },
  { formatRule: 'percentage', key: 'aggregates.backwardPassRatio', group: '' },
  { formatRule: 'float-2', key: 'aggregates.ballRecoveriesPer90', group: '' },
  { formatRule: 'float-2', key: 'aggregates.carriesPer90', group: '' },
  { formatRule: 'percentage', key: 'aggregates.challengeRatio', group: '' },
  { formatRule: 'float-2', key: 'aggregates.clearancesPer90', group: '' },
  { formatRule: 'float-2', key: 'aggregates.cornersPer90', group: '' },
  { formatRule: 'float-2', key: 'aggregates.counterpressuresPer90', group: '' },
  { formatRule: 'float-2', key: 'aggregates.crossesPer90', group: '' },
  { formatRule: 'percentage', key: 'aggregates.crossingRatio', group: '' },
  { formatRule: 'float-2', key: 'aggregates.d3PressuredPassesPer90', group: '' },
  { formatRule: 'float-2', key: 'aggregates.deepCompletionsPer90', group: '' },
  { formatRule: 'float-2', key: 'aggregates.deepProgressionsPer90', group: '' },
  { formatRule: 'float-2', key: 'aggregates.defensiveActionObvPer90', group: '' },
  { formatRule: 'float-2', key: 'aggregates.dispossessionsPer90', group: '' },
  { formatRule: 'float-2', key: 'aggregates.dribbleAndCarryObvPer90', group: '' },
  { formatRule: 'float-2', key: 'aggregates.dribblePastsPer90', group: '' },
  { formatRule: 'float-2', key: 'aggregates.dribblesPer90', group: '' },
  { formatRule: 'percentage', key: 'aggregates.dribblingRatio', group: '' },
  { formatRule: 'float-2', key: 'aggregates.errorsPer90', group: '' },
  { formatRule: 'float-2', key: 'aggregates.failedDribblesPer90', group: '' },
  { formatRule: 'float-2', key: 'aggregates.fhalfBallRecoveriesPer90', group: '' },
  { formatRule: 'percentage', key: 'aggregates.fhalfCounterpressRatio', group: '' },
  { formatRule: 'float-2', key: 'aggregates.fhalfCounterpressuresPer90', group: '' },
  { formatRule: 'percentage', key: 'aggregates.fhalfPressureRatio', group: '' },
  { formatRule: 'float-2', key: 'aggregates.fhalfPressuresPer90', group: '' },
  { formatRule: 'percentage', key: 'aggregates.forwardPassRatio', group: '' },
  { formatRule: 'float-2', key: 'aggregates.foulsPer90', group: '' },
  { formatRule: 'float-2', key: 'aggregates.foulsWonPer90', group: '' },
  { formatRule: 'float-2', key: 'aggregates.freeKicksPer90', group: '' },
  { formatRule: 'float-2', key: 'aggregates.freeKicksStartingInD3Per90', group: '' },
  { formatRule: 'float-2', key: 'aggregates.freeKicksStartingInF3Per90', group: '' },
  { formatRule: 'float-2', key: 'aggregates.freeKicksStartingInM3Per90', group: '' },
  { formatRule: 'percentage', key: 'aggregates.goalConversionRatio', group: '' },
  { formatRule: 'float-2', key: 'aggregates.goalKicksPer90', group: '' },
  { formatRule: 'float-2', key: 'aggregates.goalkeeperObvPer90', group: '' },
  { formatRule: 'float-2', key: 'aggregates.goalsAndOwnGoalsConcededPer90', group: '' },
  { formatRule: 'float-2', key: 'aggregates.goalsPer90', group: '' },
  { formatRule: 'float-2', key: 'aggregates.gsaaPer90', group: '' },
  { formatRule: 'percentage', key: 'aggregates.gsaaRatio', group: '' },
  { formatRule: 'float-2', key: 'aggregates.interceptionsPer90', group: '' },
  { formatRule: 'float-2', key: 'aggregates.keyPassesPer90', group: '' },
  { formatRule: 'percentage', key: 'aggregates.leftToRightPassRatio', group: '' },
  { formatRule: 'float-2', key: 'aggregates.longGoalKicksPer90', group: '' },
  { formatRule: 'float-2', key: 'aggregates.longPassesPer90', group: '' },
  { formatRule: 'percentage', key: 'aggregates.longPassingRatio', group: '' },
  { formatRule: 'integer', key: 'aggregates.minutesPlayed', canDrillDown: false, group: '' },
  { formatRule: 'float-2', key: 'aggregates.nonAerialDuelsPer90', group: '' },
  { formatRule: 'float-2', key: 'aggregates.nonThrowInKeyPassesPer90', group: '' },
  { formatRule: 'float-2', key: 'aggregates.nonThrowInPassesIntoF3Per90', group: '' },
  { formatRule: 'float-2', key: 'aggregates.nonThrowInPassesPer90', group: '' },
  { formatRule: 'float-2', key: 'aggregates.nonThrowInPassesStartingInD3Per90', group: '' },
  { formatRule: 'float-2', key: 'aggregates.nonThrowInThroughBallsPer90', group: '' },
  { formatRule: 'float-2', key: 'aggregates.npGoalsConcededPer90', group: '' },
  { formatRule: 'float-2', key: 'aggregates.npGoalsPer90', group: '' },
  { formatRule: 'float-2', key: 'aggregates.npPostShotXgConcededPer90', group: '' },
  { formatRule: 'float-2', key: 'aggregates.npPostShotXgPer90', group: '' },
  { formatRule: 'percentage', key: 'aggregates.npSaveRatio', group: '' },
  { formatRule: 'float-2', key: 'aggregates.npShotsConcededPer90', group: '' },
  { formatRule: 'float-2', key: 'aggregates.npShotsOnTargetConcededPer90', group: '' },
  { formatRule: 'percentage', key: 'aggregates.npShotsOnTargetConcededRatio', group: '' },
  { formatRule: 'float-2', key: 'aggregates.npShotsPer90', group: '' },
  { formatRule: 'float-2', key: 'aggregates.npXgConcededPer90', group: '' },
  { formatRule: 'float-2', key: 'aggregates.npXgPer90', group: '' },
  { formatRule: 'float-2', key: 'aggregates.npXgPerShot', group: '' },
  { formatRule: 'float-2', key: 'aggregates.obvPer90', group: '' },
  { formatRule: 'float-2', key: 'aggregates.opAssistsPer90', group: '' },
  { formatRule: 'percentage', key: 'aggregates.opF3BackwardPassRatio', group: '' },
  { formatRule: 'percentage', key: 'aggregates.opF3ForwardPassRatio', group: '' },
  { formatRule: 'float-2', key: 'aggregates.opF3PassesPer90', group: '' },
  { formatRule: 'percentage', key: 'aggregates.opF3SidewaysPassRatio', group: '' },
  { formatRule: 'float-2', key: 'aggregates.opKeyPassesPer90', group: '' },
  { formatRule: 'float-2', key: 'aggregates.opPassesIntoBoxPer90', group: '' },
  { formatRule: 'float-2', key: 'aggregates.opPassesPer90', group: '' },
  { formatRule: 'float-2', key: 'aggregates.opShotsPer90', group: '' },
  { formatRule: 'float-2', key: 'aggregates.opXaPer90', group: '' },
  { formatRule: 'float-2', key: 'aggregates.pGoalsConcededPer90', group: '' },
  { formatRule: 'float-2', key: 'aggregates.passObvPer90', group: '' },
  { formatRule: 'float-2', key: 'aggregates.passesInsideBoxPer90', group: '' },
  { formatRule: 'float-2', key: 'aggregates.passesIntoBoxPer90', group: '' },
  { formatRule: 'float-2', key: 'aggregates.passesPer90', group: '' },
  { formatRule: 'percentage', key: 'aggregates.passesPressedRatio', group: '' },
  { formatRule: 'percentage', key: 'aggregates.passingRatio', group: '' },
  { formatRule: 'float-2', key: 'aggregates.penaltiesWonPer90', group: '' },
  { formatRule: 'percentage', key: 'aggregates.penaltyConversionRatio', group: '' },
  { formatRule: 'float-2', key: 'aggregates.penaltyShotsConcededPer90', group: '' },
  { formatRule: 'float-2', key: 'aggregates.penaltyShotsPer90', group: '' },
  { formatRule: 'float-2', key: 'aggregates.pressuredLongPassesPer90', group: '' },
  { formatRule: 'percentage', key: 'aggregates.pressuredPassingRatio', group: '' },
  { formatRule: 'float-2', key: 'aggregates.pressuresPer90', group: '' },
  { formatRule: 'float-2', key: 'aggregates.receivedPassesPer90', group: '' },
  { formatRule: 'float-2', key: 'aggregates.redCardsPer90', group: '' },
  { formatRule: 'float-2', key: 'aggregates.savesPer90', group: '' },
  { formatRule: 'float-2', key: 'aggregates.secondYellowCardsPer90', group: '' },
  { formatRule: 'percentage', key: 'aggregates.shootingRatio', group: '' },
  { formatRule: 'float-2', key: 'aggregates.shortGoalKicksPer90', group: '' },
  { formatRule: 'float-2', key: 'aggregates.shotBlocksPerNpShotConceded', group: '' },
  { formatRule: 'float-2', key: 'aggregates.shotObvPer90', group: '' },
  { formatRule: 'percentage', key: 'aggregates.shotTouchRatio', group: '' },
  { formatRule: 'float-2', key: 'aggregates.shotsConcededPer90', group: '' },
  { formatRule: 'float-2', key: 'aggregates.shotsOutsideBoxConcededPer90', group: '' },
  { formatRule: 'float-2', key: 'aggregates.shotsOutsideBoxPer90', group: '' },
  { formatRule: 'float-2', key: 'aggregates.shotsPer90', group: '' },
  { formatRule: 'percentage', key: 'aggregates.sidewaysPassRatio', group: '' },
  { formatRule: 'float-2', key: 'aggregates.spAssistsPer90', group: '' },
  { formatRule: 'float-2', key: 'aggregates.spKeyPassesPer90', group: '' },
  { formatRule: 'float-2', key: 'aggregates.spPassesIntoBoxPer90', group: '' },
  { formatRule: 'float-2', key: 'aggregates.spXaPer90', group: '' },
  { formatRule: 'float-2', key: 'aggregates.successfulAerialsPer90', group: '' },
  { formatRule: 'percentage', key: 'aggregates.successfulBoxCrossRatio', group: '' },
  { formatRule: 'float-2', key: 'aggregates.successfulCrossesPer90', group: '' },
  { formatRule: 'float-2', key: 'aggregates.successfulDribblesPer90', group: '' },
  { formatRule: 'float-2', key: 'aggregates.successfulPassesPer90', group: '' },
  { formatRule: 'float-2', key: 'aggregates.tacklesAndInterceptionsPer90', group: '' },
  { formatRule: 'float-2', key: 'aggregates.tacklesPer90', group: '' },
  { formatRule: 'float-2', key: 'aggregates.throughBallsPer90', group: '' },
  { formatRule: 'float-2', key: 'aggregates.throwInsPer90', group: '' },
  { formatRule: 'float-2', key: 'aggregates.throwInsStartingInD3Per90', group: '' },
  { formatRule: 'float-2', key: 'aggregates.throwInsStartingInF3Per90', group: '' },
  { formatRule: 'float-2', key: 'aggregates.throwInsStartingInM3Per90', group: '' },
  { formatRule: 'float-2', key: 'aggregates.touchesInsideBoxPer90', group: '' },
  { formatRule: 'float-2', key: 'aggregates.touchesPer90', group: '' },
  { formatRule: 'float-2', key: 'aggregates.turnoversPer90', group: '' },
  { formatRule: 'float-2', key: 'aggregates.unpressuredLongPassesPer90', group: '' },
  { formatRule: 'float-2', key: 'aggregates.xaPer90', group: '' },
  { formatRule: 'float-2', key: 'aggregates.xgPer90', group: '' },
  { formatRule: 'float-2', key: 'aggregates.yellowCardsPer90', group: '' },
  { formatRule: 'integer', key: 'player.age', canDrillDown: false, group: '' },
  { formatRule: 'integer', key: 'player.height', canDrillDown: false, group: '' },
];
