import { Icon, Typography } from '@statsbomb/kitbag-components';
import { ReactNode } from 'react';

/* TODO (RC-74: The current kitbag alert does not look like the designs when the ticket to update it is done we can remove this and use the Alert from kitbag) */
const Alert = ({ children }: { children: ReactNode }) => (
  <div className="border-2 border-l-8 border-messaging-info-main py-2 px-2">{children}</div>
);

export const InfoBar = ({
  testId,
  mainText,
  additionalText,
}: {
  testId: string;
  mainText: string;
  additionalText?: string;
}) => (
  <Alert>
    <div className="flex items-center gap-x-5 bg-transparent [&>svg]:text-messaging-info-main leading-3">
      <Icon variant="Info" />
      <div data-testid={testId}>
        <Typography as="span" variant="bodyMedium" className="flex items-center">
          {mainText}
        </Typography>
        {additionalText && (
          <Typography as="span" variant="headingSmall">
            {`${additionalText}`}
          </Typography>
        )}
      </div>
    </div>
  </Alert>
);
