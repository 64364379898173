import { useAtomValue } from 'jotai';
import { Suspense } from 'react';
import { tableColumnsAtom } from '@/atoms/queries/tableColumns';
import { teamEventsForMetricAtom } from '@/atoms/queries/team/teamEventData';
import { PreviewEventsBase } from '@/components/tables/PreviewEvents';

const TeamPreviewEventsWithData = () => {
  const eventsForMetric = useAtomValue(teamEventsForMetricAtom);
  const eventDataTableColumns = useAtomValue(tableColumnsAtom);

  return <PreviewEventsBase eventsForMetric={eventsForMetric} eventDataTableColumns={eventDataTableColumns} />;
};

export const TeamPreviewEvents = () => (
  <Suspense fallback={<PreviewEventsBase isLoading />}>
    <TeamPreviewEventsWithData />
  </Suspense>
);
