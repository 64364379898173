import { eventsDataColumnsAtom, eventsDataVisibleColumnsAtom } from '@/atoms/dataLocker/events/table';
import { useAtomValue } from 'jotai';
import { Suspense } from 'react';
import { ErrorBoundary } from '@sentry/react';
import { GeneralErrorBoundaryFallback } from '@/components/errorBoundary/ErrorBoundaryFallback';
import { useVisDisplayMode } from '@/hooks/useVisDisplayMode';
import { VisDisplayModeSwitcher } from '@/components/visualisations/VisDisplayModeSwitcher';
import { VisDisplayMode } from '@/types/visualisation';
import { EditColumnsButton } from '@/components/button/EditColumnsButton';
import { Typography } from '@statsbomb/kitbag-components';
import { useTranslation } from 'react-i18next';
import { EVENTS_DATA_LOCKER_DEFAULT_COLUMNS } from '@/consts/dataLocker/events';
import { SectionTopBar } from '../sectionTopBar/SectionTopBar';

export const EventDataControlsBase = ({ disabled }: { disabled?: boolean }) => {
  const { t } = useTranslation('visualisation');
  const [visDisplayMode, setVisDisplayMode] = useVisDisplayMode(VisDisplayMode.TABLE);

  return (
    <ErrorBoundary fallback={<GeneralErrorBoundaryFallback />}>
      <SectionTopBar>
        <Typography variant="headingRegular">{t(`viewing.${visDisplayMode}`)}</Typography>
        <div className="ml-auto flex flex-row gap-2">
          <EditColumnsButton
            disabled={disabled}
            columnsAtom={eventsDataColumnsAtom}
            visibleColumnsAtom={eventsDataVisibleColumnsAtom}
            defaultColumns={EVENTS_DATA_LOCKER_DEFAULT_COLUMNS}
          />
          <VisDisplayModeSwitcher displayMode={visDisplayMode} handleDisplayMode={setVisDisplayMode} />
        </div>
      </SectionTopBar>
    </ErrorBoundary>
  );
};

const EventDataControlsWithData = () => {
  // Referencing this atom here as this precaches the data we want to show in the modal
  // and prevents it from rendering without any data initially. Until this is loaded,
  // we'll fall back to the disabled button.
  useAtomValue(eventsDataColumnsAtom);
  return <EventDataControlsBase />;
};

export const EventDataControls = () => (
  <Suspense fallback={<EventDataControlsBase disabled />}>
    <EventDataControlsWithData />
  </Suspense>
);
