import { objSnakeToCamel, snakeToCamel } from '@/utils/queries';
import { TableColumn } from '@statsbomb/parachute-types';
import { atom } from 'jotai';
import { atomWithSuspenseQuery } from 'jotai-tanstack-query';
import { fetchClientAtom } from '@/atoms/queries/client';

const rawTableColumnsAtom = atomWithSuspenseQuery(get => ({
  queryKey: ['table-columns'],
  queryFn: async () => {
    const { fetch } = get(fetchClientAtom);
    return (await fetch('/table-columns')) as Promise<TableColumn[]>;
  },
  refetchOnMount: false,
}));

export const tableColumnsAtom = atom(async get => {
  const rawEventData = await get(rawTableColumnsAtom);
  const data = rawEventData.data || [];

  return data.map(dataItem =>
    objSnakeToCamel({
      ...dataItem,
      key: snakeToCamel(dataItem.key),
      // adding group causes all of the columns to be considered part of the same group while not displaying any header for that group
      // TODO (PPC-1367: replace with a proper column group once those are added to the api)
      group: '',
    }),
  );
});
