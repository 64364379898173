import { teamPitchVisComponents } from '@/consts/visualisationComponents';
import { teamEventsForMetricCountAtom } from '@/atoms/queries/team/teamEventData';
import { EntityPreview } from '@/components/preview/EntityPreview';
import { TeamPreviewEvents } from '@/components/team/radar/preview/TeamPreviewEvents';

export const TeamPreview = () => (
  <EntityPreview
    eventsForMetricCountAtom={teamEventsForMetricCountAtom}
    visualisationComponents={teamPitchVisComponents}
  >
    <TeamPreviewEvents />
  </EntityPreview>
);

export default TeamPreview;
