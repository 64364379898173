import { Nullable } from '@/types/generic';
import { SaveToDashboardMenuItemProps } from '@/types/widget';
import { atom } from 'jotai';
import { atomWithReset } from 'jotai/utils';

export const isCreateDashboardModalOpenAtom = atom(false);
export const isRenameDashboardModalOpenAtom = atom(false);

export const dashboardNameAtom = atomWithReset('');
export const dashboardIdToDeleteAtom = atomWithReset<Nullable<string>>(null);

/* this defaults to undefined as it gets set by a useParams call for dashboardId which can be undefined */
export const selectedDashboardIdAtom = atom<string | undefined>(undefined);
export const isSaveWidgetToDashboardModalOpenAtom = atom(false);

export const saveWidgetToDashboardDataAtom = atomWithReset<SaveToDashboardMenuItemProps>({
  visType: 'radar',
  dataType: 'aggregate',
});
