import { ButtonIcon, Typography } from '@statsbomb/kitbag-components';
import { useTranslation } from 'react-i18next';
import { useSetAtom } from 'jotai';
import { isSettingsPanelOpenAtom } from '@/atoms/sidebar';
import { PitchOrientationToggle } from '../visualisations/PitchVisualisation/PitchOrientationToggle';
import { PitchCropOptions } from '../visualisations/PitchVisualisation/PitchCropOptions';
import { PitchMarkerToggle } from '../visualisations/PitchVisualisation/PitchMarkerToggle';
import { VisualisationToggle } from '../visualisations/VisualisationToggle';
import { PitchMarkerPlacementToggle } from '../visualisations/PitchVisualisation/PitchMarkerPlacementToggle';

export const SettingsPanel = () => {
  const { t } = useTranslation(['general', 'visualisation']);
  const setIsSettingsPanelOpen = useSetAtom(isSettingsPanelOpenAtom);

  return (
    <aside
      data-testid="settings-panel"
      className="md:slide-in-on-load bg-canvas-tertiary-main w-full h-full md:absolute md:top-0 md:left-0 overflow-y-auto md:z-10 md:p-4"
    >
      <div className="flex justify-between border-b border-opacity-50 border-canvas-primary-main items-center mb-4 pb-4 md:px-4 md:-mx-4">
        <Typography className="flex h-8 items-center" variant="headingRegular" as="h1">
          {t('displaySettings', { ns: 'visualisation' })}
        </Typography>
        <ButtonIcon
          variant="ghost"
          onClick={() => {
            setIsSettingsPanelOpen(false);
          }}
          icon="Close"
          title={t('close')}
        />
      </div>
      <div className="flex flex-col gap-5 w-max">
        <VisualisationToggle />
        <PitchMarkerToggle />
        <PitchMarkerPlacementToggle />
        <PitchOrientationToggle />
        <PitchCropOptions />
      </div>
    </aside>
  );
};
