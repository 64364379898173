/* istanbul ignore file */
import {
  dashboardEditModeAtom,
  dashboardToSaveAtom,
  dashboardWidgetsToRenderAtom,
} from '@/atoms/dashboard/dashboardSaving';
import { dashboardConfigsAtom } from '@/atoms/queries/userConfigs';
import { toastDataAtom } from '@/atoms/toast';
import { EntityTopBarBase } from '@/components/topBar/EntityTopBar';
import { useUpsertUserConfig } from '@/hooks/useUpsertUserConfig';
import { noop } from '@/utils/noop';
import { Badge, Button } from '@statsbomb/kitbag-components';
import classNames from 'classnames';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { Suspense, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface DashboardTopBarProps {
  onCancel: () => void;
  onSave: () => void;
  handleEditMode: () => void;
  isSavePending: boolean;
}

const CustomiseDashboardButton = ({ handleEditMode }: { handleEditMode: () => void }) => {
  const { t } = useTranslation('dashboard');
  const savedDashboard = useAtomValue(dashboardWidgetsToRenderAtom);

  return (
    <Button size="small" variant="secondary" isDisabled={!savedDashboard.length} onClick={handleEditMode}>
      {t('customise')}
    </Button>
  );
};

export const DashboardTopBar = ({ onCancel, onSave, handleEditMode, isSavePending }: DashboardTopBarProps) => {
  const { t } = useTranslation(['navigation', 'dashboard', 'general']);

  const dashboardEditMode = useAtomValue(dashboardEditModeAtom);
  const dashboardToSave = useAtomValue(dashboardToSaveAtom);
  const hasUnsavedChanges = !!dashboardToSave;
  const topBarTitle = dashboardEditMode ? t('editModeTitle', { ns: 'dashboard' }) : t('primary.dashboard');

  return (
    <EntityTopBarBase entityName={topBarTitle}>
      <div className={classNames('flex flex-1 px-2', dashboardEditMode ? 'justify-end' : 'justify-between')}>
        {!dashboardEditMode ? (
          <Suspense>
            <CustomiseDashboardButton handleEditMode={handleEditMode} />
          </Suspense>
        ) : (
          <div className="flex gap-3">
            {hasUnsavedChanges && (
              /* TODO (KC-203: Chip with icon) - this badge is temp until the KC ticket is done */
              <Badge type="warning">
                <span className="text-xs">{t('unsavedChanges', { ns: 'dashboard' })}</span>
              </Badge>
            )}
            {/* TODO (KC-108: test ids) - replace aria labels with test ids */}
            <Button size="small" onClick={onCancel} variant="ghost" ariaLabel="discard changes">
              {t('cancel', { ns: 'general' })}
            </Button>
            <Button
              size="small"
              onClick={onSave}
              isDisabled={!hasUnsavedChanges}
              isPending={isSavePending}
              ariaLabel="save changes"
            >
              {t('save', { ns: 'general' })}
            </Button>
          </div>
        )}
      </div>
    </EntityTopBarBase>
  );
};

export const DashboardTopBarFallback = () => (
  <DashboardTopBar handleEditMode={noop} isSavePending={false} onCancel={noop} onSave={noop} />
);

export const DashboardTopBarWithToast = ({ handleCancelEdit }: { handleCancelEdit: () => void }) => {
  const dashboardConfigs = useAtomValue(dashboardConfigsAtom);
  const [dashboardToSave, setDashboardToSave] = useAtom(dashboardToSaveAtom);
  const upsertUserConfig = useUpsertUserConfig();
  const setEditMode = useSetAtom(dashboardEditModeAtom);
  const [isSavePending, setIsSavePending] = useState(false);
  const setToastData = useSetAtom(toastDataAtom);

  const handleSaveEdit = async () => {
    setIsSavePending(true);
    const { isSuccess } = await upsertUserConfig({
      configId: dashboardConfigs[0]?.configId,
      configType: 'dashboard',
      configDefinition: { widgets: dashboardToSave },
    });

    if (isSuccess) {
      setDashboardToSave(null);
      setEditMode(false);
    }
    setIsSavePending(false);

    setToastData('saveDashboard', isSuccess);
  };

  return (
    <DashboardTopBar
      isSavePending={isSavePending}
      onCancel={handleCancelEdit}
      onSave={handleSaveEdit}
      handleEditMode={() => setEditMode(true)}
    />
  );
};
