/* TODO (PPC-853: Add correct metric columns in ui) */
export const PERMANENT_COLUMNS = [
  { formatRule: 'string', key: 'game.name' },
  { formatRule: 'string', key: 'game.date' },
  { formatRule: 'string', key: 'competition.name' },
] as const;

const MINUTES_PLAYED_COLUMN = { formatRule: 'integer', key: 'aggregates.minutesPlayed' } as const;

export const PLAYER_MATCHES_COLUMNS = [
  MINUTES_PLAYED_COLUMN,
  { formatRule: 'integer', key: 'aggregates.goals' },
  { formatRule: 'integer', key: 'aggregates.shots' },
  { formatRule: 'integer', key: 'aggregates.shotsOutsideBox' },
  { formatRule: 'integer', key: 'aggregates.nonThrowInPasses' },
  { formatRule: 'integer', key: 'aggregates.dribbles' },
  { formatRule: 'integer', key: 'aggregates.aerials' },
  { formatRule: 'integer', key: 'aggregates.nonAerialDuels' },
  { formatRule: 'integer', key: 'aggregates.pressures' },
  { formatRule: 'integer', key: 'aggregates.counterpressures' },
  { formatRule: 'integer', key: 'aggregates.nonThrowInPassesIntoF3' },
  { formatRule: 'integer', key: 'aggregates.nonThrowInThroughBalls' },
  { formatRule: 'integer', key: 'aggregates.nonThrowInKeyPasses' },
  { formatRule: 'integer', key: 'aggregates.crosses' },
  { formatRule: 'integer', key: 'aggregates.nonThrowInPassesStartingInD3' },
  { formatRule: 'integer', key: 'aggregates.d3PressuredPasses' },
  { formatRule: 'integer', key: 'aggregates.receivedPasses' },
  { formatRule: 'integer', key: 'aggregates.touchesInsideBox' },
  { formatRule: 'integer', key: 'aggregates.tackles' },
  { formatRule: 'integer', key: 'aggregates.interceptions' },
  { formatRule: 'integer', key: 'aggregates.shotBlocks' },
  { formatRule: 'integer', key: 'aggregates.dribblePasts' },
  { formatRule: 'integer', key: 'aggregates.ballRecoveries' },
  { formatRule: 'integer', key: 'aggregates.clearances' },
  { formatRule: 'integer', key: 'aggregates.fhalfPressures' },
  { formatRule: 'integer', key: 'aggregates.fouls' },
  { formatRule: 'integer', key: 'aggregates.foulsWon' },
  { formatRule: 'integer', key: 'aggregates.freeKicks' },
  { formatRule: 'integer', key: 'aggregates.corners' },
  { formatRule: 'integer', key: 'aggregates.penaltyShots' },
  { formatRule: 'integer', key: 'aggregates.throwIns' },
] as const;

export const GOALKEEPER_MATCHES_COLUMNS = [
  MINUTES_PLAYED_COLUMN,
  { formatRule: 'float-2', key: 'aggregates.gsaa' },
  { formatRule: 'integer', key: 'aggregates.saves' },
  { formatRule: 'integer', key: 'aggregates.goalsAndOwnGoalsConceded' },
  { formatRule: 'integer', key: 'aggregates.nonThrowInPasses' },
  { formatRule: 'integer', key: 'aggregates.shotsConceded' },
  { formatRule: 'integer', key: 'aggregates.shotsOutsideBoxConceded' },
  { formatRule: 'integer', key: 'aggregates.goalKicks' },
  { formatRule: 'integer', key: 'aggregates.shortGoalKicks' },
  { formatRule: 'integer', key: 'aggregates.longGoalKicks' },
  { formatRule: 'integer', key: 'aggregates.penaltyShotsConceded' },
  { formatRule: 'integer', key: 'aggregates.fouls' },
] as const;

export const NO_VIDEO_COLUMNS = ['aggregates.minutesPlayed'];

export const TEAM_MATCHES_COLUMNS = [
  { formatRule: 'float-2', key: 'aggregates.cumulativeXg' },
  { formatRule: 'integer', key: 'aggregates.goals' },
  { formatRule: 'integer', key: 'aggregates.shots' },
  { formatRule: 'integer', key: 'aggregates.shotsOutsideBox' },
  { formatRule: 'integer', key: 'aggregates.nonThrowInPassesIntoF3' },
  { formatRule: 'integer', key: 'aggregates.nonThrowInPassesStartingInD3' },
  { formatRule: 'integer', key: 'aggregates.nonThrowInThroughBalls' },
  { formatRule: 'integer', key: 'aggregates.nonThrowInKeyPasses' },
  { formatRule: 'integer', key: 'aggregates.crosses' },
  { formatRule: 'integer', key: 'aggregates.corners' },
  { formatRule: 'integer', key: 'aggregates.goalKicks' },
  { formatRule: 'integer', key: 'aggregates.shortGoalKicks' },
  { formatRule: 'integer', key: 'aggregates.longGoalKicks' },
  { formatRule: 'integer', key: 'aggregates.pressures' },
  { formatRule: 'integer', key: 'aggregates.fhalfPressures' },
  { formatRule: 'integer', key: 'aggregates.d3PressuredPasses' },
  { formatRule: 'integer', key: 'aggregates.counterpressures' },
  { formatRule: 'integer', key: 'aggregates.freeKicksStartingInF3' },
  { formatRule: 'integer', key: 'aggregates.freeKicksStartingInM3' },
  { formatRule: 'integer', key: 'aggregates.freeKicksStartingInD3' },
  { formatRule: 'integer', key: 'aggregates.aerials' },
  { formatRule: 'integer', key: 'aggregates.nonAerialDuels' },
  { formatRule: 'integer', key: 'aggregates.penaltyShots' },
  { formatRule: 'integer', key: 'aggregates.throwIns' },
  { formatRule: 'integer', key: 'aggregates.freeKicks' },
  { formatRule: 'integer', key: 'aggregates.tackles' },
  { formatRule: 'integer', key: 'aggregates.interceptions' },
  { formatRule: 'integer', key: 'aggregates.shotBlocks' },
  { formatRule: 'integer', key: 'aggregates.ballRecoveries' },
  { formatRule: 'integer', key: 'aggregates.clearances' },
  { formatRule: 'integer', key: 'aggregates.fouls' },
  { formatRule: 'integer', key: 'aggregates.foulsWon' },
  { formatRule: 'integer', key: 'aggregates.dribbles' },
  { formatRule: 'integer', key: 'aggregates.dribblePasts' },
  { formatRule: 'integer', key: 'aggregates.touchesInsideBox' },
  { formatRule: 'integer', key: 'aggregates.throwInsStartingInF3' },
  { formatRule: 'integer', key: 'aggregates.throwInsStartingInM3' },
  { formatRule: 'integer', key: 'aggregates.throwInsStartingInD3' },
] as const;
