import { isDarkModeAtom } from '@/atoms/theme';
import { POSITION_COLOUR_GROUPS } from '@/consts/filters/positionsUIcolours';
import { Position } from '@/types/player';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';

export const usePositionUIElements = () => {
  const { t } = useTranslation('events');
  const isDarkMode = useAtomValue(isDarkModeAtom);
  const colourKey = isDarkMode ? 'dark' : 'light';

  const positionItemsConfig = [
    {
      key: Position.GK,
      label: t(`fields.event.attributes.playerPosition.${Position.GK}`),
      colour: POSITION_COLOUR_GROUPS.gk[colourKey],
      cx: 10,
      cy: 40,
    },
    {
      key: Position.LB,
      label: t(`fields.event.attributes.playerPosition.${Position.LB}`),
      colour: POSITION_COLOUR_GROUPS.fullBack[colourKey],
      cx: 30,
      cy: 10,
    },
    {
      key: Position.LCB,
      label: t(`fields.event.attributes.playerPosition.${Position.LCB}`),
      colour: POSITION_COLOUR_GROUPS.centreBack[colourKey],
      cx: 30,
      cy: 25.5,
    },
    {
      key: Position.CB,
      label: t(`fields.event.attributes.playerPosition.${Position.CB}`),
      colour: POSITION_COLOUR_GROUPS.centreBack[colourKey],
      cx: 30,
      cy: 40,
    },
    {
      key: Position.RCB,
      label: t(`fields.event.attributes.playerPosition.${Position.RCB}`),
      colour: POSITION_COLOUR_GROUPS.centreBack[colourKey],
      cx: 30,
      cy: 54.5,
    },
    {
      key: Position.RB,
      label: t(`fields.event.attributes.playerPosition.${Position.RB}`),
      colour: POSITION_COLOUR_GROUPS.fullBack[colourKey],
      cx: 30,
      cy: 70,
    },
    {
      key: Position.LWB,
      label: t(`fields.event.attributes.playerPosition.${Position.LWB}`),
      colour: POSITION_COLOUR_GROUPS.fullBack[colourKey],
      cx: 50,
      cy: 10,
    },
    {
      key: Position.LDM,
      label: t(`fields.event.attributes.playerPosition.${Position.LDM}`),
      colour: POSITION_COLOUR_GROUPS.defensiveMidfielder[colourKey],
      cx: 50,
      cy: 25.5,
    },
    {
      key: Position.CDM,
      label: t(`fields.event.attributes.playerPosition.${Position.CDM}`),
      colour: POSITION_COLOUR_GROUPS.defensiveMidfielder[colourKey],
      cx: 50,
      cy: 40,
    },
    {
      key: Position.RDM,
      label: t(`fields.event.attributes.playerPosition.${Position.RDM}`),
      colour: POSITION_COLOUR_GROUPS.defensiveMidfielder[colourKey],
      cx: 50,
      cy: 54.5,
    },
    {
      key: Position.RWB,
      label: t(`fields.event.attributes.playerPosition.${Position.RWB}`),
      colour: POSITION_COLOUR_GROUPS.fullBack[colourKey],
      cx: 50,
      cy: 70,
    },
    {
      key: Position.LM,
      label: t(`fields.event.attributes.playerPosition.${Position.LM}`),
      colour: POSITION_COLOUR_GROUPS.widePlayer[colourKey],
      cx: 70,
      cy: 10,
    },
    {
      key: Position.LCM,
      label: t(`fields.event.attributes.playerPosition.${Position.LCM}`),
      colour: POSITION_COLOUR_GROUPS.centreMidfielder[colourKey],
      cx: 70,
      cy: 25.5,
    },
    {
      key: Position.CM,
      label: t(`fields.event.attributes.playerPosition.${Position.CM}`),
      colour: POSITION_COLOUR_GROUPS.centreMidfielder[colourKey],
      cx: 70,
      cy: 40,
    },
    {
      key: Position.RCM,
      label: t(`fields.event.attributes.playerPosition.${Position.RCM}`),
      colour: POSITION_COLOUR_GROUPS.centreMidfielder[colourKey],
      cx: 70,
      cy: 54.5,
    },
    {
      key: Position.RM,
      label: t(`fields.event.attributes.playerPosition.${Position.RM}`),
      colour: POSITION_COLOUR_GROUPS.widePlayer[colourKey],
      cx: 70,
      cy: 70,
    },
    {
      key: Position.LW,
      label: t(`fields.event.attributes.playerPosition.${Position.LW}`),
      colour: POSITION_COLOUR_GROUPS.widePlayer[colourKey],
      cx: 90,
      cy: 10,
    },
    {
      key: Position.LAM,
      label: t(`fields.event.attributes.playerPosition.${Position.LAM}`),
      colour: POSITION_COLOUR_GROUPS.attackingMidfielder[colourKey],
      cx: 90,
      cy: 25.5,
    },
    {
      key: Position.CAM,
      label: t(`fields.event.attributes.playerPosition.${Position.CAM}`),
      colour: POSITION_COLOUR_GROUPS.attackingMidfielder[colourKey],
      cx: 90,
      cy: 40,
    },
    {
      key: Position.RAM,
      label: t(`fields.event.attributes.playerPosition.${Position.RAM}`),
      colour: POSITION_COLOUR_GROUPS.attackingMidfielder[colourKey],
      cx: 90,
      cy: 54.5,
    },
    {
      key: Position.RW,
      label: t(`fields.event.attributes.playerPosition.${Position.RW}`),
      colour: POSITION_COLOUR_GROUPS.widePlayer[colourKey],
      cx: 90,
      cy: 70,
    },
    {
      key: Position.LCF,
      label: t(`fields.event.attributes.playerPosition.${Position.LCF}`),
      colour: POSITION_COLOUR_GROUPS.forward[colourKey],
      cx: 110,
      cy: 25.5,
    },
    {
      key: Position.CF,
      label: t(`fields.event.attributes.playerPosition.${Position.CF}`),
      colour: POSITION_COLOUR_GROUPS.forward[colourKey],
      cx: 110,
      cy: 40,
    },
    {
      key: Position.RCF,
      label: t(`fields.event.attributes.playerPosition.${Position.RCF}`),
      colour: POSITION_COLOUR_GROUPS.forward[colourKey],
      cx: 110,
      cy: 54.5,
    },
  ];

  return positionItemsConfig;
};
