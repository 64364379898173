import { ScopeProvider } from 'jotai-scope';
import { ReactNode } from 'react';
import { widgetIdAtom } from '@/atoms/dashboard/widget';
import { selectedBodyPartsAtom, selectedKeeperActionsAtom, selectedPassHeightAtom } from '@/atoms/eventData';
import { selectedGameTypeAtom } from '@/atoms/filters/gameMetrics';
import {
  deselectedGamesAtom,
  selectedEndDateAtom,
  selectedGameRangeAtom,
  selectedPeriodRangeAtom,
  selectedStartDateAtom,
} from '@/atoms/filters/highLevel';
import { selectedCompetitionIdsAtom } from '@/atoms/filters/highLevel/competitions';
import { selectedPitchFilterEndZonesAtom, selectedPitchFilterStartZonesAtom } from '@/atoms/filters/pitchFilter';
import { metricKeyAtom } from '@/atoms/metric';
import { selectedTeamIdsAtom } from '@/atoms/filters/highLevel/teams';
import { selectedSeasonIdsAtom } from '@/atoms/filters/highLevel/seasons';
import { playerIdAtom } from '@/atoms/player/player';
import { renderedRadarDataAtom, selectedRadarTemplateIdAtom } from '@/atoms/radar';
import { teamIdAtom } from '@/atoms/team/team';
import { selectedVisEventsAtom } from '@/atoms/vis/selection';
import { pitchCropAtom, pitchOrientationDashboardWidgetAtom, showArrowsAtom } from '@/atoms/visualisation';
import { sectionAtom } from '@/atoms/general';
import { selectedPlayerIdsAtom } from '@/atoms/filters/highLevel/players';
import { selectedPositionsAtom } from '@/atoms/filters/highLevel/positions';
import { selectedEventNamesAtom, selectedOutcomesAtom } from '@/atoms/filters/events';
import { widgetGenderAtom } from '@/atoms/queries/userConfigs';
import { eventDataFilterParamsDebounceObject } from '@/atoms/queries/eventData';
import { playerEventMetricFiltersDebounceObject } from '@/atoms/queries/player/playerEventData';
import { playerRadarFilterDebounceObject } from '@/atoms/queries/player/playerRadar';
import { playerPositionsFilterDebounceObject } from '@/atoms/queries/players';
import { teamRadarFilterDebounceObject } from '@/atoms/queries/team/teamRadar';
import { teamEventMetricFiltersDebounceObject } from '@/atoms/queries/team/teamEventData';

/* TODO (PPC-730: How to make our scoped atoms easier to manage) */
export const WidgetScopeProvider = ({ children }: { children: ReactNode }) => (
  <ScopeProvider
    atoms={[
      sectionAtom,
      widgetIdAtom,

      // general filters atoms
      widgetGenderAtom,
      selectedCompetitionIdsAtom,
      selectedSeasonIdsAtom,
      selectedTeamIdsAtom,
      selectedPlayerIdsAtom,
      playerIdAtom,
      teamIdAtom,
      selectedGameTypeAtom,
      deselectedGamesAtom,
      selectedPeriodRangeAtom,
      selectedGameRangeAtom,
      selectedStartDateAtom,
      selectedEndDateAtom,
      selectedPositionsAtom,

      // radar vis atoms
      // This atom is needed as it's set in a useEffect, rather than being derived.
      // Without this all radars will look the same.
      renderedRadarDataAtom,
      selectedRadarTemplateIdAtom,

      // pitch vis atoms
      selectedVisEventsAtom,
      pitchCropAtom,
      pitchOrientationDashboardWidgetAtom,
      showArrowsAtom,
      metricKeyAtom,

      // data locker filters
      selectedEventNamesAtom,
      selectedOutcomesAtom,
      selectedBodyPartsAtom,
      selectedPassHeightAtom,
      selectedKeeperActionsAtom,
      selectedPitchFilterStartZonesAtom,
      selectedPitchFilterEndZonesAtom,

      // api filter params (these things are objects, but we have to scope all the values in the object to ensure each
      // debounce timeout is scoped to each widget)
      ...Object.values(eventDataFilterParamsDebounceObject),
      ...Object.values(playerEventMetricFiltersDebounceObject),
      ...Object.values(playerRadarFilterDebounceObject),
      ...Object.values(playerPositionsFilterDebounceObject),
      ...Object.values(teamRadarFilterDebounceObject),
      ...Object.values(teamEventMetricFiltersDebounceObject),
    ]}
  >
    {children}
  </ScopeProvider>
);
