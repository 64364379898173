import { atom } from 'jotai';
import { Nullable } from '@/types/generic';
import { SelectedVideoIds, VideoDescriptionsMapAtom } from '@/types/video';
import { getOptionsAtomForSection } from '@/utils/atoms/highLevelFilters';
import { selectedVisEventsAtom } from './vis/selection';
import { eventVideoDescriptionsAtom } from './queries/eventData';
import { playerEventVideoDescriptionsAtom } from './queries/player/playerEventData';
import { teamEventVideoDescriptionsAtom } from './queries/team/teamEventData';

export const selectedVideoIdsBaseAtom = atom<Nullable<SelectedVideoIds[]>>(null);

export const selectedVideoIdsAtom = atom(get => {
  const selectedVideoIds = get(selectedVideoIdsBaseAtom);
  const selectedVisEvents = get(selectedVisEventsAtom);

  const selectedVisArqamIds = selectedVisEvents?.reduce(
    (acc, { eventId, videoEventId }) => (videoEventId ? [...acc, { eventId, videoEventId }] : acc),
    <SelectedVideoIds[]>[],
  );

  return selectedVisArqamIds?.length ? selectedVisArqamIds : selectedVideoIds;
});

export const videoDescriptionsSectionMapAtom = atom<VideoDescriptionsMapAtom>(() => ({
  player: playerEventVideoDescriptionsAtom,
  team: teamEventVideoDescriptionsAtom,
  dataLocker: eventVideoDescriptionsAtom,
}));

export const videoDescriptionsAtom = atom(getOptionsAtomForSection(videoDescriptionsSectionMapAtom));
