import { defaultSeasonsAtom } from '@/atoms/filters/highLevel/seasons';
import { playerGameAggsAtom, playerGameAggsPositions } from '@/atoms/queries/player/playerGameAggData';
import { hasVideoLicenceAtom } from '@/atoms/user';
import { GeneralErrorBoundaryFallback } from '@/components/errorBoundary/ErrorBoundaryFallback';
import { GameAggregatesTable } from '@/components/tables/GameAggregatesTable';
import { GOALKEEPER_MATCHES_COLUMNS, PLAYER_MATCHES_COLUMNS } from '@/components/tables/GameAggregatesTable.constants';
import { Position } from '@/types/player';
import { ErrorBoundary } from '@sentry/react';
import { useAtomValue } from 'jotai';
import { Suspense } from 'react';

const PlayerGameAggsTableWithData = () => {
  const { data: positionsData } = useAtomValue(playerGameAggsPositions);
  const data = useAtomValue(playerGameAggsAtom);
  const hasDefaultSeason = useAtomValue(defaultSeasonsAtom);
  const isVideoColumnVisible = useAtomValue(hasVideoLicenceAtom);

  // positions are already sorted in a descending order from most played position to least played position
  const columns = positionsData[0]?.position === Position.GK ? GOALKEEPER_MATCHES_COLUMNS : PLAYER_MATCHES_COLUMNS;
  return (
    <GameAggregatesTable
      data={data}
      columns={columns}
      isLoading={!hasDefaultSeason}
      isVideoColumnVisible={isVideoColumnVisible}
    />
  );
};

export const PlayerGameAggsTable = () => (
  <ErrorBoundary fallback={<GeneralErrorBoundaryFallback />}>
    <Suspense fallback={<GameAggregatesTable data={[]} isLoading />}>
      <PlayerGameAggsTableWithData />
    </Suspense>
  </ErrorBoundary>
);
