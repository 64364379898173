import { useSortable } from '@dnd-kit/sortable';
import { Icon, Typography, ButtonIcon } from '@statsbomb/kitbag-components';
import { useTranslation } from 'react-i18next';
import { CSS } from '@dnd-kit/utilities';
import { getTranslationColumns } from '@/utils/translations';

// Uses dnd kit - Reference: https://docs.dndkit.com/

export const SortableItem = ({ id, toggleColumn }: { id: string; toggleColumn: (columnKey: string) => void }) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });
  const { t } = useTranslation(['table', 'events', 'metrics']);
  const { translationKey, translationNs } = getTranslationColumns(id);

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <li
      className="py-1 px-2 bg-canvas-tertiary-main focus-grab-handle focus-visible:outline-none rounded-sb"
      style={style}
      ref={setNodeRef}
      data-testid={`${id}-sortable-item`}
    >
      <div className="flex justify-between items-center w-full">
        <div className="flex items-center gap-2">
          <div className="!cursor-grab" {...attributes} {...listeners}>
            <Icon colour="primary" variant="DragHandle" size="small" />
          </div>
          <Typography variant="bodyMedium" as="span">
            {t(translationKey, { ns: translationNs, defaultValue: id })}
          </Typography>
        </div>
        <ButtonIcon
          onClick={() => toggleColumn(id)}
          icon="Close"
          size="small"
          variant="ghost"
          title={t('hideColumn')}
        />
      </div>
    </li>
  );
};
