import { VisDisplayModeSwitcher } from '@/components/visualisations/VisDisplayModeSwitcher';
import { useVisDisplayMode } from '@/hooks/useVisDisplayMode';
import { Typography } from '@statsbomb/kitbag-components';
import { useTranslation } from 'react-i18next';

export const EntityVisModeTopBar = () => {
  const { t } = useTranslation('visualisation');
  const [visDisplayMode, setVisDisplayMode] = useVisDisplayMode();

  return (
    <>
      <Typography variant="headingRegular">{t(`viewing.${visDisplayMode}`)}</Typography>
      <div className="ml-auto">
        <VisDisplayModeSwitcher handleDisplayMode={setVisDisplayMode} displayMode={visDisplayMode} />
      </div>
    </>
  );
};
