import { selectedDashboardIdAtom } from '@/atoms/dashboard/dashboard';
import { useSetAtom } from 'jotai';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

export const useSetDashboardId = () => {
  const { dashboardId } = useParams();
  const setSelectedDashboardId = useSetAtom(selectedDashboardIdAtom);

  useEffect(() => {
    setSelectedDashboardId(dashboardId);
  }, [dashboardId]);
};
