import { RefObject } from 'react';
import { escapeDownloadString } from '@/utils/string';
import { Options } from 'html-to-image/lib/types';
import { exportFontEmbeddedCSS } from './exportFontEmbeddedCSS';

export const createLinkAndDownload = (dataUrl: string, fileName: string) => {
  const link = document.createElement('a');
  link.download = fileName;
  link.href = dataUrl;
  link.click();
  return link;
};

export const exportVisual = async (
  exportRef: RefObject<HTMLDivElement>,
  exportFn: <T extends HTMLElement>(node: T, options?: Options) => Promise<string>,
  fileName: string,
) => {
  if (!exportRef.current) {
    return Promise.resolve();
  }

  return exportFn(exportRef.current, {
    cacheBust: true,
    fontEmbedCSS: exportFontEmbeddedCSS,
  })
    .then(dataUrl => createLinkAndDownload(dataUrl, escapeDownloadString(fileName)))
    .catch(error => {
      throw new Error(error);
    });
};

export const toCsvFilename = (filenameWithSpaces: string) => {
  if (filenameWithSpaces.trim().length === 0) {
    return undefined;
  }

  return `${filenameWithSpaces
    .split(/(\s+)/)
    .filter(s => s.trim().length)
    .map(s => s.charAt(0).toUpperCase() + s.slice(1))
    .join('')}.csv`;
};
