// TODO (PPC-265: In time, consume these colours from kitbag theme library)
export const POSITION_COLOUR_GROUPS = {
  gk: {
    light: '#FBA6E6',
    dark: '#FFB4C1',
  },
  fullBack: {
    light: '#740C5A',
    dark: '#C138DC',
  },
  centreBack: {
    light: '#FFB84D',
    dark: '#FFDA7A',
  },
  defensiveMidfielder: {
    light: '#1682BE',
    dark: '#1682BE',
  },
  centreMidfielder: {
    light: '#BC1090',
    dark: '#9C1071',
  },
  widePlayer: {
    light: '#6F04DA',
    dark: '#1DB3F3',
  },
  attackingMidfielder: {
    light: '#F14E00',
    dark: '#FF7A30',
  },
  forward: {
    light: '#02C3C8',
    dark: '#6EF5DF',
  },
};
