import { Icon, Typography } from '@statsbomb/kitbag-components';
import { useTranslation } from 'react-i18next';
import { ContentStateWrapper } from './ContentStateWrapper';

export const EmptyState = () => {
  const { t } = useTranslation('emptyState');
  return (
    <ContentStateWrapper id="empty-state">
      <Icon variant="Info" colour="primary" />
      <Typography variant="headingLarge">{t('noData.title')}</Typography>
      <Typography variant="bodyRegular">{t('noData.description')}</Typography>
    </ContentStateWrapper>
  );
};
