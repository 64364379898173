import { BaseOption, Option } from '@/types/generic';
import { convertToDropdownOption } from './object';

export const sortAscArrayOptions = <T>(array: BaseOption<T>[]) =>
  array.sort((a, b) => {
    const aLabel = a.label;
    const bLabel = b.label;

    if (aLabel < bLabel) {
      return -1;
    }

    return aLabel > bLabel ? 1 : 0;
  });

export const convertOptionsToValues = <T>(array: BaseOption<T>[]) => array.map(({ value }) => value);
export const convertOptionsToLabels = <T>(array: BaseOption<T>[]) => array.map(({ label }) => label);

export const convertOptionsToValueArrayOrNull = (array: Option[]) =>
  array.length === 0 ? null : convertOptionsToValues(array);

export const convertFiltersToOption = <T>(array: T[] = []) => array.map(item => convertToDropdownOption<T>('', item));

export const findById = <T extends { [key in K]?: number }, K extends string>(
  items: T[],
  id: number,
  idField: K,
): T | undefined => items.find(item => item[idField] === id);

// checks whether 2 array of number ids share any values
export const isSharingId = (ids?: number[], selectedIds?: number[]) =>
  !!ids && !!selectedIds && ids.some(id => selectedIds.includes(id));

// filters out any falsy values and join what's left into a single file name
export const formatFileName = (fileInfo: (string | undefined)[]) => fileInfo.filter(Boolean).join(' - ');

// adds or removes an item in an array, depending on whether it's in it or not
export const toggleValue = <T>(value: T, items: T[]) => {
  const isIncluded = items.includes(value);
  return isIncluded ? items.filter(item => item !== value) : [...items, value];
};
