import { selectedVideoIdsAtom, videoDescriptionsAtom } from '@/atoms/video';
import { formatPlayerName } from '@/consts/player';
import { snakeToCamel } from '@/utils/queries';
import { Typography } from '@statsbomb/kitbag-components';
import { Playlist, VideoEvent, VideoProvider, usePlaylistCurrentlySelected } from '@statsbomb/video-client-js';
import dayjs from 'dayjs';
import { useAtomValue } from 'jotai';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';

export const VideoPlayerBase = () => {
  const selectedVideoIds = useAtomValue(selectedVideoIdsAtom);
  const [currentlySelectedVideo] = usePlaylistCurrentlySelected();
  const { t } = useTranslation('events');

  const markerData = useAtomValue(videoDescriptionsAtom);

  const videoEvents = selectedVideoIds?.map(({ eventId, videoEventId }) => {
    const eventData = markerData.find(event => event.eventId === eventId);

    const coreVideoData = {
      id: videoEventId,
      selectedEntry: videoEventId === currentlySelectedVideo?.id,
    };

    if (!eventData) return coreVideoData;

    const playerName = formatPlayerName(eventData.playerName, eventData.playerNickname);
    const eventType = t(`fields.event.type.${snakeToCamel(eventData.type)}`);
    const timestamp = dayjs().set('minute', eventData.minute).set('seconds', eventData.second).format('mm:ss');
    const game = eventData.gameName.split(' ').slice(1).join(' ');
    const competition = eventData.competitionName;
    const gameDate = eventData.gameName.split(' ')[0];

    return {
      ...coreVideoData,
      description: `${playerName} - ${eventType}`,
      descriptionLineTwo: `${timestamp} - ${game}`,
      descriptionLineThree: `${competition} - ${gameDate}`,
    };
  });

  return (
    <VideoProvider>
      <VideoEvent autoPlay events={videoEvents || []} />
      <Playlist />
    </VideoProvider>
  );
};

export const VideoPlayer = () => {
  const { t } = useTranslation('video');

  return (
    <Suspense fallback={<Typography variant="bodyMedium">{t('loadingVideo')}</Typography>}>
      <VideoPlayerBase />
    </Suspense>
  );
};
