import { NestedObject } from '@/types/object';
import { TFunction } from 'i18next';
import { snakeToCamel } from './queries';

export const translateValue = (
  key: string,
  value: NestedObject[string],
  columnRule: string | undefined,
  t: TFunction,
) => {
  if (columnRule !== 'string-translate') return value;
  if (value == null) return '';
  // TODO (PPC-652: remove `toLowerCase()` once preferred foot values return as lower case)
  return typeof value === 'string' ? t(`fields.${key}.${snakeToCamel(value.toLowerCase())}`) : value;
};

export const getHighlightedColumnIndex = (sortedColumnIndex: number, isVideoColumnVisible: boolean) => {
  if (sortedColumnIndex === -1) {
    return undefined;
  }
  // If video column is not visible, we need to + 1 to convert 0 based index to 1 based index
  // If video column is visible, we need to + 2 to convert 0 based index to 1 based index and account for video column
  return sortedColumnIndex + (isVideoColumnVisible ? 2 : 1);
};

// aligns table cell values depending on format rule
// text based values align to the left and numeric values align to the right
export const alignTableCell = (formatRule: string | undefined) =>
  !formatRule || formatRule.includes('string') || formatRule === 'link' || formatRule === 'date' ? 'left' : 'right';
