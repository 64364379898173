import { Playlist, VideoEvent } from '@statsbomb/video-client-js';
import { WideFirstColumnLayout } from '@/components/layout/WideFirstColumnLayout';
import { VideoProvider } from '@/components/Providers';

export const PlayerGamesVideoPage = () => (
  <div className="h-full video-column-order">
    <VideoProvider>
      <WideFirstColumnLayout>
        <Playlist />
        {/* TODO (PPC-868: Get correct videos from API) */}
        <VideoEvent
          autoPlay
          events={[
            '319568868',
            '298844775',
            '302759930',
            '303032258',
            '309339459',
            '307934870',
            '310619747',
            '312163134',
            '310601861',
            '307946970',
            '319568034',
            '281383624',
            '277537991',
            '319959908',
            '298849713',
            '303036813',
            '304806361',
            '278705323',
            '298435251',
            '318821050',
            '300071521',
            '301141926',
            '302213350',
            '309343913',
            '285360770',
          ]}
        />
      </WideFirstColumnLayout>
    </VideoProvider>
  </div>
);
