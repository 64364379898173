import { Grid } from '@statsbomb/kitbag-components';
import { useTranslation } from 'react-i18next';
import { PlayerAggDataControls } from '@/components/controls/PlayerAggDataControls';
import { LayoutWithFilters } from '@/components/layout/LayoutWithFilters';
import { PlayerAggsTableWithPagination } from '@/components/tables/PlayerAggsTable';
import { PlayerAggsFilters } from '@/components/dataLocker/PlayerAggsFilters';
import { TableScopeProvider } from '@/components/tables/TableScopeProvider';
import { PageTitle } from '@/components/head/PageTitle';
import { playerAggsFilterParamsAtom } from '@/atoms/queries/player/playerAggData';
import { useAtomValue } from 'jotai';
import { playersNormalisedAggregatesUrl } from '@/query/url';

export const PlayerAggsPage = () => {
  const { t } = useTranslation(['navigation']);
  const playerAggsFilterParams = useAtomValue(playerAggsFilterParamsAtom);
  const playerDataLockerPageTitle = t('pageTitles.dataLocker', {
    entity: t('player.one', { ns: 'entity' }),
    ns: 'navigation',
  });
  return (
    <>
      <PageTitle>
        {t('pageTitles.dataLocker', {
          entity: t('player.one', { ns: 'entity' }),
        })}
      </PageTitle>
      <LayoutWithFilters filters={<PlayerAggsFilters />}>
        <Grid>
          <Grid item>
            <PlayerAggDataControls
              heading={t(`viewing.table`, { ns: 'visualisation' })}
              csvDownloadUrl={playersNormalisedAggregatesUrl(playerAggsFilterParams)}
              tableDescription={playerDataLockerPageTitle}
            />
            <TableScopeProvider>
              <PlayerAggsTableWithPagination />
            </TableScopeProvider>
          </Grid>
        </Grid>
      </LayoutWithFilters>
    </>
  );
};
